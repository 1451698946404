import React from "react";
import styles from "./UpcomingClasses.module.scss";

import {
  FaCalendarAlt,
  FaClock,
  FaRegArrowAltCircleUp,
  FaRegTimesCircle,
} from "react-icons/fa";

const UpcomingClasses = ({ showClasses, setShowClasses }) => {
  return (
    <div className={styles.courseInfo}>
      <div className={styles.upcoming}>
        <FaRegTimesCircle
          color="#C4233C"
          onClick={() => setShowClasses(!showClasses)}
          style={{ float: "right", margin: "10px", cursor: "pointer" }}
        />
        <h5 className={styles.upcomingHeading}>Upcoming classes</h5>
        <div className={styles.classes}>
          <div className={styles.class}>
            <div className={styles.topic}>
              <FaRegArrowAltCircleUp
                className={styles.openMeeting}
                size="1.2rem"
              />
              <p style={{ fontWeight: "bold" }}>Supply and Demand</p>
            </div>
            <div className={styles.timeInfo}>
              <h5>Starting</h5>
              <div className={styles.time}>
                <div className={styles.detailedTime}>
                  <FaCalendarAlt />
                  <p>Friday, 31 May 2024</p>
                </div>
                <div className={styles.detailedTime}>
                  <FaClock />
                  <p>2pm - 4pm EAT</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.class}>
            <div className={styles.topic}>
              <FaRegArrowAltCircleUp
                className={styles.openMeeting}
                size="1.2rem"
              />
              <p style={{ fontWeight: "bold" }}>Supply and Demand</p>
              {/* <p>3 hours</p> */}
            </div>
            <div className={styles.timeInfo}>
              <h5>Starting</h5>
              <div className={styles.time}>
                <div className={styles.detailedTime}>
                  <FaCalendarAlt />
                  <p>Friday, 31 May 2024</p>
                </div>
                <div className={styles.detailedTime}>
                  <FaClock />
                  <p>2pm - 4pm EAT</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.class}>
            <div className={styles.topic}>
              <FaRegArrowAltCircleUp
                className={styles.openMeeting}
                size="1.2rem"
              />
              <p style={{ fontWeight: "bold" }}>Supply and Demand</p>
              {/* <p>3 hours</p> */}
            </div>
            <div className={styles.timeInfo}>
              <h5>Starting</h5>
              <div className={styles.time}>
                <div className={styles.detailedTime}>
                  <FaCalendarAlt />
                  <p>Friday, 31 May 2024</p>
                </div>
                <div className={styles.detailedTime}>
                  <FaClock />
                  <p>2pm - 4pm EAT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingClasses;
