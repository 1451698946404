import React, { useState, useEffect, useContext, useMemo } from 'react'
import styles from './InvestProfile.module.scss'
import Dashboard from '../../components/Dashboard/Dashboard';
import { Row, Col } from "react-bootstrap";
import InputField from '../../components/InputField/InputField';

import {
    Route,
    useRouteMatch,
    Switch,
    useLocation,
    useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { clientRoutes } from "../../routes/sidebarRoutes";
import { Context as AuthContext } from "../../context/AuthContext";
import { FaUserTag, FaArrowRight, FaCircleExclamation, FaPenToSquare, FaArrowLeft } from "react-icons/fa6";
import LoaderIntro from "react-spinners/SyncLoader";
import Button from '../../components/Button/Button';
import noLoan from "../../assets/no-loan.png";
import { TiCancelOutline } from "react-icons/ti";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { json } from 'd3';



const InvestProfile = () => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const [baseUrl, setBaseUrl] = useState('https://space.somoafrica.org')
    const [baseImg, setBaseImg] = useState('https://images.developer.bdigismat.com/somoshop')
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [businessId, setBusinessId] = useState("");

    const [createProfile, setCreateProfile] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [loadingProfileError, setLoadingProfileError] = useState(false);

    const [businessProfile, setBusinessProfile] = useState([]);
    const [showCreateProfileModal, setShowCreateProfileModal] = useState(false);

    const [showEditProfileModal, setShowEditProfileModal] = useState(false);

    const [digiKuaPhone, setDigiKuaPhone] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [isLive, setIsLive] = useState("--Select--");
    const [readyForGrowth, setReadyForGrowth] = useState("--Select--");
    const [locationName, setLocationName] = useState("");
    const [showPerformanceData, setShowPerformanceData] = useState("--Select--");
    const [overview, setOverview] = useState("");
    const [valueProposition, setValueProposition] = useState("");
    const [yearPlan, setYearPlan] = useState("");
    const [investorBenefit, setInvestorBenefit] = useState("");
    const [currentNeed, setcurrentNeed] = useState("");
    const [businessCategory, setBusinessCategory] = useState("--Select--");
    const [businessCategoryName, setBusinessCategoryName] = useState("");
    const [video, setVideo] = useState("");
    const [image, setImage] = useState("");
    const [imageSavedName, setImageSavedName] = useState("");

    const [selectedImage, setSelectedImage] = useState("");
    const [logo, setLogo] = useState("");
    const [logoSavedName, setLogoSavedName] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [createProfileButton, setCreateProfileButton] = useState("Create Profile");
    const [updateProfileButton, setUpdateProfileButton] = useState("Save Changes");


    const [businessCategories, setBusinessCategories] = useState([]);
    const [isLoadingBusinessCategories, setIsLoadingBusinessCategories] = useState(false);
    const [isLoadingBusinessCategory, setIsLoadingBusinessCategory] = useState(false);
    const [businessCategoriesError, setBusinessCategoriesError] = useState("");
    const [businessCategoryError, setBusinessCategoryError] = useState("");
    const [businessCategoryDisplay, setBusinessCategoryDisplay] = useState("");

    const [imageError, setImageError] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState("");

    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [previewLogoUrl, setPreviewLogoUrl] = useState(null);

    const [successAddProfile, setSuccessAddProfile] = useState(false);
    const [successUpdateProfile, setSuccessUpdateProfile] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [displayLocation, setDisplayLocation] = useState({ lng: '', lat: '' });
    const [oldDisplayLocation, setOldDisplayLocation] = useState("");
    const [newCategory, setNewCategory] = useState("");

    const [type, setType] = useState("");


    const {
        state: { user },
    } = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;

        setEmail(user.email);
        setDigiKuaPhone(user.phoneNumber);
    }, [user]);

    useEffect(() => {
        if (email) {
            setLoadingProfile(true);
            setLoadingProfileError('');
            setCreateProfile(false);
            setShowCreateProfileModal(false);

            try {
                const formData = new FormData();
                formData.append("email", email);

                const requestOptions = {
                    method: "POST",
                    headers: {},
                    body: formData,
                };

                fetch(`${baseUrl}/api.php?resource=user&action=readByIdOrPhoneOrEmail`, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        try {
                            if (data.data[0]?.businesses.length === 0) {
                                setCreateProfile(true);
                            } else {
                                setCreateProfile(false);
                                // read user business at index 0
                                setBusinessId(data.data[0]?.businesses[0].id);
                                getBusinessProfile(data.data[0]?.businesses[0].id)
                            }
                            setUserId(data.data[0]?.id || '');
                            setName(data.data[0]?.name?.split(" ")[0] || '');
                        } catch (innerError) {
                            console.error("Data Processing Error:", innerError);
                        }
                    })
                    .catch((error) => {
                        console.error("Fetch Error:", error);
                        setLoadingProfileError("Error fetching profile");
                    })
                    .finally(() => {
                        setLoadingProfile(false);
                    });
            } catch (error) {
                setLoadingProfileError("Unexpected Error:", error);
                setLoadingProfile(false);
            }
        } else {
            console.log("I am not boarding");
        }
    }, [email]);

    const getBusinessProfile = async (businessId) => {
        try {
            const res = await fetch(`${baseUrl}/api.php?resource=business&action=read&id=${businessId}`);

            if (!res.ok) throw new Error("Something went wrong with fetching profile");

            const data = await res.json();
            if (data.Response === "False") throw new Error("Profile not found");
            setBusinessProfile(data.business[0])
            setNewCategory(data.business[0].businessCategory)
        } catch (error) {
            console.error("Error:", error);
        }
    }

    useEffect(() => {
        const getBusinessCategories = async () => {
            try {
                setBusinessCategories([]);
                setIsLoadingBusinessCategories(true);
                setBusinessCategoriesError("");

                const url = `${baseUrl}/api.php?resource=businessCategory&action=read`;

                let res = await fetch(url);

                if (!res.ok) {
                    throw new Error("Something went wrong with fetching business categories");
                }

                const data = await res.json();
                if (data.Response === "False") {
                    throw new Error("Business Categories not found");
                }

                if (data) {
                    setBusinessCategories(data.data);
                    setBusinessCategoriesError("");
                    setIsLoadingBusinessCategories(false);
                }
            } catch (error) {
                setBusinessCategoriesError(error.message);
            }
        }

        getBusinessCategories();
    }, [])

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(",")[1]);
            reader.onerror = (error) => reject(error);
        });
    };



    const mapContainerStyle = { width: "100%", height: "100%" };
    const center = { lat: -1.286389, lng: 36.817223 };


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY
    });

    const handleMapClick = (event) => {
        if (event.latLng) {
            const mapLink = `?pb=!1m17!1m12!1m3!1d1000!2d${event.latLng.lng()}!3d${event.latLng.lat()}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1`;
            setSelectedLocation(mapLink);
            setDisplayLocation({
                lng: event.latLng.lng(),
                lat: event.latLng.lat()
            });
        }
    };


    useEffect(() => {
        if (logo) {
            setPreviewLogoUrl(URL.createObjectURL(logo))
        }
        if (image) {
            setPreviewImageUrl(URL.createObjectURL(image))
        }

    }, [image, logo])

    const submitBusinessProfile = async (actionType) => {
        if (!businessName) {
            toast.error("Business Name cannot be blank");
            return;
        }
        if (businessCategory === "--Select--") {
            toast.error("Business Category cannot be blank");
            return;
        }
        if (!digiKuaPhone) {
            toast.error("DigiKua Phone cannot be blank");
            return;
        }
        if (!overview) {
            toast.error("Overview cannot be blank");
            return;
        }

        actionType === 'add' ? setCreateProfileButton(<LoaderIntro color="#fff" />) : setUpdateProfileButton(<LoaderIntro color="#fff" />);

        if (logo || image) {
            const payload = [];
            if (image) {
                const imageBase64 = await toBase64(image);
                payload.push({
                    key: "image",
                    namePrefix: "user_uploaded",
                    path: "somoshop/biz/",
                    bin: imageBase64,
                    name: image.name,
                    size: image.size,
                    type: image.type
                });
            }
            if (logo) {
                const logoBase64 = await toBase64(logo);
                payload.push({
                    key: "logo",
                    namePrefix: "user_uploaded",
                    path: "somoshop/logos/",
                    bin: logoBase64,
                    name: logo.name,
                    size: logo.size,
                    type: logo.type
                });
            }

            try {
                const _frm = new FormData();
                _frm.append('files_up', JSON.stringify(payload));

                const requestOptions = {
                    method: "POST",
                    body: _frm
                };

                const response = await fetch("https://upload.somoafrica.com/", requestOptions);
                const data = await response.json();

                if (!data.error) {
                    let imageName = "";
                    let logoName = "";

                    data.data.forEach(dt => {
                        if (dt.key === "image") imageName = dt.savedName;
                        if (dt.key === "logo") logoName = dt.savedName;
                    });

                    await uploadBusinessData(actionType, imageName, logoName);
                }
            } catch (error) {
                toast.error("An error occurred");
                resetButtons(actionType);
            }
        } else {
            await uploadBusinessData(actionType);
        }
    };

    const uploadBusinessData = async (actionType, imageName, logoName) => {
        const profileData = {
            name: businessName,
            businessCategory: parseInt(businessCategory),
            phone: digiKuaPhone,
            displayName: displayName,
            ...(actionType === 'add' && { displayName: businessName }),
            ...(actionType === 'update' && { displayName: displayName }),
            currentNeed: currentNeed,
            enabled: 1,
            readyForGrowth: 0,
            showPerformance: 0,
            videoId: video,
            overview: overview,
            investorBenefit: investorBenefit,
            mapLink: JSON.stringify(displayLocation),
            businessAddress: locationName,
            yearPlan5: yearPlan,
            companyDifferentiation: valueProposition,
            ...(actionType === 'update' && { id: businessId }),
            ...(imageName && { image: imageName }),
            ...(logoName && { logo: logoName }),
            ...(actionType === 'add' && { dateAdded: Math.floor(Date.now() / 1000) }),
            ...(actionType === 'update' && { lastEdited: Math.floor(Date.now() / 1000) }),
        };

        const requestOptions = {
            method: "POST",
            body: JSON.stringify(profileData),
        };

        const url = actionType === 'add'
            ? `${baseUrl}/api.php?resource=business&action=create`
            : `${baseUrl}/api.php?resource=business&action=update`;

        try {
            const response = await fetch(url, requestOptions);
            const data = await response.json();

            if (!data.error) {
                actionType === 'add' ? setSuccessAddProfile(true) : setSuccessUpdateProfile(true);
                resetForm(actionType);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error("An error occurred");
        } finally {
            resetButtons(actionType);
        }
    };

    const resetForm = (actionType) => {
        setBusinessName("");
        setDisplayName("");
        setIsLive("--Select--");
        setReadyForGrowth("--Select--");
        setLocationName("");
        setShowPerformanceData("--Select--");
        setOverview("");
        setValueProposition("");
        setYearPlan("");
        setInvestorBenefit("");
        setcurrentNeed("");
        setBusinessCategory("--Select--");
        setBusinessCategoryName("");
        setVideo("");
        setImage("");
        setLogo("");
        setPreviewLogoUrl("");
        setPreviewImageUrl("");
        setEmail("");
        setImageSavedName("");
        setLogoSavedName("");
        setDisplayLocation({ lng: '', lat: '' })

        if (actionType === 'update') {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setShowEditProfileModal(false)
        }

        setTimeout(() => {
            setEmail(user.email);
        }, 0);

    };

    const resetButtons = (actionType) => {
        if (actionType === 'add') {
            setCreateProfileButton("Create Profile");
        } else {
            setUpdateProfileButton("Save Changes");
        }
    };

    useEffect(() => {
        if (successAddProfile) {
            toast.success("Profile created successfully");
            setSuccessAddProfile(false)
        } else if (successUpdateProfile) {
            toast.success("Profile updated successfully");
            setSuccessUpdateProfile(false)
        }
    }, [successAddProfile, successUpdateProfile])

    useEffect(() => {
        if (!newCategory) return;

        const fetchCategory = async () => {
            try {
                setIsLoadingBusinessCategory(true);
                setBusinessCategoryError("");

                const requiredData = { id: newCategory };

                const requestOptions = {
                    method: "POST",
                    body: JSON.stringify(requiredData),
                };

                const url = `${baseUrl}/api.php?resource=businessCategory&action=read`;

                let res = await fetch(url, requestOptions);

                if (!res.ok) {
                    throw new Error("Something went wrong with fetching business category");
                }

                const data = await res.json();
                if (data.Response === "False") {
                    throw new Error("Business Category not found");
                }

                if (data) {
                    const category = data.data.filter((dt) => dt.id == newCategory)
                    setBusinessCategoryDisplay(category[0].name);
                }
            } catch (error) {
                setBusinessCategoryError(error.message);
            } finally {
                setIsLoadingBusinessCategory(false);
            }
        };

        fetchCategory();
    }, [newCategory]);


    return (
        <>
            <Dashboard sidebarRoutes={clientRoutes} location={location}>
                <ToastContainer position="top-center" />
                <div>
                    {/* Loading Profile */}
                    {loadingProfile && (
                        <div className={styles.loadingProfile}>
                            <h2>
                                <FaUserTag /> Invest Profile
                            </h2>
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: "100px",
                                }}
                            >
                                Loading&nbsp;
                                <LoaderIntro color="#00a5a2" />
                            </span>
                            <p style={{ fontStyle: "italic" }}>
                                Empowering you, to change the world
                            </p>
                        </div>
                    )}
                    {/* User does not have a profile */}
                    {!loadingProfile && !loadingProfileError && createProfile && !showCreateProfileModal && (
                        <div className={styles.noProfileContainer}>
                            <div className={styles.noProfile}>
                                <h2>
                                    <FaUserTag /> Invest Profile
                                </h2>
                                <div className={styles.createProfile}>
                                    <div className={styles.image}>
                                        <img src={noLoan} alt="No profile" height="250" />
                                    </div>
                                    <div className={styles.action}>
                                        <TiCancelOutline
                                            className={styles.mobileNoProfile}
                                            size="5em"
                                            color="rgba(116, 23, 99, 0.6)"
                                        />
                                        <p>You haven't created your invest profile yet. <br /> Click the button below to set up your profile.</p>
                                        <Button
                                            bgColor="#00A5A2"
                                            size="sm"
                                            color="#fff"
                                            className={`mt-4 ${styles.btn}`}
                                            clicked={() => setShowCreateProfileModal(true)}
                                        >
                                            Create Profile <FaArrowRight />
                                        </Button>
                                    </div>
                                </div>
                                <p style={{ fontStyle: "italic" }} className={styles.footer}>
                                    Empowering you, to change the world
                                </p>
                            </div>
                        </div>
                    )}
                    {/* Create Profile Modal */}
                    {!loadingProfile && !loadingProfileError && createProfile && showCreateProfileModal && (
                        <div className={styles.body}>
                            <div className={styles.header}>
                                <h2>
                                    <FaUserTag /> Invest Profile
                                </h2>
                            </div>
                            <div className={styles.contentSection} style={{ padding: "0 30px 80px" }}>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <div className={styles.imageContainer}>
                                            <img src={previewImageUrl ? previewImageUrl : 'https://invest.somoafrica.org/img/icons/user.png'}
                                                alt="" />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <div className={styles.logoContainer}>
                                            <img src={previewLogoUrl ? previewLogoUrl : 'https://invest.somoafrica.org/img/icons/user.png'}
                                                alt="" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Image"
                                            type="file"
                                            accept="image/*"
                                            nameAttr="image"
                                            changed={(val) => setImage(val.target.files[0])}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Logo"
                                            type="file"
                                            accept="image/*"
                                            nameAttr="logo"
                                            changed={(val) => setLogo(val.target.files[0])}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Business Name"
                                            type="text"
                                            nameAttr="businessName"
                                            value={businessName}
                                            changed={(val) => setBusinessName(val)}
                                            mandatory={true}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Business Categories"
                                            type="select"
                                            nameAttr="businessCategories"
                                            value={businessCategory}
                                            customDefault="--Select--"
                                            options={businessCategories}
                                            changed={(val) => setBusinessCategory(val)}
                                            mandatory={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={12} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Overview"
                                            type="textarea"
                                            nameAttr="overview"
                                            value={overview}
                                            changed={(val) => setOverview(val)}
                                            mandatory={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={12} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Business location name"
                                            type="text"
                                            nameAttr="locationName"
                                            value={locationName}
                                            changed={(val) => setLocationName(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Value proposition"
                                            type="textarea"
                                            nameAttr="valueProposition"
                                            value={valueProposition}
                                            changed={(val) => setValueProposition(val)}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="5-year plan"
                                            type="textarea"
                                            nameAttr="5yearPlan"
                                            value={yearPlan}
                                            changed={(val) => setYearPlan(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Why should an investor be interested?"
                                            type="textarea"
                                            nameAttr="investorBenefit"
                                            value={investorBenefit}
                                            changed={(val) => setInvestorBenefit(val)}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Current Need"
                                            type="textarea"
                                            nameAttr="currentNeed"
                                            value={currentNeed}
                                            changed={(val) => setcurrentNeed(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Map"
                                            type="text"
                                            nameAttr="addMap"
                                            value={displayLocation.lng && displayLocation.lat ? Object.values(displayLocation) : ""}
                                            disable={true}
                                        />
                                        <div className={styles.mainContainer}>
                                            <div className={styles.mapContainer}>
                                                <GoogleMap
                                                    mapContainerStyle={mapContainerStyle}
                                                    zoom={13}
                                                    center={center}
                                                    onClick={handleMapClick}>
                                                    {selectedLocation && <Marker position={selectedLocation} />}
                                                </GoogleMap>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <InputField
                                            label="video"
                                            type="text"
                                            nameAttr="video"
                                            value={video}
                                            changed={(val) => setVideo(val)}
                                        />
                                        <div className={styles.content} style={{ marginTop: '15px' }}>
                                            <div className={styles.videoMap}>
                                                <iframe src={`https://www.youtube.com/embed/${video}?rel=0`}></iframe>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '60px' }}>
                                    <Button
                                        bgColor="#00A5A2"
                                        size="sm"
                                        color="#fff"
                                        className={`mt-4 ${styles.btn}`}
                                        clicked={() => {
                                            submitBusinessProfile('add')
                                        }}>
                                        {createProfileButton}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* User has Profile */}
                    {!loadingProfile && !loadingProfileError && !createProfile && !showEditProfileModal && businessProfile && (
                        <div className={styles.body}>
                            <div className={styles.imagesSection}>
                                <div className={styles.header}>
                                    <h2>
                                        <FaUserTag /> My Invest Profile
                                    </h2>
                                </div>
                                <div className={styles.imageContainer1}
                                    style={{ bottom: "-20%" }}>
                                    <img src={`${baseImg}/biz/${businessProfile.image}`} alt={businessProfile.displayName} />
                                </div>
                                <div className={styles.imageContainer2}
                                    style={{ bottom: "-20%" }}>
                                    <img src={`${baseImg}/logos/${businessProfile.logo}`} alt={businessProfile.displayName} />
                                </div>
                                <FaPenToSquare onClick={() => {
                                    setPreviewImageUrl(`${baseImg}/biz/${businessProfile.image}`)
                                    setPreviewLogoUrl(`${baseImg}/logos/${businessProfile.logo}`)
                                    setBusinessName(businessProfile.name)
                                    setBusinessCategory(businessProfile.businessCategory)
                                    setOverview(businessProfile.overview)
                                    setLocationName(businessProfile.businessAddress)
                                    setDisplayName(businessProfile.displayName)
                                    setValueProposition(businessProfile.companyDifferentiation)
                                    setYearPlan(businessProfile.yearPlan5)
                                    setInvestorBenefit(businessProfile.investorBenefit)
                                    setcurrentNeed(businessProfile.currentNeed)
                                    // setSelectedLocation(businessProfile.mapLink)
                                    const locationObject = !businessProfile.mapLink.startsWith('?pb') && JSON.parse(businessProfile.mapLink)
                                    setOldDisplayLocation(businessProfile.mapLink.startsWith('?pb') && businessProfile.mapLink)
                                    setDisplayLocation(!businessProfile.mapLink.startsWith('?pb') && { lng: locationObject.lng, lat: locationObject.lat })
                                    setVideo(businessProfile.videoId)
                                    setShowEditProfileModal(true)
                                }} title='Edit' size="1.5rem" color='#999999' style={{ position: "absolute", right: "20px", top: "20px", cursor: "pointer" }} />
                            </div>
                            <div className={styles.contentSection}>
                                <Row className="mb-4 align-items-stretch">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <div className={styles.content}>
                                            <label>Business Name</label>
                                            <div className={styles.text}><p>{businessProfile.name}</p></div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <div className={styles.content}>
                                            <label>Display Name</label>
                                            <div className={styles.text}><p>{businessProfile.displayName}</p></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4 align-items-stretch">
                                    <Col sm={12} md={6}>
                                        <div className={styles.content}>
                                            <label>Business Category</label>
                                            <div className={styles.text}><p>
                                                {isLoadingBusinessCategory && 'Loading...'}
                                                {!isLoadingBusinessCategory && businessCategoryError && businessCategoryError}
                                                {!isLoadingBusinessCategory && !businessCategoryError && businessCategoryDisplay}
                                            </p></div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <div className={styles.content}>
                                            <label>Business location name</label>
                                            <div className={styles.text}><p>{businessProfile.businessAddress}</p></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4 align-items-stretch">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0 d-flex flex-column">
                                        <div className={`${styles.content} flex-grow-1 d-flex flex-column`}>
                                            <label>Overview</label>
                                            <div className={`${styles.text} flex-grow-1 d-flex`}>
                                                <p className="m-0">{businessProfile.overview}</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} className="d-flex flex-column">
                                        <div className={`${styles.content} flex-grow-1 d-flex flex-column`}>
                                            <label>Value proposition</label>
                                            <div className={`${styles.text} flex-grow-1 d-flex`}>
                                                <p className="m-0">{businessProfile.companyDifferentiation}</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4 align-items-stretch">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0 d-flex flex-column">
                                        <div className={`${styles.content} flex-grow-1 d-flex flex-column`}>
                                            <label>5-year plan</label>
                                            <div className={`${styles.text} flex-grow-1 d-flex`}><p className="m-0">{businessProfile.yearPlan5}</p></div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <div className={`${styles.content} flex-grow-1 d-flex flex-column`}>
                                            <label>Why should an investor be interested?</label>
                                            <div className={`${styles.text} flex-grow-1 d-flex`}><p className="m-0">{businessProfile.investorBenefit}</p></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4 align-items-stretch">
                                    <Col sm={12} md={12} className="mb-4 mb-md-0 d-flex flex-column">
                                        <div className={`${styles.content} flex-grow-1 d-flex flex-column`}>
                                            <label>Current Need</label>
                                            <div className={`${styles.text} flex-grow-1 d-flex`}><p className="m-0">{businessProfile.currentNeed}</p></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4 align-items-stretch">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0 d-flex flex-column">
                                        <div className={`${styles.content} flex-grow-1 d-flex flex-column`}>
                                            <label>Map</label>
                                            <div className={styles.videoMap}>
                                                <iframe
                                                    src={
                                                        businessProfile.mapLink ? businessProfile.mapLink.startsWith('?pb')
                                                            ? `https://www.google.com/maps/embed${businessProfile.mapLink}`
                                                            : (() => {
                                                                const parsedMapLink = JSON.parse(businessProfile.mapLink);
                                                                return `https://www.google.com/maps?q=${parsedMapLink.lat},${parsedMapLink.lng}&hl=en&z=14&output=embed`;
                                                            })() : ""
                                                    }
                                                ></iframe>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <div className={`${styles.content} flex-grow-1 d-flex flex-column`}>
                                            <label>Video</label>
                                            <div className={styles.videoMap}>
                                                <iframe src={`https://www.youtube.com/embed/${businessProfile.videoId}?rel=0`}></iframe>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
                    {/* Edit Profile Modal */}
                    {!loadingProfile && !loadingProfileError && !createProfile && showEditProfileModal && businessProfile && (
                        <div className={styles.body}>
                            <div className={styles.header}>
                                <div className={`${styles.back}`}>
                                    <a href="javascript:void(0)" onClick={() => setShowEditProfileModal(false)}><FaArrowLeft size="20px" /> Back</a>
                                </div>
                                <h2>
                                    <FaUserTag /> Edit Invest Profile
                                </h2>
                            </div>
                            <div className={styles.contentSection} style={{ padding: "0 30px 80px" }}>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <div className={styles.imageContainer}>
                                            <img src={previewImageUrl ? previewImageUrl : 'https://invest.somoafrica.org/img/icons/user.png'}
                                                alt="" />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <div className={styles.logoContainer}>
                                            <img src={previewLogoUrl ? previewLogoUrl : 'https://invest.somoafrica.org/img/icons/user.png'}
                                                alt="" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Image"
                                            type="file"
                                            accept="image/*"
                                            nameAttr="image"
                                            changed={(val) => setImage(val.target.files[0])}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Logo"
                                            type="file"
                                            accept="image/*"
                                            nameAttr="logo"
                                            changed={(val) => setLogo(val.target.files[0])}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Display Name"
                                            type="text"
                                            nameAttr="displayName"
                                            value={displayName}
                                            changed={(val) => setDisplayName(val)}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Business Categories"
                                            type="select"
                                            nameAttr="businessCategories"
                                            value={businessCategory}
                                            customDefault="--Select--"
                                            options={businessCategories}
                                            changed={(val) => setBusinessCategory(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Business location name"
                                            type="textarea"
                                            nameAttr="locationName"
                                            value={locationName}
                                            changed={(val) => setLocationName(val)}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Overview"
                                            type="textarea"
                                            nameAttr="overview"
                                            value={overview}
                                            changed={(val) => setOverview(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Value proposition"
                                            type="textarea"
                                            nameAttr="valueProposition"
                                            value={valueProposition}
                                            changed={(val) => setValueProposition(val)}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="5-year plan"
                                            type="textarea"
                                            nameAttr="5yearPlan"
                                            value={yearPlan}
                                            changed={(val) => setYearPlan(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Why should an investor be interested?"
                                            type="textarea"
                                            nameAttr="investorBenefit"
                                            value={investorBenefit}
                                            changed={(val) => setInvestorBenefit(val)}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Current Need"
                                            type="textarea"
                                            nameAttr="currentNeed"
                                            value={currentNeed}
                                            changed={(val) => setcurrentNeed(val)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col sm={12} md={6} className="mb-4 mb-md-0">
                                        <InputField
                                            label="Map"
                                            type="text"
                                            nameAttr="addMap"
                                            value={displayLocation.lng && displayLocation.lat ? Object.values(displayLocation) : oldDisplayLocation}
                                            disable={true} />
                                        <div className={styles.mainContainer}>
                                            <div className={styles.mapContainer}>
                                                <GoogleMap
                                                    mapContainerStyle={mapContainerStyle}
                                                    zoom={13}
                                                    center={center}
                                                    onClick={handleMapClick}
                                                >
                                                    {selectedLocation && <Marker position={selectedLocation} />}
                                                </GoogleMap>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <InputField
                                            label="video"
                                            type="text"
                                            nameAttr="video"
                                            value={video}
                                            changed={(val) => setVideo(val)}
                                        />
                                        <div className={styles.content} style={{ marginTop: '15px' }}>
                                            <div className={styles.videoMap}>
                                                <iframe src={`https://www.youtube.com/embed/${video}?rel=0`}></iframe>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '60px' }}>
                                    <Button
                                        bgColor="#C4233C"
                                        size="sm"
                                        color="#fff"
                                        className={`mt-4 mr-4 ${styles.btn}`}
                                        clicked={() => {
                                            window.scrollTo({ top: 0, behavior: "smooth" });
                                            setShowEditProfileModal(false)
                                        }}
                                    >
                                        Cancel Changes
                                    </Button>
                                    <Button
                                        bgColor="#00A5A2"
                                        size="sm"
                                        color="#fff"
                                        className={`mt-4 ml-4 ${styles.btn}`}
                                        clicked={() => {
                                            submitBusinessProfile('update')
                                        }}
                                    >
                                        {updateProfileButton}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Error loading profile */}
                    {!loadingProfile && loadingProfileError && !createProfile && (
                        <div className={styles.loadingProfile}>
                            <h2>
                                <FaUserTag /> Invest Profile
                            </h2>
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: "100px",
                                }}
                            >
                                <FaCircleExclamation color='#C4233C' style={{ marginRight: '5px' }} /> {loadingProfileError}&nbsp;
                            </span>
                            <p style={{ fontStyle: "italic" }}>
                                Empowering you, to change the world
                            </p>
                        </div>
                    )}
                </div>
            </Dashboard >
        </>
    )
}

export default InvestProfile