import React, { useState, useEffect } from "react";
import styles from "./LMSAssignments.module.scss";
import { ReactComponent as Icon } from "../../assets/icons/assignment.svg";
import { ToastContainer, toast } from "react-toastify";
import {
  FaSearch,
  FaBookReader,
  FaArrowCircleLeft,
  FaChevronRight,
  FaRegListAlt,
  FaLock,
} from "react-icons/fa";
import Assignments from "../Assignments/Assignments";
import Button from "../Button/Button";
import { ThreeDots } from "react-loader-spinner";
import { TiCancelOutline } from "react-icons/ti";
import noLoan from "../../assets/no-loan.png";

import {
  // useParams,
  useRouteMatch,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

const LMSAssignment = ({
  cohortId,
  traineeId,
  closePanel,
  setClosePanel,
  screenWidth,
  baseUrl,
  fromMenu,
  menuAssignmentId,
  menuIndex,
  assignmentTitle,
  setCurrentPath,
  classId,
  lastChapter
}) => {
  const [assignments, setAssignments] = useState([]);
  const [showAssignments, setShowAssignments] = useState([]);
  const [assignmentsError, setAssignmentsError] = useState("");
  const [isLoadingAssignments, setIsLoadingAssignments] = useState("");

  const [clickedAssignment, setClickedAssignment] = useState();
  const [assignmentIndex, setAssignmentIndex] = useState();
  const [title, setTitle] = useState("");

  const [displayAssignments, setDisplayAssignments] = useState(false);

  const [hoveredAssignment, setHoveredAssignment] = useState();

  const [chapters, setChapters] = useState([]);
  const [loadingChapters, setLoadingChapters] = useState();
  const [selectedChapter, setSelectedChapter] = useState("");

  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    const fetchAssignmentsAndScores = async () => {
      try {
        setIsLoadingAssignments(true);
        setAssignmentsError("");

        // Fetch assignments
        const resAssignments = await fetch(
          `${baseUrl}assignments?class_id=${classId}`
        );
        if (!resAssignments.ok)
          throw new Error("Something went wrong with fetching assignments");

        const dataAssignments = await resAssignments.json();
        if (dataAssignments.Response === false)
          throw new Error("Assignments not found.");

        // Fetch scores for each assignment
        const allAssignmentsInfo = await Promise.all(
          dataAssignments.data.map(async (assign) => {
            const resScores = await fetch(
              `${baseUrl}assignments/${assign.id}/submits?trainee_id=${traineeId}&cohort_id=${cohortId}`
            );
            if (!resScores.ok)
              throw new Error("Something went wrong with fetching score");

            const dataScores = await resScores.json();

            // Check if there are no submissions
            if (dataScores.count === 0 || dataScores.data.length === 0) {
              return { ...assign, score: "notAttempted", display: false };
            }

            // Extract scores from the submissions
            const scores = dataScores.data
              .map((item) => item.score)
              .filter((score) => score !== null);

            // If there are scores, return the highest one
            const highestScore = scores.length > 0 ? Math.max(...scores) : null;

            // Initially, set display to true
            return { ...assign, score: highestScore, display: true };
          })
        );

        // Determine display status based on the previous chapters' assignments
        let previousChaptersCompleted = true;
        let firstIncompleteChapterFound = false;

        allAssignmentsInfo.forEach((assignment, index) => {
          if (
            assignment.score === "notAttempted" &&
            !firstIncompleteChapterFound
          ) {
            if (previousChaptersCompleted) {
              assignment.display = true;
              firstIncompleteChapterFound = true;
            } else {
              assignment.display = false;
            }
          } else if (!previousChaptersCompleted) {
            assignment.display = false;
          }

          if (assignment.score === "notAttempted") {
            previousChaptersCompleted = false;
          }
        });

        setAssignments(allAssignmentsInfo);

        console.log("Last chapter:", lastChapter);


        console.log(allAssignmentsInfo);

        setShowAssignments(allAssignmentsInfo);
      } catch (err) {
        setAssignmentsError(err.message);
      } finally {
        setIsLoadingAssignments(false);
      }
    };

    fetchAssignmentsAndScores();
  }, [traineeId, classId]);

  const convertUnixTimestamp = (unixTimestamp) => {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    return dateObject.toLocaleDateString("en-GB");
  };

  const addDaysToUnixTimestamp = (unixTimestamp, days) => {
    const milliseconds = unixTimestamp * 1000;
    const dateObject = new Date(milliseconds);
    dateObject.setDate(dateObject.getDate() + days);
    return dateObject.toLocaleDateString("en-GB");
  };

  const getOccurrence = (closePanel, screenWidth) => {
    if (closePanel && screenWidth < 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth < 1200) {
      return ["0%", "none"];
    } else if (closePanel && screenWidth > 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth > 1200) {
      return ["70%", ""];
    }
  };

  const getChapters = async () => {
    try {
      setChapters([]);
      setLoadingChapters(true);
      const res = await fetch(`${baseUrl}chapters?class_id=${classId}`);

      if (!res.ok)
        throw new Error("Something went wrong with fetching chapters");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Chapters not found");

      const filteredChapters = data.data;

      setChapters(filteredChapters);
      setLoadingChapters(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingChapters(false);
    }
  };

  useEffect(() => {
    getChapters();
  }, []);

  useEffect(() => {
    selectedChapter === "all"
      ? setShowAssignments(assignments)
      : setShowAssignments(
        assignments.filter(
          (assignment) =>
            parseInt(assignment.chapterId) === parseInt(selectedChapter)
        )
      );
  }, [selectedChapter]);

  return (
    <div
      className={styles.assignmentsCont}
      style={{
        width: getOccurrence(closePanel, screenWidth)[0],
        display: getOccurrence(closePanel, screenWidth)[1],
      }}
    >
      <ToastContainer position="top-center" />
      <div className={styles.topBar}>
        <Switch>
          <Route exact path={`${path}`}>
            <div className={styles.header}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {closePanel ? (
                  <div
                    className={styles.openMenu}
                    onClick={() => {
                      setClosePanel(false);
                    }}
                  >
                    <FaChevronRight size="1rem" color="#cc6328" />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Icon
                className={styles.icon}
                style={{ marginLeft: closePanel ? "" : "20px" }}
              />
              <h2>Assignments</h2>
            </div>
          </Route>
          <Route path={`${path}/:id`}>
            {!fromMenu && (
              <div className={styles.header} style={{ paddingLeft: "20px" }}>
                <Button
                  bgColor="#cc6328"
                  size="sm"
                  color="#fff"
                  clicked={() => {
                    setClickedAssignment("");
                    setAssignmentIndex("");
                    setTitle("");
                    setCurrentPath(`learning/assignments`);
                  }}
                >
                  <FaArrowCircleLeft />
                </Button>
              </div>
            )}

            {fromMenu && (
              <div className={styles.header}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {closePanel ? (
                    <div
                      className={styles.openMenu}
                      onClick={() => setClosePanel(false)}
                    >
                      <FaChevronRight size="1rem" color="#cc6328" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <Icon
                  className={styles.icon}
                  style={{ marginLeft: closePanel ? "" : "20px" }}
                />
                <h2>Assignments</h2>
              </div>
            )}
          </Route>
        </Switch>
      </div>

      <Switch>
        <Route exact path={`${path}`}>
          <div className={styles.contentBar}>
            <div className={styles.search}>
              <div className={styles.searchContainer}>
                <label for="cashflowElement">Filter by Chapter:</label>
                <select
                  name=""
                  id="cashflowElement"
                  className={`${styles.formControl} ${styles.select}`}
                  onChange={(e) => {
                    setSelectedChapter(e.target.value);
                  }}
                >
                  {loadingChapters && (
                    <option disabled selected value="--Select--">
                      Loading...
                    </option>
                  )}
                  <option value="all" selected>
                    All
                  </option>
                  {!loadingChapters &&
                    chapters.map((chapter, index) => (
                      <option key={index} value={chapter.id}>
                        {chapter.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className={styles.assignments}>
              <div className={styles.applicationComplete}>
                <div
                  className={styles.advisorsListHold}
                  style={{ textAlign: "left" }}
                  id="advisorsList"
                >
                  {isLoadingAssignments && (
                    <div className={styles.loader}>
                      <p>
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#00A5A2"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </p>
                    </div>
                  )}
                  {!isLoadingAssignments && assignmentsError && (
                    <>
                      <div className={styles.errorQuestions}>
                        <TiCancelOutline
                          className={styles.mobileNoScores}
                          size="5em"
                          color="rgba(116, 23, 99, 0.6)"
                        />
                        <p>{assignmentsError}</p>
                      </div>
                    </>
                  )}
                  {!isLoadingAssignments &&
                    !assignmentsError &&
                    showAssignments.length > 0 &&
                    showAssignments.map((assignment, index) => (
                      <div
                        className={styles.advisorList}
                        style={{
                          transform:
                            hoveredAssignment === assignment.id &&
                            (assignment.score === null ||
                              assignment.score === "notAttempted") &&
                            "scale(1.05)",

                          boxShadow:
                            hoveredAssignment === assignment.id &&
                            (assignment.score === null ||
                              assignment.score === "notAttempted") &&
                            "10px 10px 10px #bcbcbc",
                        }}
                        onClick={() => {
                          if (
                            assignment.score !== null &&
                            assignment.score !== "notAttempted"
                          ) {
                            toast.error(
                              "No additional attempts are allowed for this assignment."
                            );
                          } else if (assignment.display) {
                            setCurrentPath(
                              `learning/assignments/${assignment.id}`
                            );
                            setDisplayAssignments(true);
                            setClickedAssignment(assignment.id);
                            setAssignmentIndex(index + 1);
                            setTitle(assignment.title);
                          } else {
                            toast.error(
                              "Complete previous chapters to access this assignment."
                            );
                          }
                        }}
                        onMouseEnter={() => setHoveredAssignment(assignment.id)}
                        onMouseLeave={() => setHoveredAssignment()}
                      >
                        <FaBookReader
                          size="1.2em"
                          color="#cc6328"
                          className={styles.icon}
                        />
                        <div className={styles.advisorListInfo}>
                          <span className={styles.titleCont}>
                            {assignment.title}{" "}
                            {assignment.score !== null &&
                              assignment.score !== "notAttempted" && <FaLock />}
                            {!assignment.display && <FaLock />}
                          </span>
                          <span>
                            Added: {convertUnixTimestamp(assignment.createdAt)}
                          </span>
                          <span>
                            Due:{" "}
                            {addDaysToUnixTimestamp(
                              assignment.createdAt,
                              assignment.periodInDays
                            )}
                          </span>
                          <span>
                            Score:{" "}
                            {assignment.score !== null &&
                              assignment.score !== "notAttempted"
                              ? assignment.score
                              : "--"}
                          </span>
                          <button
                            className={styles.button}
                            style={{
                              backgroundColor:
                                assignment.score === null
                                  ? "#CC63283a"
                                  : assignment.score === "notAttempted"
                                    ? "#C4233C3a"
                                    : "#00A5A23a",
                            }}
                          >
                            {assignment.score === null && "Submitted"}
                            {assignment.score !== null &&
                              assignment.score !== "notAttempted" &&
                              "Graded"}
                            {assignment.score === "notAttempted" &&
                              "Not Attempted"}
                          </button>
                        </div>
                      </div>
                    ))}

                  {!isLoadingAssignments &&
                    !assignmentsError &&
                    showAssignments.length < 1 && (
                      <div className={styles.noAssignmentCont}>
                        <div className={styles.noAssignments}>
                          <p>No Assignments Available Yet</p>
                          <img src={noLoan} alt="No assignments" height="250" />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </Route>
        {/* )} */}

        <Route path={`${path}/:id`}>
          <Assignments
            id={fromMenu ? menuAssignmentId : clickedAssignment}
            setId={setClickedAssignment}
            index={fromMenu ? menuIndex : assignmentIndex}
            baseUrl={baseUrl}
            cohortId={cohortId}
            traineeId={traineeId}
            assignmentTitle={fromMenu ? assignmentTitle : title}
          />
        </Route>
      </Switch>
      {/* {displayAssignments && (
        <Assignments
          id={clickedAssignment}
          index={assignmentIndex}
          baseUrl={baseUrl}
        />
      )} */}
    </div>
  );
};

export default LMSAssignment;
