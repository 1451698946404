import React, { useState, useEffect } from "react";
import styles from "./LMSContent.module.scss";
import { Switch, Route } from "react-router-dom";
import LoaderIntroWait from "react-spinners/HashLoader";
import LoaderIntro from "react-spinners/SyncLoader";
import { ThreeDots } from "react-loader-spinner";

// import { FaBusinessTime, FaCloudUploadAlt } from "react-icons/fa";

import ContentResources from "../ContentResources/ContentResources";
import ContentQA from "../ContentQA/ContentQA";
import ContentNotes from "../ContentNotes/ContentNotes";
import ContentReviews from "../ContentReviews/ContentReviews";
import noLoan from "../../assets/no-loan.png";
import Assignments from "../Assignments/Assignments";

import TopicHeader from "../TopicHeader/TopicHeader";

const LMSContent = ({
  traineeId,
  cohortId,
  baseUrl,
  topic,
  content,
  loadingTopicContent,
  setClickedTopic,
  closePanel,
  setClosePanel,
  screenWidth,
  actionId,
  setActionId
}) => {
  const [videoId, setVideoId] = useState("YLSxO36EJYc");

  const [objectives, setObjectives] = useState([]);
  const [objectivesError, setObjectivesError] = useState("");
  const [loadingObjectives, setLoadingObjectives] = useState();

  const actions = [
    { id: 1, name: "Reviews" },
    // { id: 2, name: "Notes" },
    { id: 3, name: "FAQs" },
    // { id: 4, name: "Resources" },
  ];

  const getOccurrence = (closePanel, screenWidth) => {
    if (closePanel && screenWidth < 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth < 1200) {
      return ["0%", "none"];
    } else if (closePanel && screenWidth > 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth > 1200) {
      return ["70%", ""];
    }
  };

  const getObjectives = async () => {
    try {
      setObjectives([]);
      setLoadingObjectives(true);
      setObjectivesError("");
      const res = await fetch(`${baseUrl}objectives?topic_id=${topic.id}`);

      if (!res.ok)
        throw new Error("Something went wrong with fetching objectives");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Objectives not found");

      setObjectives(data.data || []);
      setLoadingObjectives(false);
      setObjectivesError("");
    } catch (error) {
      setObjectivesError(error.message);
      console.error("Error:", error);
    } finally {
      setLoadingObjectives(false);
    }
  };

  useEffect(() => {
    getObjectives();
  }, [topic]);

  return (
    <div
      className={styles.rightPanel}
      style={{
        width: getOccurrence(closePanel, screenWidth)[0],
        display: getOccurrence(closePanel, screenWidth)[1],

        // display: !closePanel && screenWidth < 1200 ? "none" : "",
        borderRight: closePanel ? "1px solid #e2e7eb" : "",
      }}
    >
      <TopicHeader
        topicName={topic.name}
        setClickedTopic={setClickedTopic}
        closePanel={closePanel}
        setClosePanel={setClosePanel}
      />
      <div className={styles.LMSContent}>
        {!loadingObjectives && !objectivesError && objectives.length > 0 && (
          <div className={styles.info}>
            <h2>{objectives[0].title}</h2>
            <ul>
              {Object.entries(objectives[0].list).map(([key, value]) => (
                <li>{value}</li>
              ))}
            </ul>
          </div>
        )}

        <div className={styles.info}>
          <p>{!loadingTopicContent ? topic.description : ""}</p>
        </div>
        {loadingTopicContent && (
          <div className={styles.loader}>
            <p>
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#00A5A2"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </p>
          </div>
        )}
        {!loadingTopicContent && content.length === 0 && (
          <>
            <div className={styles.noQuestions}>
              <p>Topic resources have not been added.</p>
              <img src={noLoan} alt="No questions" height="250" />
            </div>
          </>
        )}
        {!loadingTopicContent &&
          content.map((cont) => (
            <div>
              <div className={styles.info}>
                <h2>{cont.name}</h2>
                <p dangerouslySetInnerHTML={{ __html: cont.description.replace(/\r\n|\r|\n/g, "<br/>") }}></p>
              </div>
              {/* Audio */}
              {cont.resourceTypeId === 2 && (
                <div>
                  <audio id="" controls className={styles.audio}>
                    <source src={cont.link} type="" />
                  </audio>
                </div>
              )}
              {/* Video */}
              {cont.resourceTypeId === 3 && (
                <>
                  <div className={styles.video}>
                    <div className={styles.videoWrapper}>
                      <iframe
                        src={`https://www.youtube.com/embed/${cont.link}`}
                        allowFullScreen
                        title={cont.name}
                      ></iframe>
                    </div>
                  </div>
                </>
              )}
              {/* Document */}
              {cont.link !== null && cont.resourceTypeId === 4 && (
                <div className={styles.vidEmbed}>
                  <iframe
                    src={`https://drive.google.com/file/d/${cont.link}/preview`}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                  ></iframe>
                </div>
              )}
            </div>
          ))}

        {!loadingTopicContent && (
          <div className={styles.descriptionBox}>
            <div className={styles.descriptionBoxHeadings}>
              {actions.map((action) => (
                <div
                  key={action.id}
                  onClick={() => {
                    setActionId(action.id === 1 && !loadingTopicContent && content.length === 0 ? 3 : action.id);
                  }}
                  style={{
                    borderBottom:
                      actionId === action.id ? "3px solid #00A5A2" : "",
                  }}
                >
                  <h3>{action.id === 1 && !loadingTopicContent && content.length === 0 ? "" : action.name}</h3>
                </div>
              ))}
            </div>
            <div className={styles.descriptionBoxCont}>
              {actionId === 1 && !loadingTopicContent && content.length > 0 && (
                <ContentReviews
                  traineeId={traineeId}
                  topicId={topic.id}
                  baseUrl={baseUrl}
                />
              )}
              {actionId === 2 && (
                <ContentNotes
                  traineeId={traineeId}
                  cohortId={cohortId}
                  baseUrl={baseUrl}
                  closePanel={closePanel}
                  setClosePanel={setClosePanel}
                  screenWidth={screenWidth}
                />
              )}
              {actionId === 3 && <ContentQA baseUrl={baseUrl} />}
              {actionId === 4 && <ContentResources />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LMSContent;
