import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

import {
  // useParams,
  useRouteMatch,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import styles from "./ProgramDetails.module.scss";
import UpcomingClasses from "../UpcomingClasses/UpcomingClasses";
import CourseOutline from "../TopicHeader/TopicHeader";
import LMSNotes from "../LMSNotes/LMSNotes";
import Scores from "../Scores/Scores";
import LMSMessages from "../LMSMessages/LMSMessages";
import LMSAssignments from "../LMSAssignments/LMSAssignments";
import Assignments from "../Assignments/Assignments";
import LMSResources from "../LMSResources/LMSResources";
import LMSContent from "../LMSContent/LMSContent";
import LoaderIntro from "react-spinners/SyncLoader";
import { ToastContainer, toast } from "react-toastify";

import {
  FaRegCircle,
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
  FaArrowRight,
  FaCheckCircle,
  FaVideo,
  FaBookOpen,
  FaRegQuestionCircle,
  FaRegTimesCircle,
  FaTimes,
  FaLock,
  FaRegCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { assign } from "lodash";

const ProgramDetails = ({
  traineeId,
  userId,
  mentorId,
  mentorName,
  cohortId,
  classId,
  setClassId,
  lastChapter,
  lastTopic,
  baseUrl,
  fromDashboard,
  setFromDashboard,
  currentPath,
  setCurrentPath,

  clickedAssignment,
  setClickedAssignment,
  assignmentIndex,
  setAssignmentIndex,
  fromMenu,
  setFromMenu,
  assignmentTitle,
  setAssignmentTitle,
}) => {
  // const { programId } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const [displayWeeks, setDisplayWeeks] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [contentDisplayed, setContentDisplayed] = useState(false);
  const [showClasses, setShowClasses] = useState(false);

  const [clickedChapter, setClickedChapter] = useState();
  const [clickedTopic, setClickedTopic] = useState();
  const [clickedMenu, setClickedMenu] = useState();

  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedTopicHeading, setSelectedTopicHeading] = useState();

  const [activeIndex, setActiveIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [closePanel, setClosePanel] = useState(
    screenWidth < 1200 ? true : false
  );
  const [courseClassMap, setCourseClassMap] = useState([]);

  const [loadingClasses, setLoadingClasses] = useState(false);
  const [loadingChapters, setLoadingChapters] = useState(false);
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [loadingChapterAssignments, setLoadingChapterAssignments] =
    useState(false);
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [loadingTopic, setLoadingTopic] = useState(false);
  const [loadingTopicContent, setLoadingTopicContent] = useState(false);

  // const [classes, setClasses] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [insights, setInsights] = useState({});
  const [chapterAssignments, setChapterAssignments] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topic, setTopic] = useState([]);
  const [topicContent, setTopicContent] = useState([]);

  const [selectedTopic, setSelectedTopic] = useState([]);

  const [assignmentsError, setAssignmentsError] = useState("");
  const [insightsError, setInsightsError] = useState("");
  const [actionId, setActionId] = useState(3);


  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getChapters = async (classId) => {
    try {
      setChapters([]);
      setLoadingChapters(true);
      const res = await fetch(`${baseUrl}chapters?class_id=${classId}`);

      if (!res.ok)
        throw new Error("Something went wrong with fetching chapters");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Chapters not found");

      const filteredChapters = data.data;

      setChapters(filteredChapters);
      setLoadingChapters(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingChapters(false);
    }
  };

  const getChapterInsights = async () => {
    try {
      setInsights([]);
      setLoadingInsights(true);
      setInsightsError("");

      const res = await fetch(
        `${baseUrl}insights/trainees/${traineeId}/cohort-info?organize_by=trainee`
      );

      if (!res.ok)
        throw new Error("Something went wrong with fetching chapters");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Chapters not found");

      if (data.error) {
        setInsightsError(data.message);
        return;
      }

      const chapters = data.data[cohortId]?.[classId]?.chapters;

      if (!chapters || Object.keys(chapters).length === 0) {
        setInsightsError("No chapters found, check back later.");
        return;
      }

      const filteredChapters = {};
      let previousChapterCompleted = true;

      Object.entries(chapters).forEach(([key, chapter]) => {
        if (!key.trim()) return;

        const assignments = chapter.assignments;
        let completedAssignments = 0;
        const totalAssignments = Object.keys(assignments).length;

        Object.entries(assignments).forEach(([_, assignment]) => {
          if (assignment.submissions > 0) {
            completedAssignments++;
          }
        });

        if (!previousChapterCompleted) {
          filteredChapters[key] = { ...chapter, status: "not done" };
        } else if (completedAssignments === totalAssignments) {
          filteredChapters[key] = { ...chapter, status: "completed" };
          previousChapterCompleted = true;
        } else {
          filteredChapters[key] = { ...chapter, status: "current" };
          previousChapterCompleted = false;
        }
      });

      if (
        Object.keys(filteredChapters).length === 0 ||
        Object.keys(filteredChapters).every((key) => key.trim() === "")
      ) {
        setInsightsError("No available chapters to display.");
        return;
      }

      setInsights(filteredChapters);
      setInsightsError("");
    } catch (error) {
      console.error("Error fetching chapters:", error);
      setInsightsError(error.message);
    } finally {
      setLoadingInsights(false);
    }
  };

  const getChapterAssignments = async (chapterId) => {
    try {
      setChapterAssignments([]);
      setLoadingChapterAssignments(true);
      setAssignmentsError("");

      // Fetch assignments
      const resAssignments = await fetch(
        `${baseUrl}assignments?chapter_id=${chapterId}`
      );
      if (!resAssignments.ok)
        throw new Error("Something went wrong with fetching assignments");

      const dataAssignments = await resAssignments.json();
      if (dataAssignments.Response === "False")
        throw new Error("Assignments not found");

      // Fetch scores for each assignment
      const allAssignmentsInfo = await Promise.all(
        dataAssignments.data.map(async (assign) => {
          const resScores = await fetch(
            `${baseUrl}assignments/${assign.id}/submits?trainee_id=${traineeId}`
          );
          if (!resScores.ok)
            throw new Error("Something went wrong with fetching score");

          const dataScores = await resScores.json();

          // Check if there are no submissions
          if (dataScores.count === 0 || dataScores.data.length === 0) {
            return { ...assign, score: "notAttempted" };
          }

          // Extract scores from the submissions
          const scores = dataScores.data
            .map((item) => item.score)
            .filter((score) => score !== null);

          // If there are scores, return the highest one
          const highestScore = scores.length > 0 ? Math.max(...scores) : null;
          return { ...assign, score: highestScore };
        })
      );

      setChapterAssignments(allAssignmentsInfo);
      setAssignmentsError("");
    } catch (error) {
      console.error("Error:", error);
      setAssignmentsError(error.message);
    } finally {
      setLoadingChapterAssignments(false);
    }
  };

  const getTopics = async (chapterId) => {
    try {
      setTopics([]);
      setLoadingTopics(true);
      const res = await fetch(`${baseUrl}topics?chapter_id=${chapterId}`);

      if (!res.ok) throw new Error("Something went wrong with fetching topics");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Topics not found");

      setTopics(data.data);
      setLoadingTopics(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getTopic = async (topicId) => {
    try {
      setTopic([]);
      setLoadingTopic(true);
      const res = await fetch(`${baseUrl}/topics/${topicId}`);

      if (!res.ok) throw new Error("Something went wrong with fetching topic");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Topic not found");

      setTopic(data.data[0]);
      setSelectedTopic(data.data[0]);
      setLoadingTopic(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getTopicContent = async (topicId) => {
    try {
      setTopicContent([]);
      setLoadingTopicContent(true);
      const res = await fetch(`${baseUrl}topic-resources`);

      if (!res.ok)
        throw new Error("Something went wrong with fetching content");

      const data = await res.json();
      if (data.Response === "False") throw new Error("Content not found");

      const filteredTopicContent = topicId
        ? data.data.filter((topic) => topic.topicId === topicId)
        : data.data;

      const sortedTopics = filteredTopicContent.sort(
        (a, b) => a.position - b.position
      );

      setTopicContent(sortedTopics);
      setLoadingTopicContent(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getChapters(classId);
    getChapterInsights();
    const pathAfterLms = location.pathname.split("/lms/")[1];
    const pathSegments = pathAfterLms.split("/");

    const isNumber = (value) => !isNaN(parseInt(value, 10));

    const isValidAssignmentsPath =
      pathSegments[0] === "learning" &&
      pathSegments[1] === "assignments" &&
      (pathSegments.length === 2 ||
        (pathSegments.length === 3 && isNumber(pathSegments[2])));

    const isValidNotesPath =
      pathSegments[0] === "learning" &&
      pathSegments[1] === "notes" &&
      (pathSegments.length === 2 ||
        (pathSegments.length === 3 && isNumber(pathSegments[2])));

    const isValidLearningPath =
      pathSegments[0] === "learning" &&
      pathSegments.length === 5 &&
      isNumber(pathSegments[1]) &&
      isNumber(pathSegments[2]) &&
      isNumber(pathSegments[3]) &&
      isNumber(pathSegments[4]);

    const isValidMessagesPath =
      pathSegments[0] === "learning" &&
      pathSegments[1] === "messages" &&
      pathSegments.length === 2;

    const isValidScoresPath =
      pathSegments[0] === "learning" &&
      pathSegments[1] === "scores" &&
      pathSegments.length === 2;

    if (isValidAssignmentsPath) {
      if (pathSegments.length === 3) {
        const assignmentId = parseInt(pathSegments[2], 10);
        // setClickedAssignment(assignmentId);
      }
      setClickedMenu(0);
    } else if (isValidNotesPath) {
      if (pathSegments.length === 3) {
        const noteId = parseInt(pathSegments[2], 10);
        setClickedMenu(3);
      }
      setClickedMenu(3);
    } else if (isValidLearningPath) {
      const cohortId = parseInt(pathSegments[1], 10);
      const nclassId = parseInt(pathSegments[2], 10);
      const chapterId = parseInt(pathSegments[3], 10);
      const topicId = parseInt(pathSegments[4], 10);


      // setClickedAssignment(cohortId);
      setClickedChapter(chapterId);
      setClickedTopic(topicId);
      console.log('Class ID-> ', classId);

    } else if (isValidMessagesPath) {
      setClickedMenu(4);
    } else if (isValidScoresPath) {
      setClickedMenu(1);
    } else if (pathSegments.length === 1 && pathSegments[0] === "learning") {
      setClickedChapter(lastChapter);
      setClickedTopic(lastTopic);
    } else {
      setCurrentPath("home");
    }
  }, []);

  useEffect(() => {
    if (!currentPath) return;

    if (currentPath === "learning") {
      console.log("Trainee ID:", traineeId, " Cohort ID:", cohortId, " Class ID:", classId, " lastChapter ID:", lastChapter, " lastTopic ID:", lastTopic);
      if (!lastChapter || !lastTopic) {
        setCurrentPath(`learning/notes`);
        setClickedMenu(3);
      } else {
        setCurrentPath(`learning/${cohortId}/${classId}/${lastChapter}/${lastTopic}`);
        setClickedChapter(lastChapter);
        setClickedTopic(lastTopic);
        setClickedMenu("");
        setClickedAssignment("");
      }
    }
  }, [currentPath]);

  useEffect(() => {
    if (!clickedChapter) return;

    const fetchData = async (chapter) => {
      try {
        await getTopics(chapter);
        // await getChapterAssignments(chapter);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(clickedChapter);
  }, [clickedChapter]);

  useEffect(() => {
    if (!clickedTopic) return;

    setLoadingTopicContent(true);
    setTopicContent([]);
    const getTopicData = async (clickedTopic) => {
      await getTopic(clickedTopic);
      await getTopicContent(clickedTopic);

      const pathAfterLearning = location.pathname.split("/lms/")[1];

      setCurrentPath(`learning/${cohortId}/${classId}/${clickedChapter}/${clickedTopic}`);
    };
    getTopicData(clickedTopic);
  }, [clickedTopic]);

  const menus = [
    { id: 0, name: "Assignments", link: "assignments" },
    { id: 1, name: "My Scores", link: "scores" },
    // { id: 2, name: "Resources", link: "resources" },
    { id: 3, name: "My Notes", link: "notes" },
    { id: 4, name: "Messages", link: "messages" },
  ];

  const getOccurrence = (closePanel, screenWidth) => {
    if (closePanel && screenWidth < 1200) {
      return ["0%", "none"];
    } else if (!closePanel && screenWidth < 1200) {
      return ["100%", ""];
    } else if (closePanel && screenWidth > 1200) {
      return ["0%", "none"];
    } else if (!closePanel && screenWidth > 1200) {
      return ["30%", ""];
    }
  };

  return (
    <div className={styles.programDetails}>
      <ToastContainer position="top-center" />
      <div className={styles.courseContent}>
        <div
          className={styles.leftPanel}
          style={{
            width: getOccurrence(closePanel, screenWidth)[0],
            display: getOccurrence(closePanel, screenWidth)[1],
            // width: closePanel && screenWidth > 1200 ? "0" : "30%",
            // display: closePanel ? "none" : "",
          }}
        >
          <div className={styles.closeCont}>
            <div
              className={styles.closeBox}
              onClick={() => setClosePanel(true)}
              title="Close Menu"
            >
              <FaTimes
                size=".6rem"
                style={{ cursor: "pointer" }}
                color="#c4233c"
              />
            </div>
          </div>
          <div className={styles.leftPanelCont}>
            <div>
              <div className={styles.openContent}>
                <h4 style={{ marginRight: "10px" }}></h4>
                <h4>Course Outline</h4>
                <div className={styles.dot}></div>
              </div>
            </div>
            <div className={styles.courses}>
              <div className={styles.chapters}>
                {loadingInsights && (
                  <div className={styles.chapter}>
                    <h2 className={styles.loader}>
                      <ThreeDots
                        visible={true}
                        height="40"
                        width="40"
                        color="#00A5A2"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </h2>
                  </div>
                )}

                {!loadingInsights && insightsError && (
                  <div>
                    <div className={styles.classTitle}>
                      <div className={styles.chapterTitle}>
                        <h5>
                          <FaExclamationCircle size=".8rem" color="#C4233C" />
                        </h5>
                        <h5
                          className={styles.courseName}
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {insightsError}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}

                {!loadingInsights && !insightsError &&
                  Object.keys(insights).length > 0 &&
                  Object.entries(insights).map(([key, chapter]) => (
                    <div key={key}>
                      <div
                        className={styles.classTitle}
                        style={{
                          borderBottom:
                            clickedChapter === chapter.id
                              ? "1px solid #ffffff"
                              : "1px dotted #e2e7eb",
                        }}
                        onClick={() => {
                          if (chapter.status === "not done") {
                            toast.error(
                              "Please complete all previous chapters before accessing this one."
                            );
                          } else {
                            clickedChapter === chapter.id
                              ? setClickedChapter("")
                              : setClickedChapter(chapter.id);
                          }
                        }}
                      >
                        <div className={styles.chapterTitle}>
                          <h5>
                            {chapter.status === "completed" && (
                              <FaCheckCircle size=".8rem" color="#00A5A2" />
                            )}
                            {chapter.status === "not done" && (
                              <FaRegCircle size=".8rem" color="#00A5A2" />
                            )}
                            {chapter.status === "current" && (
                              <div className={styles.inProgress}></div>
                            )}
                          </h5>
                          <h5
                            className={styles.courseName}
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            {chapter.name}
                          </h5>
                          <h5 className={styles.chevron}>
                            {clickedChapter === chapter.id ? (
                              <FaChevronUp />
                            ) : (
                              <FaChevronDown />
                            )}
                          </h5>
                        </div>
                      </div>
                      <div
                        className={styles.topics}
                        style={{
                          display: clickedChapter === chapter.id ? "" : "none",
                        }}
                      >
                        {clickedChapter === chapter.id && loadingTopics && (
                          <div
                            className={styles.chapter}
                            style={{
                              margin: "5px 0 5px 20px",
                            }}
                          >
                            <h2 className="">
                              <ThreeDots
                                visible={true}
                                height="40"
                                width="40"
                                color="#00A5A2"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </h2>
                          </div>
                        )}

                        {clickedChapter === chapter.id &&
                          !loadingChapters &&
                          topics.map((topic) => (
                            <div
                              className={styles.topic}
                              style={{
                                // margin: "0 0 0 20px",
                                borderBottom:
                                  clickedTopic === topic.id
                                    ? "1px solid #ffffff"
                                    : "1px dotted #e2e7eb",
                                backgroundColor:
                                  clickedTopic === topic.id
                                    ? "#FFC83B5a"
                                    : "#ffffff",
                              }}
                              onClick={() => {
                                setClickedTopic(topic.id);
                                setSelectedTopic(topic);
                                setActionId(3)
                                setClosePanel(
                                  screenWidth < 1200 ? true : false
                                );
                                setClickedMenu("");
                                setClickedAssignment("");
                                setAssignmentIndex("");
                                setFromMenu(false);
                              }}
                            >
                              <h5>
                                <FaBookOpen
                                  style={{
                                    color:
                                      clickedTopic === topic.id
                                        ? "#212153"
                                        : "#878787",
                                  }}
                                />
                              </h5>
                              <h5
                                style={{
                                  color:
                                    clickedTopic === topic.id
                                      ? "#212153"
                                      : "#878787",
                                }}
                              >
                                {topic.name}
                              </h5>
                            </div>
                          ))}
                        {Object.entries(chapter.assignments).map(
                          ([key, assignment]) => (
                            <div
                              className={styles.topic}
                              key={key}
                              style={{
                                backgroundColor:
                                  clickedAssignment === assignment.id &&
                                    fromMenu
                                    ? "#FFC83B5a"
                                    : "#ffffff",
                              }}
                              onClick={() => {
                                if (assignment.is_marked) {
                                  toast.error(
                                    "No additional attempts are allowed for this assignment."
                                  );
                                } else {
                                  history.push(
                                    `${path}/assignments/${assignment.id}`
                                  );
                                  setClickedAssignment(assignment.id);
                                  // setAssignmentIndex(index + 1);
                                  setAssignmentTitle(assignment.title);
                                  setClickedTopic("");
                                  setClickedMenu("");
                                  setFromMenu(true);
                                }
                              }}
                              title={
                                assignment.is_marked
                                  ? "Locked"
                                  : assignment.submissions > 0
                                    ? "Reattempt"
                                    : "Attempt"
                              }
                            >
                              <h5>
                                <FaRegQuestionCircle
                                  style={{
                                    color:
                                      clickedAssignment === assignment.id &&
                                        fromMenu
                                        ? "#212153"
                                        : "#878787",
                                  }}
                                />
                              </h5>
                              <h5
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  color:
                                    clickedAssignment === assignment.id &&
                                      fromMenu
                                      ? "#212153"
                                      : "#878787",
                                }}
                              >
                                {assignment.title}
                                {assignment.is_marked && <FaLock />}
                                {!assignment.is_marked &&
                                  assignment.submissions > 0 && (
                                    <FaRegCheckCircle
                                      color="#00A5A2
                                  "
                                    />
                                  )}
                              </h5>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {menus.map((menu) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaArrowRight
                  size="0.7rem"
                  color="#00a5a2"
                  style={{
                    opacity: clickedMenu === menu.id ? 1 : 0,
                    marginRight: "10px",
                  }}
                />
                <div
                  key={menu.link}
                  className={`${styles.openContent} ${styles.menu}`}
                  style={{
                    padding: "15px 10px",
                    margin: "0",
                    width: "100%",
                    backgroundColor:
                      clickedMenu === menu.id ? "#FFC83B5a" : "#ffffff",
                  }}
                  onClick={() => {
                    setClickedTopic("");
                    // setClickedChapter("");
                    setClickedMenu(menu.id);
                    setClosePanel(screenWidth < 1200 ? true : false);

                    setClickedAssignment("");
                    setAssignmentIndex("");
                    setAssignmentTitle("");
                    setFromMenu(false);
                    setCurrentPath(`learning/${menu.link}`);
                  }}
                >
                  <h4
                    style={{
                      marginBottom: "0",
                      color: clickedMenu === menu.id ? "#212153" : "#212529",
                    }}
                  >
                    {menu.name}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Switch>
          <Route path={`${path}/:cohortId/:chapterId/:topicId`}>
            <LMSContent
              traineeId={traineeId}
              cohortId={cohortId}
              baseUrl={baseUrl}
              topic={selectedTopic}
              content={topicContent}
              loadingTopicContent={loadingTopicContent}
              setClickedTopic={setClickedTopic}
              closePanel={closePanel}
              setClosePanel={setClosePanel}
              screenWidth={screenWidth}
              actionId={actionId}
              setActionId={setActionId}
            // assignment={assignment}
            />
          </Route>
          <Route path={`${path}/assignments`}>
            <LMSAssignments
              cohortId={cohortId}
              traineeId={traineeId}
              closePanel={closePanel}
              setClosePanel={setClosePanel}
              screenWidth={screenWidth}
              baseUrl={baseUrl}
              fromMenu={fromMenu}
              menuAssignmentId={clickedAssignment}
              menuIndex={assignmentIndex}
              assignmentTitle={assignmentTitle}
              setCurrentPath={setCurrentPath}
              classId={classId}
              lastChapter={lastChapter}
            />
          </Route>
          <Route path={`${path}/scores`}>
            <Scores
              closePanel={closePanel}
              setClosePanel={setClosePanel}
              screenWidth={screenWidth}
              baseUrl={baseUrl}
              traineeId={traineeId}
              cohortId={cohortId}
            />
          </Route>
          <Route path={`${path}/resources`}>
            <LMSResources
              closePanel={closePanel}
              setClosePanel={setClosePanel}
              screenWidth={screenWidth}
            />
          </Route>
          <Route path={`${path}/notes`}>
            <LMSNotes
              traineeId={traineeId}
              userId={userId}
              cohortId={cohortId}
              baseUrl={baseUrl}
              closePanel={closePanel}
              setClosePanel={setClosePanel}
              screenWidth={screenWidth}
            />
          </Route>
          <Route path={`${path}/messages`}>
            <LMSMessages
              baseUrl={baseUrl}
              cohortId={cohortId}
              traineeId={traineeId}
              mentorId={mentorId}
              mentorName={mentorName}
              closePanel={closePanel}
              setClosePanel={setClosePanel}
              screenWidth={screenWidth}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default ProgramDetails;
