import React, { useState, useEffect, useContext } from "react";
import styles from './PostTrainingSurvey.module.scss';
import surveyStyles from "../../components/Survey/Survey.module.scss"
import Dashboard from '../../components/Dashboard/Dashboard'
import Survey from '../../components/Survey/Survey';
import Button from '../../components/Button/Button'
import {
    Route,
    useRouteMatch,
    Switch,
    useLocation,
    useHistory,
} from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FaCheckCircle } from "react-icons/fa";
import { applicRoutes } from "../../routes/sidebarRoutes";
import { Context as AuthContext } from "../../context/AuthContext";



import { FaBusinessTime, FaCloudUploadAlt } from "react-icons/fa";
import LoaderIntro from "react-spinners/SyncLoader";
import { TiCancelOutline } from "react-icons/ti";
import noLoan from "../../assets/no-loan.png";
import { ToastContainer, toast } from "react-toastify";
import { FaArrowCircleLeft } from "react-icons/fa";


const PostTrainingSurvey = ({
    doneSurvey,
    setDoneSurvey,
    doneSurveyData,
    checkDoneSurvey,
}) => {
    const { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const [survey, setSurvey] = useState("");
    const [surveyTitle, setSurveyTitle] = useState("");
    // const [entryORexit, setEntryORexit] = useState("entry");
    const [round, setRound] = useState(1);

    const [formValid, setFormValid] = useState(true);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [questions, setQuestions] = useState([]);
    const [submitButton, setSubmitButton] = useState("Submit");
    const [hovered, setHovered] = useState();
    const [displayOther, setDisplayOther] = useState(false);
    const [otherValues, setOtherValues] = useState({});
    const [baseUrl, setBaseUrl] = useState("")
    const [cohortId, setCohortId] = useState();
    const [traineeId, setTraineeId] = useState();
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");



    useEffect(() => {
        if (location.host === "localhost:3000") {
            setBaseUrl("http://space.somoafrica-org/lms/api/v1/");
        } else {
            setBaseUrl("https://digikua.co.ke/lms/api/v1/");
        }
    }, []);

    const {
        state: { user },
    } = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;
        setEmail(user.email);
    }, [user]);

    useEffect(() => {
        if (email) {
            const formData = new FormData();

            formData.append("email", email);

            const requestOptions = {
                method: "POST",
                headers: {},
                body: formData,
            };

            fetch(
                "https://space.somoafrica.org/api.php?resource=user&action=readByIdOrPhoneOrEmail",
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    setUserId(data.data[0].id);
                    setName(data.data[0].name.split(" ")[0]);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            console.log("I am not boarding");
        }
    }, [email]);

    useEffect(() => {
        if (!userId) return;

        const getTraineeData = async () => {
            try {
                const res = await fetch(`${baseUrl}trainees?user_id=${userId}`);

                if (!res.ok)
                    throw new Error("Something went wrong with fetching trainee");

                const data = await res.json();
                if (data.Response === "False") throw new Error("Trainee not found");
                setTraineeId(data.data[0].id);
                setCohortId(data.data[0].cohortId);
            } catch (error) {
                console.log(Error);
            }
        };

        getTraineeData(userId);
    }, [userId]);


    return (
        <Dashboard sidebarRoutes={applicRoutes} location={location}>
            <Switch>
                <Route path={`${path}/home`}>
                    <div className={styles.creditTable}>
                        <div className={styles.welcomeAcc}>
                            <h2>Welcome to Somo Post Training Survey</h2>
                            <hr />
                            <p>
                                Hi {name ? name : "there"}, <br />
                                We're excited to have you with us!
                                <br />
                                Please take a moment to complete the survey.
                                <br />
                                <br />
                                <strong>
                                    <i>Available Surveys*:</i>
                                </strong>
                                <br />
                            </p>
                            <div className={styles.surveyList} style={{ marginTop: "40px" }}>
                                <div>
                                    <h3 className={styles.surveyTitle}>Buruka Post Training Survey</h3>
                                    <button
                                        className={`${styles.button}`}
                                        onClick={() => {
                                            history.push(`${path}/survey/buruka-post-training`);
                                            setSurvey("buruka-post-training");
                                            setSurveyTitle("Buruka Post Training Survey");
                                        }}
                                    >
                                        Take Survey
                                    </button>
                                </div>
                                <FadeLoader size="4em" className={styles.icon} />
                            </div>
                        </div>
                    </div>
                </Route>
                <Route path={`${path}/survey/:survey`}>
                    <Survey
                        traineeId={traineeId}
                        cohortId={cohortId}
                        baseUrl={baseUrl}
                        survey={survey}
                        surveyTitle={surveyTitle}
                        setSurvey={setSurvey}
                        setSurveyTitle={setSurveyTitle}
                        round={round}
                        from="post-training"
                    />
                </Route>
            </Switch>
        </Dashboard>
    );
};

export default PostTrainingSurvey;








// <div className={surveyStyles.container}>
//     <Button
//         bgColor="#cc6328"
//         size="sm"
//         color="#fff"
//         className={surveyStyles.button}
//         clicked={() => {
//             setSurvey("");
//             setSurveyTitle("");
//             history.push("/dashboard/post-training-survey/home");
//         }}
//     >
//         <FaArrowCircleLeft />
//     </Button>
//     <div
//         className={surveyStyles.applicationComplete}
//         style={{ marginBottom: "20px" }}
//     >
//         <ToastContainer position="top-center" />
//         <h2>
//             <FaBusinessTime size="2em" color="#cc6328" /> {surveyTitle}
//         </h2>
//         {!loading && !error && questions.length > 0 && (
//             <p style={{ textAlign: "left" }}>
//                 Answer all questions
//                 <br />
//             </p>
//         )}
//         {loading && (
//             <div>
//                 <p style={{ textAlign: "left" }}>
//                     Loading&nbsp;
//                     <LoaderIntro color="#00a5a2" />
//                 </p>
//             </div>
//         )}
//         {!loading && error && (
//             <>
//                 <div className={surveyStyles.errorQuestions}>
//                     <TiCancelOutline
//                         className={surveyStyles.mobileNoScores}
//                         size="5em"
//                         color="rgba(116, 23, 99, 0.6)"
//                     />
//                     <p>{error}</p>
//                 </div>
//             </>
//         )}
//         {!loading && !error && questions.length < 1 && (
//             <>
//                 <div className={surveyStyles.noQuestions}>
//                     <p>Survey questions have not been added.</p>
//                     <img src={noLoan} alt="No questions" height="250" />
//                 </div>
//             </>
//         )}
//         {!loading && !error && questions.length > 0 && (
//             <form method="POST" name="q_survey_form" onSubmit={handleSurveySubmit}>
//                 <div className={surveyStyles.quizListHold} style={{ textAlign: "left" }} id="quizList" survey_info="quarterly_survey">
//                     <input type="text" name="cohortId" value={cohortId} hidden />
//                     <input type="text" name="traineeId" value={traineeId} hidden />

//                     {questions.map((quiz, index) => (
//                         <div
//                             className={surveyStyles.quizList}
//                             key={index}
//                             style={{
//                                 transform: hovered === quiz.id && "scale(1.05)",
//                                 boxShadow: hovered === quiz.id && "10px 10px 10px #bcbcbc",
//                                 border: (!quiz.isHeader && quiz.id !== "END") && "1px solid #00a5a2",
//                             }}
//                             onMouseEnter={() => setHovered((!quiz.isHeader && quiz.id !== "END") && quiz.id)}
//                             onMouseLeave={() => setHovered()}
//                         >
//                             <div className={surveyStyles.quizListInfo}>
//                                 <label>
//                                     <p>
//                                         {!quiz.name && quiz.options && !quiz.isHeader && (
//                                             <span style={{ color: "#CC6328", border: "none" }}>
//                                                 {quiz.id}.
//                                             </span>
//                                         )}
//                                         {!quiz.name &&
//                                             !quiz.options &&
//                                             quiz.input !== false && !quiz.isHeader && (
//                                                 <span style={{ color: "#CC6328", border: "none" }}>
//                                                     {quiz.id}
//                                                     {". "}
//                                                 </span>
//                                             )}
//                                         {quiz.id !== "END" && quiz.text}
//                                         {quiz.id === "END" &&
//                                             <p style={{ marginTop: "20px", fontSize: "1rem", fontWeight: "normal", lineHeight: "170%" }}>{quiz.text}</p>}
//                                         {quiz.isHeader && <h4 style={{ color: "#CC6328" }}>{quiz.title}</h4>}
//                                         {quiz.isHeader && quiz.description &&
//                                             <p style={{ marginTop: "20px", fontSize: "1rem", fontWeight: "normal", lineHeight: "170%" }}>{quiz.description}</p>
//                                         }
//                                         {!quiz.name && quiz.options && quiz.multiple && (
//                                             <span style={{ fontSize: "0.7rem", border: "none" }}>
//                                                 {" "}
//                                                 (Select all that apply)
//                                             </span>
//                                         )}
//                                     </p>
//                                     {quiz.imageLink && (
//                                         <div className={surveyStyles.imgContainer}>
//                                             <img src={quiz.imageLink} alt={quiz.id} />
//                                         </div>
//                                     )}

//                                     {quiz.name && !quiz.options && (
//                                         <textarea
//                                             rows={1}
//                                             id={quiz.name}
//                                             name={quiz.name}
//                                             required={quiz.required}
//                                         ></textarea>
//                                     )}

//                                     {!quiz.name && !quiz.options && quiz.input !== false && !quiz.isHeader && (
//                                         <textarea
//                                             rows={1}
//                                             id={quiz.id}
//                                             name={`Q${quiz.id}`}
//                                             required={quiz.required}
//                                         ></textarea>
//                                     )}
//                                     {!quiz.name && quiz.options && !quiz.multiple && !quiz.isHeader && (
//                                         <select
//                                             id={`Q${quiz.id}`}
//                                             name={`Q${quiz.id}`}
//                                             required={quiz.required}
//                                         >
//                                             <option value="" selected disabled>
//                                                 -- Select --
//                                             </option>
//                                             {Object.entries(quiz.options).map(([key, option]) => (
//                                                 <option key={key} value={key}>
//                                                     {option}
//                                                 </option>
//                                             ))}
//                                         </select>
//                                     )}

//                                     {/* Multiple select with "Other" option */}
//                                     {!quiz.name && quiz.options && quiz.multiple && !quiz.isHeader && (
//                                         <>
//                                             {Object.entries(quiz.options).map(([key, option]) => (
//                                                 <div key={key} className={surveyStyles.check}>
//                                                     <input
//                                                         type="checkbox"
//                                                         id={`${key}${quiz.id}`}
//                                                         name={`Q${quiz.id}`}
//                                                         value={key}
//                                                         onChange={() => handleCheckboxChange(quiz.id, key)}
//                                                     />
//                                                     <label htmlFor={`${key}${quiz.id}`}>{option}</label>

//                                                     {/* "Other" input field */}
//                                                     {key === "_" && displayOther[quiz.id] && (
//                                                         <input
//                                                             type="text"
//                                                             className={surveyStyles.other}
//                                                             required
//                                                             name={`Q${quiz.id}`}
//                                                             value={otherValues[quiz.id] || ""}
//                                                             onChange={(e) =>
//                                                                 handleOtherInputChange(quiz.id, e.target.value)
//                                                             }
//                                                         />
//                                                     )}
//                                                 </div>
//                                             ))}
//                                         </>
//                                     )}
//                                 </label>
//                             </div>
//                         </div>
//                     ))}
//                 </div>

//                 <div className={surveyStyles.quizBtnLdr}>
//                     <button type="submit" className={surveyStyles.surveySubmitBtn}>
//                         Submit&nbsp;
//                         <FaCloudUploadAlt size="2em" color="#00a5a2" />
//                     </button>
//                 </div>
//             </form>
//         )}
//         <br />
//     </div>
// </div>
