import React, { useEffect, useState, useContext } from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import styles from "./Digikua.module.scss";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import LoaderIntro from "react-spinners/SyncLoader";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import "jspdf-autotable";

import { clientRoutes } from "../../routes/sidebarRoutes";
import { applicRoutes } from "../../routes/sidebarRoutes";
import {
  useLocation,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as UserContext } from "../../context/UserContext";

import SummaryCards from "../../components/SummaryCards/SummaryCards";
import Button from "../../components/Button/Button";
import DigikuaButton from "../../components/DigikuaButton/DigikuaButton";
import DownloadCashflow from "../../components/DownloadCashflow/DownloadCashflow";
import DigiKuaAnalytics from "../../components/DigiKuaAnalytics/DigiKuaAnalytics";

import {
  FaArrowLeft,
  FaXmark,
  FaCircleCheck,
  FaRegCopy,
  FaFileExcel,
  FaFileCsv,
  FaFilePdf,
  FaPrint,
  FaCartPlus,
  FaCartShopping,
  FaSackDollar,
  FaCartFlatbedSuitcase,
  FaChartLine,
  FaClone,
  FaLifeRing,
  FaCircleDollarToSlot,
  FaMoneyBills,
  FaMoneyBillTransfer,
  FaChartGantt,
  FaDiagramProject,
  FaBoxesStacked,
  FaPeopleCarryBox,
  FaTruckRampBox,
  FaFileInvoice,
  FaPlus,
  FaEye,
  FaRegPenToSquare,
  FaAngleLeft,
  FaRankingStar,
  FaHandsHolding,
  FaReadme,
} from "react-icons/fa6";
import { FaChartArea } from "react-icons/fa";
import globalVars from "../../utils/globalVars";
import resolveToken from "../../utils/resolveToken";

const Digikua = () => {
  const location = useLocation("");
  const history = useHistory();
  const { path } = useRouteMatch();
  const [component, setComponent] = useState("");

  const getUserType = (path) => {
    if (path.includes("/applicant/")) {
      return "applicant";
    } else if (path.includes("/client/")) {
      return "client";
    } else {
      return "unknown";
    }
  };

  const initialPath = getUserType(location.pathname);
  const applicantBasePath = "applicant";
  const [applicantPath, setApplicantPath] = useState(
    "dashboard/digikua/applicant"
  );
  const [clientPath, setClientPath] = useState("dashboard/digikua/client");

  const [baseUrl, setBaseUrl] = useState(
    "https://space.somoafrica.org/api.php"
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVariants, setIsLoadingVariants] = useState(false);
  const [error, setError] = useState(null);
  const [loadingAnalytics, setLoadingAnalytics] = useState(true);

  const [selectedYear, setSelectedYear] = useState();
  const [mode, setMode] = useState("");

  // add buttons
  const [addSaleButton, setAddSaleButton] = useState("Add");
  const [filterId, setFilterId] = useState("");
  const [nature, setNature] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [filteredProductsData, setFilteredProductsData] = useState([]);
  const [filteredServicesData, setFilteredServicesData] = useState([]);
  const [addExpenseButton, setAddExpenseButton] = useState("Add");
  const [addProductButton, setAddProductButton] = useState("Add");
  const [addProductVariantButton, setAddProductVariantButton] = useState("Add");
  const [addServiceButton, setAddServiceButton] = useState("Add");
  const [addServiceVariantButton, setAddServiceVariantButton] = useState("Add");
  const [addInvestmentButton, setAddInvestmentButton] = useState("Add");
  const [addInventoryButton, setAddInventoryButton] = useState("Add");

  // edit buttons
  const [editSaleButton, setEditSaleButton] = useState("Update");
  const [editExpenseButton, setEditExpenseButton] = useState("Update");
  const [editProductButton, setEditProductButton] = useState("Update");
  const [editProductVariantButton, setEditProductVariantButton] =
    useState("Update");
  const [editServiceButton, setEditServiceButton] = useState("Update");
  const [editServiceVariantButton, setEditServiceVariantButton] =
    useState("Update");
  const [editInvestmentButton, setEditInvestmentButton] = useState("Update");
  const [editInventoryButton, setEditInventoryButton] = useState("Update");

  const [productSalesData, setProductSalesData] = useState([]);
  const [serviceSalesData, setServiceSalesData] = useState([]);
  const [cogData, setCogData] = useState([]);
  const [capexData, setCapexData] = useState([]);
  const [opexData, setOpexData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [allProductVariants, setAllProductVariants] = useState([]);
  const [mainId, setMainId] = useState("all");
  const [servicesData, setServicesData] = useState([]);
  const [allServiceVariants, setAllServiceVariants] = useState([]);
  const [investmentsData, setInvestmentsData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);

  const [productCategory, setProductCategory] = useState([]);
  const [measurement, setMeasurement] = useState([]);
  const [globalProductsData, setGlobalProductsData] = useState([]);
  const [globalServiceData, setGlobalServiceData] = useState([]);
  const [globalProductVariantsData, setGlobalProductVariantsData] = useState(
    []
  );
  const [globalServiceVariantsData, setGlobalServiceVariantsData] = useState(
    []
  );
  const [investmentTypeData, setInvestmentTypeData] = useState([]);
  const [saleTypeData, setSaleTypeData] = useState([]);
  const [saleMin, setSaleMin] = useState("");
  const [saleMax, setSaleMax] = useState("");

  // Adding state
  const [successAddSale, setSuccessAddSale] = useState(false);
  const [successAddExpense, setSuccessAddExpense] = useState(false);
  const [successAddProduct, setSuccessAddProduct] = useState(false);
  const [successAddProductVariant, setSuccessAddProductVariant] =
    useState(false);
  const [successAddService, setSuccessAddService] = useState(false);
  const [successAddServiceVariant, setSuccessAddServiceVariant] =
    useState(false);
  const [successAddInvestment, setSuccessAddInvestment] = useState(false);
  const [successAddInventory, setSuccessAddInventory] = useState(false);

  // Editing state
  const [successEditSale, setSuccessEditSale] = useState(false);
  const [successEditExpense, setSuccessEditExpense] = useState(false);
  const [successEditProduct, setSuccessEditProduct] = useState(false);
  const [successEditProductVariant, setSuccessEditProductVariant] =
    useState(false);
  const [successEditService, setSuccessEditService] = useState(false);
  const [successEditServiceVariant, setSuccessEditServiceVariant] =
    useState(false);
  const [successEditInvestment, setSuccessEditInvestment] = useState(false);
  const [successEditInventory, setSuccessEditInventory] = useState(false);

  const [showAddSaleModal, setShowAddSaleModal] = useState(false);
  const [showEditSaleModal, setShowEditSaleModal] = useState(false);
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [showEditExpenseModal, setShowEditExpenseModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [showEditServiceModal, setShowEditServiceModal] = useState(false);
  const [showAddProductVariantModal, setShowAddProductVariantModal] =
    useState(false);
  const [showEditProductVariantModal, setShowEditProductVariantModal] =
    useState(false);
  const [showAddServiceVariantModal, setShowAddServiceVariantModal] =
    useState(false);
  const [showEditServiceVariantModal, setShowEditServiceVariantModal] =
    useState(false);
  const [showAddInvestmentModal, setShowAddInvestmentModal] = useState(false);
  const [showEditInvestmentModal, setShowEditInvestmentModal] = useState(false);
  const [showAddInventoryModal, setShowAddInventoryModal] = useState(false);
  const [showEditInventoryModal, setShowEditInventoryModal] = useState(false);

  // addingSale
  const [saleNature, setSaleNature] = useState("--Select--");
  const [saleProduct, setSaleProduct] = useState("--Select--");
  const [saleProductVariant, setSaleProductVariant] = useState("--Select--");
  const [saleService, setSaleService] = useState("--Select--");
  const [saleServiceVariant, setSaleServiceVariant] = useState("--Select--");
  const [saleQuantity, setSaleQuantity] = useState("");
  const [saleRevenue, setSaleRevenue] = useState("");
  const [saleDateSold, setSaleDateSold] = useState("");
  const [saleType, setSaleType] = useState("--Select--");
  const [saleClientPhone, setSaleClientPhone] = useState("");

  // editingExpense
  const [addExpNature, setAddExpNature] = useState("--Select--");
  const [addExpName, setAddExpName] = useState("");
  const [addExpAmountSpent, setAddExpAmountSpent] = useState("");
  const [addExpTransactionDate, setAddExpTransactionDate] = useState("");
  const [addExpTypeOfExpense, setAddExpTypeOfExpense] = useState("--Select--");
  useState("--Select--");
  const [addExpSupplierPhone, setAddExpSupplierPhone] = useState("");
  const [addExpProductVariant, setAddExpProductVariant] =
    useState("--Select--");
  const [addExpLifespan, setAddExpLifespan] = useState("");

  // addingproduct
  const [productName, setProductName] = useState("");
  const [prdCategory, setPrdCategory] = useState("--Select--");
  const [productMeasurement, setProductMeasurement] = useState("--Select--");
  const [productPrice, setProductPrice] = useState("");

  // addingproductVariant
  const [productVariantName, setProductVariantName] = useState("");
  const [mainProduct, setMainProduct] = useState("--Select--");
  const [productVariantPrice, setProductVariantPrice] = useState("");
  const [productVariantSQ, setProductVariantSQ] = useState("");

  // addingservice
  const [serviceName, setServiceName] = useState("");
  const [srcCategory, setSrcCategory] = useState("--Select--");
  const [serviceMeasurement, setServiceMeasurement] = useState("--Select--");
  const [servicePrice, setServicePrice] = useState("");

  // addingserviceVariant
  const [serviceVariantName, setServiceVariantName] = useState("");
  const [mainService, setMainService] = useState("--Select--");
  const [serviceVariantPrice, setServiceVariantPrice] = useState("");

  // addinginvestment
  const [investmentType, setInvestmentType] = useState("--Select--");
  const [narration, setNarration] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [investmentDate, setInvestmentDate] = useState("");

  //addinginventory
  const [inventoryId, setInventoryId] = useState("");
  const [inventoryProductVId, setInventoryProductVId] = useState("--Select--");
  const [inventoryProductVName, setInventoryProductVName] = useState("");
  const [inventoryStock, setInventoryStock] = useState("");
  const [inventoryPrice, setInventoryPrice] = useState("");
  const [inventoryDate, setInventoryDate] = useState("");

  // updatingSale
  const [editSaleId, setEditSaleId] = useState("");
  const [saleEditNature, setSaleEditNature] = useState("");
  const [saleEditProductVariant, setSaleEditProductVariant] = useState("");
  const [saleEditServiceVariant, setSaleEditServiceVariant] = useState("");
  const [saleEditQuantity, setSaleEditQuantity] = useState("");
  const [saleEditRevenue, setSaleEditRevenue] = useState("");
  const [saleEditDateSold, setSaleEditDateSold] = useState("");
  const [saleEditType, setSaleEditType] = useState("");
  const [saleEditClientPhone, setSaleEditClientPhone] = useState("");

  // editingExpense
  const [editExpId, setEditExpId] = useState("");
  const [editExpNature, setEditExpNature] = useState("");
  const [editExpName, setEditExpName] = useState("");
  const [editExpAmountSpent, setEditExpAmountSpent] = useState("");
  const [editExpTransactionDate, setEditExpTransactionDate] = useState("");
  const [editExpTypeOfExpense, setEditExpTypeOfExpense] = useState("");
  const [editExpSupplierPhone, setEditExpSupplierPhone] = useState("");
  const [editExpProductVariant, setEditExpProductVariant] = useState("");
  const [editExpLifespan, setEditExpLifespan] = useState("");

  // updatingaproduct
  const [editProductId, setEditProductId] = useState("");
  const [editProductName, setEditProductName] = useState("");
  const [editProductCategoryId, setEditProductCategoryId] = useState("");
  const [editProductMeasurementId, setEditProductMeasurementId] = useState("");
  const [editProductPrice, setEditProductPrice] = useState("");

  // updatingaproductvariants
  const [editProductVariantId, setEditProductVariantId] = useState("");
  const [editProductVariantName, setEditProductVariantName] = useState("");
  const [editMainProductVariant, setEditMainProductVariant] = useState("");
  const [editProductVariantPrice, setEditProductVariantPrice] = useState("");
  const [editProductVariantSQ, setEditProductVariantSQ] = useState("");

  // updatingservice
  const [editServiceId, setEditServiceId] = useState("");
  const [editServiceName, setEditServiceName] = useState("");
  const [editSrcCategory, setEditSrcCategory] = useState("");
  const [editServiceMeasurement, setEditServiceMeasurement] = useState("");
  const [editServicePrice, setEditServicePrice] = useState("");

  // updatingaproductvariants
  const [editServiceVariantId, setEditServiceVariantId] = useState("");
  const [editServiceVariantName, setEditServiceVariantName] = useState("");
  const [editMainServiceVariant, setEditMainServiceVariant] = useState("");
  const [editServiceVariantPrice, setEditServiceVariantPrice] = useState("");

  // updatinginvestment
  const [editingInvestmentId, setEditingInvestmentId] = useState("");
  const [editInvestmentType, setEditInvestmentType] = useState();
  const [editNarration, setEditNarration] = useState("");
  const [editInvestmentAmount, setEditInvestmentAmount] = useState("");
  const [editInvestmentDate, setEditInvestmentDate] = useState("");

  //updatinginventory
  const [editInventoryId, setEditInventoryId] = useState("");
  const [editInventoryProductVId, setEditInventoryProductVId] = useState("");
  const [editInventoryProductVName, setEditInventoryProductVName] =
    useState("");
  const [editInventoryStock, setEditInventoryStock] = useState("");
  const [editInventoryPrice, setEditInventoryPrice] = useState("");
  const [editInventoryDate, setEditInventoryDate] = useState("");

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredDiv, setHoveredDiv] = useState();

  const [copySuccess, setCopySuccess] = useState(false);

  const [filterWord, setFilterWord] = useState("");

  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const [productsSalesRevenue, setProductsSalesRevenue] = useState();
  const [serviceSalesRevenue, setServiceSalesRevenue] = useState();

  const [cogSummary, setCogSummary] = useState();
  const [opexSummary, setOpexSummary] = useState();
  const [capexSummary, setCapexSummary] = useState();

  //Handling modals
  const handleShowAddSaleModal = () => setShowAddSaleModal(true);
  const handleCloseAddSaleModal = () => setShowAddSaleModal(false);

  const handleShowEditSaleModal = () => setShowEditSaleModal(true);
  const handleCloseEditSaleModal = () => setShowEditSaleModal(false);

  const handleShowAddExpenseModal = () => setShowAddExpenseModal(true);
  const handleCloseAddExpenseModal = () => setShowAddExpenseModal(false);

  const handleShowEditExpenseModal = () => setShowEditExpenseModal(true);
  const handleCloseEditExpenseModal = () => setShowEditExpenseModal(false);

  const handleShowAddProductModal = () => setShowAddProductModal(true);
  const handleCloseAddProductModal = () => setShowAddProductModal(false);

  const handleShowEditProductModal = () => setShowEditProductModal(true);
  const handleCloseEditProductModal = () => setShowEditProductModal(false);

  const handleShowAddServiceModal = () => setShowAddServiceModal(true);
  const handleCloseAddServiceModal = () => setShowAddServiceModal(false);

  const handleShowEditServiceModal = () => setShowEditServiceModal(true);
  const handleCloseEditServiceModal = () => setShowEditServiceModal(false);

  const handleShowAddProductVariantModal = () =>
    setShowAddProductVariantModal(true);
  const handleCloseAddProductVariantModal = () =>
    setShowAddProductVariantModal(false);

  const handleShowEditProductVariantModal = () =>
    setShowEditProductVariantModal(true);
  const handleCloseEditProductVariantModal = () =>
    setShowEditProductVariantModal(false);

  const handleShowAddServiceVariantModal = () =>
    setShowAddServiceVariantModal(true);
  const handleCloseAddServiceVariantModal = () =>
    setShowAddServiceVariantModal(false);

  const handleShowEditServiceVariantModal = () =>
    setShowEditServiceVariantModal(true);
  const handleCloseEditServiceVariantModal = () =>
    setShowEditServiceVariantModal(false);

  const handleShowAddInvestmentModal = () => setShowAddInvestmentModal(true);
  const handleCloseAddInvestmentModal = () => setShowAddInvestmentModal(false);

  const handleShowEditInvestmentModal = () => setShowEditInvestmentModal(true);
  const handleCloseEditInvestmentModal = () =>
    setShowEditInvestmentModal(false);

  const handleShowAddInventoryModal = () => setShowAddInventoryModal(true);
  const handleCloseAddInventoryModal = () => setShowAddInventoryModal(false);

  const handleShowEditInventoryModal = () => setShowEditInventoryModal(true);
  const handleCloseEditInventoryModal = () => setShowEditInventoryModal(false);

  // const [cashflowSummaryData, setCashflowSummaryData] = useState([]);
  const [loadingCashflowSummary, setLoadingCashflowSummaryData] =
    useState(true);
  const [cashflowSummaryError, setCashflowSummaryError] = useState("");

  const handleButtonClick = () => {
    console.log("Open survey page");
  };

  const fetchOverview = () => {
    const revenue = productsSalesRevenue + serviceSalesRevenue;
    const grossProfit = revenue - cogSummary;
    const operatingCashflow = grossProfit - opexSummary;
    const netCashflow = operatingCashflow - capexSummary;
    const grossMargin = revenue !== 0 ? grossProfit / revenue : 0;

    const getValue = (value, error, loaderColor) => {
      if (error) return "Error occurred.";
      if (value > -1 || value === 0) return value.toLocaleString();
      return <LoaderIntro color={loaderColor} />;
    };

    const cashflowSummaryData = [
      {
        title: "Revenue",
        value: getValue(revenue, error, "#CC6328"),
        icon: <FaSackDollar color="#fff" size="1.5rem" />,
        color: "#CC6328",
      },
      {
        title: "Cost of Goods",
        value: getValue(cogSummary, error, "#FFC83B"),
        icon: <FaCartFlatbedSuitcase color="#fff" size="1.5rem" />,
        color: "#FFC83B",
      },
      {
        title: "Gross Profits",
        value: getValue(grossProfit, error, "#00a5a2"),
        icon: <FaChartLine color="#fff" size="1.5rem" />,
        color: "#00A5A2",
      },
      {
        title: "Gross Margins",
        value: getValue(grossMargin, error, "#C4233C"),
        icon: <FaClone color="#fff" size="1.5rem" />,
        color: "#C4233C",
      },
      {
        title: "Operating Expense",
        value: getValue(opexSummary, error, "#212153"),
        icon: <FaLifeRing color="#fff" size="1.5rem" />,
        color: "#212153",
      },
      {
        title: "Operating Cashflow",
        value: getValue(operatingCashflow, error, "#CC6328"),
        icon: <FaCircleDollarToSlot color="#fff" size="1.5rem" />,
        color: "#CC6328",
      },
      {
        title: "Capital Expenditure",
        value: getValue(capexSummary, error, "#FFC83B"),
        icon: <FaMoneyBills color="#fff" size="1.5rem" />,
        color: "#FFC83B",
      },
      {
        title: "Net Cashflow",
        value: getValue(netCashflow, error, "#00a5a2"),
        icon: <FaMoneyBillTransfer color="#fff" size="1.5rem" />,
        color: "#00A5A2",
      },
    ];

    return cashflowSummaryData;
  };

  var cashflowSummaryData = fetchOverview();

  // Dates which sales and investments can be added
  useEffect(() => {
    const minDateObj = new Date();
    minDateObj.setDate(minDateObj.getDate() - 180);

    const currentDateObj = new Date();

    const minDateStr = minDateObj.toISOString().split("T")[0];
    const currentDateStr = currentDateObj.toISOString().split("T")[0];

    setMinDate(minDateStr);
    setMaxDate(currentDateStr);
  }, []);

  useEffect(() => {
    const minDateObj = new Date();
    minDateObj.setDate(minDateObj.getDate() - 180);

    const currentDateObj = new Date();

    const minDateStr = minDateObj.toISOString().split("T")[0];
    const currentDateStr = currentDateObj.toISOString().split("T")[0];

    setSaleMin(minDateStr);
    setSaleMax(currentDateStr);
  }, []);

  // Handling path when accessing variants
  useEffect(() => {
    if (component) {
      if (
        component === "product-variants" ||
        component === "service-variants"
      ) {
        history.push(
          `/${initialPath === applicantBasePath ? applicantPath : clientPath
          }/cashflow-summary-detailed/${component}/${mainId}`
        );
      } else if (component === "none") {
        history.push(
          `/${initialPath === applicantBasePath ? applicantPath : clientPath
          }/cashflow-summary`
        );
        window.location.reload();
      } else {
        history.push(
          `/${initialPath === applicantBasePath ? applicantPath : clientPath
          }/cashflow-summary-detailed/${component}`
        );
      }
    }
  }, [component]);

  // information of user in session
  const [businessId, setBusinessId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const {
    state: { user },
  } = useContext(AuthContext);

  const {
    state: { userDetails },
    getClientDetails,
  } = useContext(UserContext);

  useEffect(() => {
    getClientDetails(user.user_id);
  }, []);

  useEffect(() => {
    setError("");
    if (initialPath === applicantBasePath) {
      setUserId("");
      setUserEmail(user.email);
      setUserPhone(user.phoneNumber);
    } else {
      if (userDetails) {
        const { bioData } = userDetails;
        setUserId("");
        setUserEmail(bioData.email);
        setUserPhone(bioData.phoneNumber);
      } else {
        console.log(typeof userDetails, userDetails);
        var reqOpt = {
          method: "GET",
          headers: { Authorization: `Bearer ` + resolveToken() },
        };
        fetch(`${globalVars.baseUrl}/client/details/${user.user_id}`, reqOpt)
          .then((response) => response.json())
          .then((resp) => {
            if (
              resp.error === "Complete setting up your account" ||
              resp.error === "Complete seting up your account"
            ) {
              toast.error(`${resp.error} to proceed.`);
              history.push("/dashboard/profile");
            } else {
              console.log("-- Auth Ok -> ", resp);
            }
          })
          .catch((error) => {
            console.error("-- Auth Error:", error);
            setError(error.message);
          });
      }
    }
  }, [userDetails]);

  //Getting business ID of user in session based on user email
  useEffect(() => {
    setError("");
    if (userEmail) {
      const formData = new FormData();

      formData.append("readByEmailOrId", userEmail);

      const requestOptions = {
        method: "POST",
        headers: {},
        body: formData,
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=business&action=readByEmailOrId",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setBusinessId(data.business[0].id);
          setBusinessName(data.business[0].name);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      console.log("I am not boarding");
      console.log("umail -> ", typeof userEmail, userEmail);
    }
  }, [userEmail]);

  // Function to fetch variants data with loading and error handling
  const fetchVariantsData = async (resource, mainId) => {
    const requestOptions = {
      method: "GET",
      headers: {},
    };

    let url = `${baseUrl}?resource=${resource}&action=read&businessId=${businessId}`;
    if (mainId !== "all") {
      url += `&main${resource === "entrepreneurProductVariant" ? "Product" : "Service"
        }Id=${mainId}`;
    }

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.data.reverse();
    } catch (error) {
      console.log("Error: " + error);
      throw error;
    }
  };

  // Handling product variants and service variants and clearing filter word
  useEffect(() => {
    const fetchData = async () => {
      setFilterWord("");
      setIsLoadingVariants(true);
      setError("");

      try {
        if (component === "product-variants") {
          const data = await fetchVariantsData(
            "entrepreneurProductVariant",
            mainId
          );
          setAllProductVariants(data);
        } else if (component === "service-variants") {
          const data = await fetchVariantsData(
            "entrepreneurServiceVariant",
            mainId
          );
          setAllServiceVariants(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoadingVariants(false);
        setComponent("");
      }
    };

    fetchData();
  }, [component]);

  useEffect(() => {
    if (!businessId) return;
    const controller = new AbortController();

    if (window.location.href.split("/").pop() !== "cashflow-summary") {
      const segments = window.location.href.split("/");
      const secondToLastSegment = segments[segments.length - 2];
      if (secondToLastSegment === "product-variants") {
        setComponent("product-variants");
        setMainId(window.location.href.split("/").pop());
      } else if (secondToLastSegment === "service-variants") {
        setComponent("service-variants");
        setMainId(window.location.href.split("/").pop());
      } else {
        setComponent(window.location.href.split("/").pop());
      }
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError("");

      try {
        const endpoints = [
          `${baseUrl}?resource=productCategory&action=read`,
          `${baseUrl}?resource=entrepreneurSaleType&action=read`,
          `${baseUrl}?resource=measurement&action=read`,
          `${baseUrl}?resource=entrepreneurProduct&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurProductVariant&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurService&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurServiceVariant&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurInvestmentType&action=read`,
          `${baseUrl}?resource=entrepreneurProductSale&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurServiceSale&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurCapitalExpense&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurOverheadExpense&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurCostOfGoods&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurInvestment&action=read&businessId=${businessId}`,
          `${baseUrl}?resource=entrepreneurProductInventory&action=read&businessId=${businessId}`,
        ];

        const fetchPromises = endpoints.map((url) =>
          fetch(url, { signal: controller.signal }).then((response) => {
            if (!response.ok) {
              throw new Error(`Something went wrong`);
            }
            return response.json();
          })
        );
        const responses = await Promise.all(fetchPromises);
        responses.forEach((data, index) => {
          switch (index) {
            case 0:
              setProductCategory(data.data);
              break;

            case 1:
              setSaleTypeData(data.data);
              break;

            case 2:
              setMeasurement(data.data);
              break;

            case 3:
              setProductsData(data.data.reverse());
              setGlobalProductsData(data.data);
              break;

            case 4:
              setGlobalProductVariantsData(data.data);
              break;

            case 5:
              setServicesData(data.data.reverse());
              setGlobalServiceData(data.data);
              break;

            case 6:
              setGlobalServiceVariantsData(data.data);
              break;

            case 7:
              setInvestmentTypeData(data.data);
              break;

            case 8:
              setProductSalesData(data.data.reverse());
              let productsRevenue = 0;
              for (let i = 0; i < data.data.length; i++) {
                productsRevenue += parseInt(data.data[i].revenue);
              }
              setProductsSalesRevenue(productsRevenue);
              break;

            case 9:
              setServiceSalesData(data.data.reverse());
              let serviceRevenue = 0;
              for (let i = 0; i < data.data.length; i++) {
                serviceRevenue += parseInt(data.data[i].revenue);
              }
              setServiceSalesRevenue(serviceRevenue);
              break;

            case 10:
              setCapexData(data.data.reverse());
              let capex = 0;
              for (let i = 0; i < data.data.length; i++) {
                capex += parseInt(data.data[i].amountSpent);
              }
              setCapexSummary(capex);
              break;

            case 11:
              setOpexData(data.data.reverse());
              let opex = 0;
              for (let i = 0; i < data.data.length; i++) {
                opex += parseInt(data.data[i].amountSpent);
              }
              setOpexSummary(opex);
              break;

            case 12:
              setCogData(data.data.reverse());
              let cog = 0;
              for (let i = 0; i < data.data.length; i++) {
                cog += parseInt(data.data[i].amountSpent);
              }
              setCogSummary(cog);
              break;

            case 13:
              setInvestmentsData(data.data.reverse());
              break;

            case 14:
              console.log(data.data);

              setInventoryData(data.data.reverse());
              break;

            default:
              console.log("None");
          }
        });
      } catch (error) {
        if (error.name !== "AbortError") {
          setError(error.message);
          console.log("Error: " + error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [businessId, baseUrl]);

  useEffect(() => {
    if (!filterId) return;

    const fetchData = async () => {
      setIsLoadingVariants(true);
      setFilteredProductsData([]);
      setFilteredServicesData([]);
      setError("");

      try {
        if (nature === "product-variants") {
          const data = await fetchVariantsData(
            "entrepreneurProductVariant",
            filterId
          );
          setFilteredProductsData(data);
        } else if (nature === "service-variants") {
          const data = await fetchVariantsData(
            "entrepreneurServiceVariant",
            filterId
          );
          setFilteredServicesData(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoadingVariants(false);
        setFilterId("");
      }
    };

    fetchData();
  }, [
    filterId,
    nature,
    setIsLoadingVariants,
    setFilteredProductsData,
    setFilteredServicesData,
    setError,
  ]);

  //Sale
  const addSale = () => {
    if (!businessId) {
      toast.error("Missing important information");
      return;
    }

    if (saleNature === "--Select--" || saleNature === "") {
      toast.error("Sale nature cannot be blank");
      return;
    }

    if (saleNature === "Product") {
      if (saleProductVariant === "--Select--" || saleProductVariant === " ") {
        toast.error("Product variant cannot be blank");
        return;
      }
    } else if (saleNature === "Service") {
      if (saleServiceVariant === "--Select--" || saleServiceVariant === " ") {
        toast.error("Service variant cannot be blank");
        return;
      }
    }

    if (!saleQuantity) {
      toast.error("Quantity cannot be blank");
      return;
    }

    if (!saleRevenue) {
      toast.error("Revenue cannot be blank");
      return;
    }

    if (!saleDateSold) {
      toast.error("Date sold cannot be blank");
      return;
    }

    if (saleType === "--Select--" || saleType === " ") {
      toast.error("Type of sale cannot be blank");
      return;
    }

    if (!saleClientPhone) {
      toast.error("Client phone cannot be blank");
      return;
    }
    setAddSaleButton(<LoaderIntro color="#fff" />);

    const data = {
      businessId: businessId,
      productVariantId: saleProductVariant,
      serviceVariantId: saleServiceVariant,
      quantity: saleQuantity,
      revenueAmount: saleRevenue,
      monthSold: new Date(saleDateSold).getMonth() + 1,
      dateSold: new Date(saleDateSold).getDate(),
      yearSold: new Date(saleDateSold).getFullYear(),
      typeOfSale: saleType,
      clientPhone: saleClientPhone,
      whoAdds: {
        id: userId,
        email: userEmail,
        phone: userPhone,
      },
    };

    const requestOptions = {
      method: "POST",
      headers: {},
      body: JSON.stringify(data),
    };

    const apiUrl =
      saleNature === "Product"
        ? "https://space.somoafrica.org/api.php?resource=entrepreneurProductSale&action=create"
        : "https://space.somoafrica.org/api.php?resource=entrepreneurServiceSale&action=create";

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error === false) {
          setComponent("sales");
          setSuccessAddSale(true);
          handleCloseAddSaleModal();
          setSaleNature("--Select--");
          setSaleProductVariant("--Select--");
          setSaleServiceVariant("--Select--");
          setSaleType("--Select--");
          setSaleQuantity("");
          setSaleProduct("--Select--");
          setSaleService("--Select--");
          setSaleRevenue("");
          setSaleDateSold("");
          setSaleClientPhone("");
          setAddSaleButton("Add");
        } else {
          toast.error(data.message);
          setSuccessAddSale(false);
          setAddSaleButton("Add");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const editSale = () => {
    if (!businessId) {
      toast.error("Missing important information");
      return;
    }

    if (saleEditNature === "--Select--" || saleEditNature === "") {
      toast.error("Sale nature cannot be blank");
      return;
    }

    if (saleEditNature === "Product") {
      if (
        saleEditProductVariant === "--Select--" ||
        saleEditProductVariant === " "
      ) {
        toast.error("Product variant cannot be blank");
        return;
      }
    } else if (saleEditNature === "Service") {
      if (
        saleEditServiceVariant === "--Select--" ||
        saleEditServiceVariant === " "
      ) {
        toast.error("Service variant cannot be blank");
        return;
      }
    }

    if (!saleEditQuantity) {
      toast.error("Quantity cannot be blank");
      return;
    }

    if (!saleEditRevenue) {
      toast.error("Revenue cannot be blank");
      return;
    }

    if (!saleEditDateSold) {
      toast.error("Date sold cannot be blank");
      return;
    }

    if (saleEditType === "--Select--" || saleEditType === " ") {
      toast.error("Type of sale cannot be blank");
      return;
    }

    if (!saleEditClientPhone) {
      toast.error("Client phone cannot be blank");
      return;
    }
    setEditSaleButton(<LoaderIntro color="#fff" />);

    const ProductData = {
      id: editSaleId,
      productVariantId: saleEditProductVariant,
      quantity: saleEditQuantity,
      revenue: saleEditRevenue,
      monthSold: new Date(saleEditDateSold).getMonth() + 1,
      dateSold: new Date(saleEditDateSold).getDate(),
      yearSold: new Date(saleEditDateSold).getFullYear(),
      typeOfSale: saleEditType,
      clientPhone: saleEditClientPhone,
      whoUpdates: {
        AddedById: userId,
        AddedByEmail: userEmail,
        AddedByPhone: userPhone,
      },
    };

    const ServiceData = {
      id: editSaleId,
      serviceVariantId: saleEditServiceVariant,
      quantity: saleEditQuantity,
      revenue: saleEditRevenue,
      monthSold: new Date(saleEditDateSold).getMonth() + 1,
      dateSold: new Date(saleEditDateSold).getDate(),
      yearSold: new Date(saleEditDateSold).getFullYear(),
      typeOfSale: saleEditType,
      clientPhone: saleEditClientPhone,
      whoUpdates: {
        AddedById: userId,
        AddedByEmail: userEmail,
        AddedByPhone: userPhone,
      },
    };

    const data =
      saleEditNature === "Product" ? { ...ProductData } : { ...ServiceData };

    const requestOptions = {
      method: "POST",
      headers: {},
      body: JSON.stringify(data),
    };

    const apiUrl =
      saleEditNature === "Product"
        ? "https://space.somoafrica.org/api.php?resource=entrepreneurProductSale&action=update"
        : "https://space.somoafrica.org/api.php?resource=entrepreneurServiceSale&action=update";

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error === false) {
          setComponent("sales");
          setSuccessEditSale(true);
          handleCloseEditSaleModal();
          setEditSaleId("");
          setSaleEditNature("");
          setSaleEditProductVariant("");
          setSaleEditServiceVariant("");
          setSaleEditType("");
          setSaleEditQuantity("");
          setSaleEditRevenue("");
          setSaleEditDateSold("");
          setSaleEditClientPhone("");
          setEditSaleButton("Update");
        } else {
          toast.error(data.message);
          setSuccessEditSale(false);
          setEditSaleButton("Update");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  //Expense
  const addExpense = () => {
    if (addExpNature === "--Select--" || addExpNature === "") {
      toast.error("Nature of Expense cannot be blank");
      return;
    }

    if (addExpTypeOfExpense === "--Select--" || addExpTypeOfExpense === "") {
      toast.error("Type of Expense cannot be blank");
      return;
    }

    if (!addExpSupplierPhone) {
      toast.error("Supplier Phone cannot be blank");
      return;
    }

    if (!addExpTransactionDate) {
      toast.error("Transaction Date cannot be blank");
      return;
    }

    if (!addExpName) {
      toast.error("Name cannot be blank");
      return;
    }

    if (!addExpAmountSpent) {
      toast.error("Amount Spent cannot be blank");
      return;
    }

    if (addExpNature === "COG") {
      if (
        addExpProductVariant === "--Select--" ||
        addExpProductVariant === ""
      ) {
        toast.error("Product Variant cannot be blank");
        return;
      }
    } else if (addExpNature === "CapEx") {
      if (!addExpLifespan) {
        toast.error("Lifespan cannot be blank");
        return;
      }
    }

    setAddExpenseButton(<LoaderIntro color="#fff" />);

    const data = {
      businessId: businessId,
      typeOfExpense: addExpTypeOfExpense,
      supplierPhone: addExpSupplierPhone,
      monthDate: new Date(addExpTransactionDate).getMonth() + 1,
      dayDate: new Date(addExpTransactionDate).getDate(),
      year: new Date(addExpTransactionDate).getFullYear(),
      amountSpent: addExpAmountSpent,
      productVariantId: addExpProductVariant,
      capexName: addExpName,
      cogsName: addExpName,
      lifespanInYears: addExpLifespan,
      whoAdds: {
        id: userId,
        email: userEmail,
        phone: userPhone,
      },
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
    };

    let api = "";
    if (addExpNature === "COG") {
      api =
        "https://space.somoafrica.org/api.php?resource=entrepreneurCostOfGoods&action=create";
    } else if (addExpNature === "CapEx") {
      api =
        "https://space.somoafrica.org/api.php?resource=entrepreneurCapitalExpense&action=create";
    } else if (addExpNature === "OpEx") {
      api =
        "https://space.somoafrica.org/api.php?resource=entrepreneurOverheadExpense&action=create";
    }

    fetch(api, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error === false) {
          setComponent("expenses");
          setSuccessAddExpense(true);
          handleCloseAddExpenseModal();
          setAddExpNature("--Select--");
          setAddExpTypeOfExpense("--Select--");
          setAddExpSupplierPhone("");
          setAddExpTransactionDate("");
          setAddExpAmountSpent("");
          setAddExpProductVariant("--Select--");
          setAddExpName("");
          setAddExpLifespan("");
          setAddExpenseButton("Add");
        } else {
          toast.error(data.message);
          setAddExpenseButton("Add");
          setSuccessAddExpense(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setAddExpenseButton("Add");
        setSuccessAddExpense(false);
      });
  };

  const editExpense = () => {
    if (editExpNature === "--Select--" || editExpNature === "") {
      toast.error("Nature of Expense cannot be blank");
      return;
    }

    if (editExpTypeOfExpense === "--Select--" || editExpTypeOfExpense === "") {
      toast.error("Type of Expense cannot be blank");
      return;
    }

    if (!editExpSupplierPhone) {
      toast.error("Supplier Phone cannot be blank");
      return;
    }

    if (!editExpTransactionDate) {
      toast.error("Transaction Date cannot be blank");
      return;
    }

    if (!editExpName) {
      toast.error("Name cannot be blank");
      return;
    }

    if (!editExpAmountSpent) {
      toast.error("Amount Spent cannot be blank");
      return;
    }

    if (editExpNature === "COG") {
      if (
        editExpProductVariant === "--Select--" ||
        editExpProductVariant === ""
      ) {
        toast.error("Product Variant cannot be blank");
        return;
      }
    } else if (editExpNature === "CapEx") {
      if (!editExpLifespan) {
        toast.error("Lifespan cannot be blank");
        return;
      }
    }

    setEditExpenseButton(<LoaderIntro color="#fff" />);

    const data = {
      businessId: businessId,
      id: editExpId,
      typeOfExpense: editExpTypeOfExpense,
      supplierPhone: editExpSupplierPhone,
      monthDate: new Date(editExpTransactionDate).getMonth() + 1,
      dayDate: new Date(editExpTransactionDate).getDate(),
      year: new Date(editExpTransactionDate).getFullYear(),
      amountSpent: editExpAmountSpent,
      productVariantId: editExpProductVariant,
      name: editExpName,
      lifespanInYears: editExpLifespan,
      whoUpdates: {
        id: userId,
        email: userEmail,
        phone: userPhone,
      },
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
    };

    let api = "";
    if (editExpNature === "COG") {
      api =
        "https://space.somoafrica.org/api.php?resource=entrepreneurCostOfGoods&action=update";
    } else if (editExpNature === "CapEx") {
      api =
        "https://space.somoafrica.org/api.php?resource=entrepreneurCapitalExpense&action=update";
    } else if (editExpNature === "OpEx") {
      api =
        "https://space.somoafrica.org/api.php?resource=entrepreneurOverheadExpense&action=update";
    }

    fetch(api, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error === false) {
          setComponent("expenses");
          setSuccessEditExpense(true);
          handleCloseEditExpenseModal();
          setEditExpNature("");
          setEditExpTypeOfExpense("");
          setEditExpSupplierPhone("");
          setEditExpTransactionDate("");
          setEditExpAmountSpent("");
          setEditExpProductVariant("");
          setEditExpName("");
          setEditExpLifespan("");
          setEditExpenseButton("Update");
        } else {
          toast.error(data.message);
          setEditExpenseButton("Update");
          setSuccessEditExpense(false);
        }
      })
      .catch((error) => {
        toast.error(error);
        setEditExpenseButton("Update");
        setSuccessEditExpense(false);
      });
  };

  //Product
  const addProduct = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!productName) {
      toast.error("Product name cannot be blank");
    } else if (prdCategory === "--Select--" || prdCategory === " ") {
      toast.error("Category cannot be blank");
    } else if (
      productMeasurement === "--Select--" ||
      productMeasurement === " "
    ) {
      toast.error("Measurement cannot be blank");
    } else if (!productPrice) {
      toast.error("Price cannot be blank");
    } else {
      setAddProductButton(<LoaderIntro color="#fff" />);

      const data = {
        businessId: businessId,
        name: productName,
        categoryId: prdCategory,
        measurementId: productMeasurement,
        price: productPrice,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurProduct&action=create",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("products");
            setSuccessAddProduct(true);
            handleCloseAddProductModal();
            setProductName("");
            setPrdCategory("--Select--");
            setProductMeasurement("--Select--");
            setProductPrice("");
            setAddProductButton("Add");
          } else {
            toast.error(data.message);
            setSuccessAddProduct(false);
            setAddProductButton("Add");
          }
        })
        .catch((error) => {
          toast.error(error);
          setSuccessAddProduct(false);
          setAddProductButton("Add");
        });
    }
  };

  const editProduct = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!editProductName) {
      toast.error("Product name cannot be blank");
    } else if (!editProductId) {
      toast.error("Missing important information");
    } else if (
      editProductCategoryId === "--Select--" ||
      editProductCategoryId === " "
    ) {
      toast.error("Category cannot be blank");
    } else if (
      editProductMeasurementId === "--Select--" ||
      editProductMeasurementId === " "
    ) {
      toast.error("Measurement cannot be blank");
    } else if (!editProductPrice) {
      toast.error("Price cannot be blank");
    } else {
      setEditProductButton(<LoaderIntro color="#fff" />);

      const data = {
        id: editProductId,
        businessId: businessId,
        name: editProductName,
        categoryId: editProductCategoryId,
        measurementId: editProductMeasurementId,
        price: editProductPrice,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurProduct&action=update",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("products");
            setSuccessEditProduct(true);
            handleCloseEditProductModal();
            setEditProductId("");
            setEditProductName("");
            setEditProductCategoryId("");
            setEditProductMeasurementId("");
            setEditProductPrice("");
            setEditProductButton("Update");
          } else {
            toast.error(data.message);
            setSuccessEditProduct(false);
            setEditProductButton("Update");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  //product variant
  const addProductVariant = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!productVariantName) {
      toast.error("Product variant name cannot be blank");
    } else if (mainProduct === "--Select--" || mainProduct === " ") {
      toast.error("Main Product cannot be blank");
    } else if (!productVariantPrice) {
      toast.error("Price cannot be blank");
    } else if (!productVariantSQ) {
      toast.error("Starting Quantity cannot be blank");
    } else {
      setAddProductVariantButton(<LoaderIntro color="#fff" />);

      const data = {
        businessId: businessId,
        name: productVariantName,
        mainProductId: mainProduct,
        unitPrice: productVariantPrice,
        startingQuantity: productVariantSQ,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurProductVariant&action=create",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("product-variants");
            setMainId(window.location.href.split("/").pop());
            setSuccessAddProductVariant(true);
            handleCloseAddProductVariantModal();
            setProductVariantName("");
            setMainProduct("--Select--");
            setProductVariantPrice("");
            setProductVariantSQ("");
            setAddProductVariantButton("Add");
          } else {
            toast.error(data.message);
            setSuccessAddProductVariant(false);
            setAddProductButton("Add");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const editProductVariant = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!editProductVariantName) {
      toast.error("Product variant name cannot be blank");
    } else if (!editProductVariantId) {
      toast.error("Missing important information");
    } else if (
      editMainProductVariant === "--Select--" ||
      editMainProductVariant === " "
    ) {
      toast.error("Main Product cannot be blank");
    } else if (!editProductVariantPrice) {
      toast.error("Price cannot be blank");
    } else if (!editProductVariantSQ) {
      toast.error("Starting quantity cannot be blank");
    } else {
      setEditProductVariantButton(<LoaderIntro color="#fff" />);

      const data = {
        id: editProductVariantId,
        businessId: businessId,
        name: editProductVariantName,
        mainProductId: editMainProductVariant,
        unitPrice: editProductVariantPrice,
        startingQuantity: editProductVariantSQ,
      };

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      console.log(JSON.stringify(data));

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurProductVariant&action=update",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("product-variants");
            setMainId(window.location.href.split("/").pop());
            setSuccessEditProductVariant(true);
            handleCloseEditProductVariantModal();
            setEditProductVariantName("");
            setEditMainProductVariant("--Select--");
            setEditProductVariantPrice("");
            setEditProductVariantSQ("");
            setEditProductVariantButton("Update");
          } else {
            toast.error(data.message);
            setSuccessEditProductVariant(false);
            setEditProductVariantButton("Update");
          }
        })
        .catch((error) => {
          toast.error(error);
          setSuccessEditProductVariant(false);
          editProductVariantButton("Update");
        });
    }
  };

  //service
  const addService = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!serviceName) {
      toast.error("Service name cannot be blank");
    } else if (srcCategory === "--Select--" || prdCategory === " ") {
      toast.error("Category cannot be blank");
    } else if (
      serviceMeasurement === "--Select--" ||
      productMeasurement === " "
    ) {
      toast.error("Measurement cannot be blank");
    } else if (!servicePrice) {
      toast.error("Price cannot be blank");
    } else {
      setAddServiceButton(<LoaderIntro color="#fff" />);

      const data = {
        businessId: businessId,
        name: serviceName,
        categoryId: srcCategory,
        measurementId: serviceMeasurement,
        price: servicePrice,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurService&action=create",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("services");
            setSuccessAddService(true);
            handleCloseAddServiceModal();
            setServiceName("");
            setSrcCategory("--Select--");
            setServiceMeasurement("--Select--");
            setServicePrice("");
            setAddServiceButton("Add");
          } else {
            toast.error(data.message);
            setSuccessAddService(false);
            setAddServiceButton("Add");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const editService = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!editServiceName) {
      toast.error("Service name cannot be blank");
    } else if (!editServiceId) {
      toast.error("Missing important information");
    } else if (editSrcCategory === "--Select--" || editSrcCategory === " ") {
      toast.error("Category cannot be blank");
    } else if (
      editServiceMeasurement === "--Select--" ||
      editServiceMeasurement === " "
    ) {
      toast.error("Measurement cannot be blank");
    } else if (!editServicePrice) {
      toast.error("Price cannot be blank");
    } else {
      setEditProductButton(<LoaderIntro color="#fff" />);

      const data = {
        id: editServiceId,
        businessId: businessId,
        name: editServiceName,
        categoryId: editSrcCategory,
        measurementId: editServiceMeasurement,
        price: editServicePrice,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurService&action=update",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("services");
            setSuccessEditService(true);
            handleCloseEditServiceModal();
            setEditServiceId("");
            setEditServiceName("");
            setEditSrcCategory("");
            setEditServiceMeasurement("");
            setEditServicePrice("");
            setEditServiceButton("Update");
          } else {
            toast.error(data.message);
            setSuccessEditService(false);
            setEditServiceButton("Update");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  //service variant
  const addServiceVariant = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!serviceVariantName) {
      toast.error("Service variant name cannot be blank");
    } else if (mainService === "--Select--" || mainService === " ") {
      toast.error("Main service cannot be blank");
    } else if (!serviceVariantPrice) {
      toast.error("Price cannot be blank");
    } else {
      setAddServiceVariantButton(<LoaderIntro color="#fff" />);

      const data = {
        businessId: businessId,
        name: serviceVariantName,
        mainServiceId: mainService,
        unitPrice: serviceVariantPrice,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurServiceVariant&action=create",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("service-variants");
            setMainId(window.location.href.split("/").pop());
            setSuccessAddServiceVariant(true);
            handleCloseAddServiceVariantModal();
            setServiceVariantName("");
            setMainService("--Select--");
            setServiceVariantPrice("");
            setAddServiceVariantButton("Add");
          } else {
            toast.error(data.message);
            setSuccessAddServiceVariant(false);
            setAddServiceVariantButton("Add");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const editServiceVariant = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!editServiceVariantName) {
      toast.error("Product variant name cannot be blank");
    } else if (!editServiceVariantId) {
      toast.error("Missing important information");
    } else if (
      editMainServiceVariant === "--Select--" ||
      editMainServiceVariant === " "
    ) {
      toast.error("Main Service cannot be blank");
    } else if (!editServiceVariantPrice) {
      toast.error("Price cannot be blank");
    } else {
      setEditServiceVariantButton(<LoaderIntro color="#fff" />);

      const data = {
        id: editServiceVariantId,
        businessId: businessId,
        name: editServiceVariantName,
        mainServiceId: editMainServiceVariant,
        unitPrice: editServiceVariantPrice,
      };

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      console.log(JSON.stringify(data));

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurServiceVariant&action=update",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("service-variants");
            setMainId(window.location.href.split("/").pop());
            setSuccessEditServiceVariant(true);
            handleCloseEditServiceVariantModal();
            setEditServiceVariantName("");
            setEditMainServiceVariant("--Select--");
            setEditServiceVariantPrice("");
            setEditServiceVariantButton("Update");
          } else {
            toast.error(data.message);
            setSuccessEditServiceVariant(false);
            setEditServiceVariantButton("Update");
          }
        })
        .catch((error) => {
          toast.error(error);
          setSuccessEditServiceVariant(false);
          editServiceVariantButton("Update");
        });
    }
  };

  //Investment
  const addInvestment = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!narration) {
      toast.error("Investment Narration cannot be blank");
    } else if (investmentType === "--Select--" || investmentType === " ") {
      toast.error("Investment Type cannot be blank");
    } else if (!investmentAmount) {
      toast.error("Investment amount cannot be blank");
    } else if (!investmentDate) {
      toast.error("Investment date cannot be blank");
    } else {
      setAddInvestmentButton(<LoaderIntro color="#fff" />);

      const data = {
        businessId: businessId,
        investmentNarration: narration,
        typeOfInvestment: investmentType,
        investmentAmount: investmentAmount,
        investmentDate: investmentDate,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurInvestment&action=create",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("investments");
            setSuccessAddInvestment(true);
            handleCloseAddInvestmentModal();
            setInvestmentType("--Select--");
            setNarration("");
            setInvestmentAmount("");
            setInvestmentDate("");
            setAddInvestmentButton("Add");
          } else {
            toast.error(data.message);
            setSuccessAddInvestment(false);
            setAddInvestmentButton("Add");
          }
        })
        .catch((error) => {
          console.log("Error: " + error);
        });
    }
  };

  const editInvestment = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!editNarration) {
      toast.error("Investment narration cannot be blank");
    } else if (!editingInvestmentId) {
      toast.error("Missing important information");
    } else if (
      editInvestmentType === "--Select--" ||
      editInvestmentType === " "
    ) {
      toast.error("Investment type cannot be blank");
    } else if (!editInvestmentAmount) {
      toast.error("Investment amount cannot be blank");
    } else if (!editInvestmentDate) {
      toast.error("Investment date cannot be blank");
    } else {
      setEditInvestmentButton(<LoaderIntro color="#fff" />);

      const data = {
        businessId: businessId,
        id: editingInvestmentId,
        investmentNarration: editNarration,
        typeOfInvestment: editInvestmentType,
        investmentAmount: editInvestmentAmount,
        investmentDate: editInvestmentDate,
      };
      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurInvestment&action=update",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("investments");
            setSuccessEditInvestment(true);
            handleCloseEditInvestmentModal();
            setEditingInvestmentId("");
            setEditInvestmentAmount("");
            setEditInvestmentDate("");
            setEditInvestmentType();
            setEditNarration("");
            setEditInvestmentButton("Update");
          } else {
            toast.error(data.message);
            setSuccessEditInvestment(false);
            setEditInvestmentButton("Update");
          }
        })
        .catch((error) => {
          console.log("Error: " + error);
        });
    }
  };

  //Inventory
  const addInventory = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (
      inventoryProductVId === "--Select--" ||
      inventoryProductVId === " "
    ) {
      toast.error("Product variant cannot be blank");
    } else if (!inventoryStock) {
      toast.error("No of items cannot be blank");
    } else if (!inventoryDate) {
      toast.error("Date stocked cannot be blank");
    } else {
      setAddInventoryButton(<LoaderIntro color="#fff" />);

      const data = {
        businessId: businessId,
        productVariantId: inventoryProductVId,
        stocked: inventoryStock,
        monthStocked: new Date(inventoryDate).getMonth() + 1,
        dateStocked: new Date(inventoryDate).getDate(),
        yearStocked: new Date(inventoryDate).getFullYear(),
      };

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurProductInventory&action=create",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("inventory");
            setSuccessAddInventory(true);
            handleCloseAddInventoryModal();
            setInventoryProductVId("--Select--");
            setInventoryProductVName("");
            setInventoryDate("");
            setInventoryStock("");
            setAddInventoryButton("Add");
          } else {
            toast.error(data.message);
            setSuccessAddInventory(false);
            setAddInventoryButton("Add");
          }
        })
        .catch((error) => {
          console.log("Error: " + error);
        });
    }
  };

  const editInventory = () => {
    if (!businessId) {
      toast.error("Missing important information");
    } else if (!editInventoryId) {
      toast.error("Missing important information");
    } else if (
      editInventoryProductVId === "--Select--" ||
      editInventoryProductVId === " "
    ) {
      toast.error("Product variant cannot be blank");
    } else if (!editInventoryStock) {
      toast.error("No of items cannot be blank");
    } else if (!editInventoryDate) {
      toast.error("Date stocked cannot be blank");
    } else {
      setEditInventoryButton(<LoaderIntro color="#fff" />);

      const data = {
        id: editInventoryId,
        businessId: businessId,
        productVariantId: editInventoryProductVId,
        stocked: editInventoryStock,
        monthStocked: new Date(editInventoryDate).getMonth() + 1,
        dateStocked: new Date(editInventoryDate).getDate(),
        yearStocked: new Date(editInventoryDate).getFullYear(),
      };

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        "https://space.somoafrica.org/api.php?resource=entrepreneurProductInventory&action=update",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setComponent("inventory");
            setSuccessEditInventory(true);
            handleCloseEditInventoryModal();
            setInventoryProductVId("");
            setEditInventoryProductVName("");
            setEditInventoryDate("");
            setEditInventoryStock("");
            setEditInventoryButton("Update");
          } else {
            toast.error(data.message);
            setSuccessEditInventory(false);
            setEditInventoryButton("Update");
          }
        })
        .catch((error) => {
          console.log("Error: " + error);
        });
    }
  };

  //Was adding or updating cashflow successful?
  useEffect(() => {
    if (successAddSale === true) {
      toast.success("Sale added successfully");
      setSuccessAddSale(false);
    } else if (successAddExpense === true) {
      toast.success("Expense added successfully");
      setSuccessAddExpense(false);
    } else if (successAddProduct === true) {
      toast.success("Product added successfully");
      setSuccessAddProduct(false);
    } else if (successAddService === true) {
      toast.success("Service added successfully");
      setSuccessAddService(false);
    } else if (successAddProductVariant === true) {
      toast.success("Product variant added successfully");
      setSuccessAddProductVariant(false);
    } else if (successAddServiceVariant === true) {
      toast.success("Service variant added successfully");
      setSuccessAddServiceVariant(false);
    } else if (successAddInvestment === true) {
      toast.success("Investment added successfully");
      setSuccessAddInvestment(false);
    } else if (successAddInventory === true) {
      toast.success("Inventory added successfully");
      setSuccessAddInventory(false);
    } else if (successEditSale === true) {
      toast.success("Sale updated successfully");
      setSuccessEditSale(false);
    } else if (successEditExpense === true) {
      toast.success("Expense updated successfully");
      setSuccessEditExpense(false);
    } else if (successEditProduct === true) {
      toast.success("Product updated successfully");
      setSuccessEditProduct(false);
    } else if (successEditProductVariant === true) {
      toast.success("Product variant updated successfully");
      setSuccessEditProductVariant(false);
    } else if (successEditServiceVariant === true) {
      toast.success("Service variant updated successfully");
      setSuccessEditServiceVariant(false);
    } else if (successEditService === true) {
      toast.success("Service updated successfully");
      setSuccessEditService(false);
    } else if (successEditInvestment === true) {
      toast.success("Investment updated successfully");
      setSuccessEditInvestment(false);
    } else if (successEditInventory === true) {
      toast.success("Inventory updated successfully");
      setSuccessEditInventory(false);
    }
  }, [
    successAddSale,
    successAddExpense,
    successAddProduct,
    successAddService,
    successAddProductVariant,
    successAddServiceVariant,
    successAddInvestment,
    successAddInventory,
    successEditSale,
    successEditExpense,
    successEditProduct,
    successEditProductVariant,
    successEditServiceVariant,
    successEditService,
    successEditInvestment,
    successEditInventory,
  ]);

  //Copying data to clipboard
  const handleCopy = () => {
    const tableId = document.querySelector("#tableId");
    const range = document.createRange();
    range.selectNode(tableId);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");

    selection.removeAllRanges();
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  //saving data as CSV

  const handleCSV = () => {
    const table = document.querySelector("#tableId");
    if (!table) return;

    const headerCells = Array.from(table.querySelectorAll("th"));

    const filterValue = filterWord;

    const filteredRows = Array.from(table.getElementsByTagName("tr")).filter(
      (row) => {
        const cells = Array.from(row.querySelectorAll("td"));
        return cells.some((cell) =>
          cell.textContent.toLowerCase().includes(filterValue)
        );
      }
    );

    const csvData = [];

    const headerRow = headerCells
      .map((header) => `"${header.textContent}"`)
      .join(",");
    csvData.push(headerRow);

    filteredRows.forEach((row) => {
      const rowData = Array.from(row.querySelectorAll("td"))
        .map((cell) => `"${cell.textContent}"`)
        .join(",");
      csvData.push(rowData);
    });

    const blob = new Blob([csvData.join("\n")], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "digikua.csv";
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  //saving data as PDF

  const handlePDF = () => {
    const table = document.querySelector("#tableId");
    const pdf = new jsPDF();
    pdf.autoTable({ html: table });
    pdf.save("digikua.pdf");
  };

  //saving data as Excel

  const handleExcel = () => {
    const table = document.querySelector("#tableId");
    const tableData = [];
    const headerCells = Array.from(table.querySelectorAll("th"));

    const filterValue = filterWord;

    const rows = Array.from(table.getElementsByTagName("tr")).filter((row) => {
      const cells = Array.from(row.querySelectorAll("td"));
      return cells.some((cell) =>
        cell.textContent.toLowerCase().includes(filterValue)
      );
    });

    const headerRow = headerCells.map((header) => `${header.textContent}`);
    tableData.push(headerRow);

    rows.forEach((row) => {
      const rowData = [];
      const cells = row.querySelectorAll("td, th");
      cells.forEach((cell) => {
        rowData.push(`${cell.innerText}`);
      });
      tableData.push(rowData);
    });

    const ws = XLSX.utils.aoa_to_sheet(tableData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "digikua.xlsx");
  };

  //Printing Table
  const handlePrint = () => {
    window.print();
  };

  //Filtering a table whenever user types on the filter field
  useEffect(() => {
    // if (!filterWord) return;
    const currentPath = `${path}/${window.location.href.split("/").pop()}`;
    if (
      currentPath !==
      `/${initialPath === applicantBasePath ? applicantPath : clientPath
      }/cashflow-summary`
    ) {
      const table = document.querySelector("#tableId");
      const rows = table.getElementsByTagName("tr");

      for (let i = 1; i < rows.length; i++) {
        const row = rows[i];
        const cells = row.getElementsByTagName("td");

        let shouldDisplay = false;

        for (let j = 0; j < cells.length; j++) {
          const cell = cells[j];
          const cellText = cell.textContent || cell.innerText;

          if (cellText.toLowerCase().indexOf(filterWord) > -1) {
            shouldDisplay = true;
            break;
          }
        }
        row.style.display = shouldDisplay ? "" : "none";
      }
    }
  }, [filterWord]);

  const handleClick = () => {
    history.push(`${path}/cashflow-summary`);
  };

  return (
    <>
      <Dashboard
        sidebarRoutes={
          initialPath === applicantBasePath ? applicRoutes : clientRoutes
        }
        location={location}
      >
        <ToastContainer position="top-center" />
        {/* Display whenever copying contents of table to clipboard was successful */}
        <div
          style={{ display: copySuccess ? "flex" : "none" }}
          className={styles.copy}
        >
          <p>
            <FaCircleCheck color="#07BC0C" size="1.5rem" />
          </p>
          <p className={styles.text}>Copied to clipboard!</p>
        </div>
        <Switch>
          <Route path={`${path}/cashflow-summary`}>
            {/* <div className={styles.buttonSelect}> */}
            <div className={styles.buttonSelect}>
              <div
                className={`${styles.selectContainer} ${styles.selectContainerHide}`}
              >
                <Button
                  clicked={handleButtonClick}
                  size="sm"
                  bgColor="#C4233C"
                  color="#fff"
                >
                  Survey
                </Button>
              </div>
              {/* <div className={styles.selectContainer}> */}
              <div
                className={`${styles.selectContainer} ${styles.selectContainerHide} ${styles.selectContainerShow}`}
              >
                <div>
                  <label for="cashflowElement">Select Cashflow Element:</label>
                </div>
                <select
                  name=""
                  id="cashflowElement"
                  className={`${styles.formControl} ${styles.select}`}
                  onChange={(e) => {
                    setComponent(e.target.value);
                  }}
                >
                  <option selected disabled value="0">
                    --Select--
                  </option>
                  <option value="">Overview</option>
                  <option value="sales">Sales</option>
                  <option value="expenses">Expenses</option>
                  <option value="products">Products</option>
                  <option value="services">Services</option>
                  <option value="investments">Investments</option>
                  <option value="inventory">Inventory</option>
                </select>
              </div>
            </div>
            <div className={styles.digikuaButton}>
              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "cashflow-summary"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("none");

                  // history.push("/dashboard/digikua/applicant/cashflow-summary");
                  // setComponent("");
                }}
              >
                <FaChartArea />
                <span className={styles.btnTxt}>Overview</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "sales"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("sales");
                }}
              >
                <FaCartShopping />
                <span className={styles.btnTxt}>Sales</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "expenses"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("expenses");
                }}
              >
                <FaDiagramProject />
                <span className={styles.btnTxt}>Expenses</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() === "products" ||
                    window.location.href.split("/")[
                    window.location.href.split("/").length - 2
                    ] === "product-variants"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setMainId("all");
                  setComponent("products");
                }}
              >
                <FaBoxesStacked />
                <span className={styles.btnTxt}>Products</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "services" ||
                    window.location.href.split("/")[
                    window.location.href.split("/").length - 2
                    ] == "service-variants"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setMainId("all");
                  setComponent("services");
                }}
              >
                <FaPeopleCarryBox />
                <span className={styles.btnTxt}>Services</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "investments"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("investments");
                }}
              >
                <FaTruckRampBox />
                <span className={styles.btnTxt}>Investments</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "inventory"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("inventory");
                }}
              >
                <FaReadme />
                <span className={styles.btnTxt}>Inventory</span>
              </DigikuaButton>
            </div>
          </Route>

          <Route path={!`${path}/cashflow-summary`}>
            <div className={`${styles.back} ${styles.noPrint}`}>
              <a href="" onClick={handleClick}>
                <FaArrowLeft size="20px" /> Back
              </a>
            </div>
            <div
              className={`${styles.selectContainer} ${styles.cashflowElementsSelect}`}
            >
              <div>
                <label for="cashflowElement">Select Cashflow Element:</label>
              </div>
              <select
                name=""
                id="cashflowElement"
                className={`${styles.formControl} ${styles.select}`}
                onChange={(e) => {
                  e.target.value
                    ? setComponent(e.target.value)
                    : history.push(
                      `/${initialPath === applicantBasePath
                        ? applicantPath
                        : clientPath
                      }/cashflow-summary`
                    );
                }}
              >
                <option selected disabled value="0">
                  --Select--
                </option>
                <option value="">Overview</option>
                <option value="sales">Sales</option>
                <option value="expenses">Expenses</option>
                <option value="products">Products</option>
                <option value="services">Services</option>
                <option value="investments">Investments</option>
                <option value="inventory">Inventory</option>
              </select>
            </div>
            <div className={styles.digikuaButton}>
              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == ""
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  // setComponent("")
                  setComponent("none");

                  // history.push("/dashboard/digikua/applicant/cashflow-summary");

                  // history.push(`/dashboard/digikua/client/cashflow-summary`);
                  // history.push(
                  //   `${
                  //     initialPath === applicantBasePath
                  //       ? applicantPath
                  //       : clientPath
                  //   }/cashflow-summary`
                  // );
                }}
              >
                <FaChartArea />
                <span className={styles.btnTxt}>Overview</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "sales"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("sales");
                }}
              >
                <FaCartShopping />
                <span className={styles.btnTxt}>Sales</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "expenses"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("expenses");
                }}
              >
                <FaDiagramProject />
                <span className={styles.btnTxt}>Expenses</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() === "products" ||
                    window.location.href.split("/")[
                    window.location.href.split("/").length - 2
                    ] === "product-variants"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setMainId("all");
                  setComponent("products");
                }}
              >
                <FaBoxesStacked />
                <span className={styles.btnTxt}>Products</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "services" ||
                    window.location.href.split("/")[
                    window.location.href.split("/").length - 2
                    ] == "service-variants"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setMainId("all");
                  setComponent("services");
                }}
              >
                <FaPeopleCarryBox />
                <span className={styles.btnTxt}>Services</span>
              </DigikuaButton>

              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "investments"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("investments");
                }}
              >
                <FaTruckRampBox />
                <span className={styles.btnTxt}>Investments</span>
              </DigikuaButton>
              <DigikuaButton
                bgColor={
                  window.location.href.split("/").pop() == "inventory"
                    ? "#00a5a2"
                    : "#cc6328"
                }
                size="sm"
                color="#fff"
                className="m-2"
                clicked={() => {
                  setComponent("inventory");
                }}
              >
                <FaReadme />
                <span className={styles.btnTxt}>Inventory</span>
              </DigikuaButton>
            </div>
          </Route>
        </Switch>

        <Switch>
          <Route path={`${path}/cashflow-summary`}>
            <div>
              {businessId && userEmail && businessName && (
                <DigiKuaAnalytics
                  bizId={businessId}
                  userEmail={userEmail}
                  biName={businessName}
                  loadingAnalytics={loadingAnalytics}
                  setLoadingAnalytics={setLoadingAnalytics}
                />
              )}
              <div className={styles.cards}>
                {cashflowSummaryData.map((item) => (
                  <SummaryCards
                    title={item.title}
                    value={item.value}
                    color={item.color}
                    icon={item.icon}
                  />
                ))}
              </div>
              {/* <div className={styles.cards}>
                {!loadingCashflowSummary &&
                  !error &&
                  cashflowSummaryData.map((item) => (
                    <SummaryCards
                      title={item.title}
                      value={item.value}
                      color={item.color}
                      icon={item.icon}
                      error={error}
                      loadingCashflowSummary={loadingCashflowSummary}
                    />
                  ))}

                {loadingCashflowSummary && !error && <p>Loading...</p>}
                {error && <p>Error</p>}
              </div> */}
            </div>
          </Route>

          <div className={styles.tableContainer}>
            <div className={styles.table}>
              <div className={styles.tableHeader}>
                <div className={styles.operationFunctions}>
                  <Switch>
                    <Route path={`${path}/cashflow-summary-detailed/sales`}>
                      <a
                        href="javascript:void(0)"
                        onClick={handleShowAddSaleModal}
                        class=""
                      >
                        <span>
                          <FaPlus /> Add Sale
                        </span>
                      </a>
                    </Route>

                    <Route path={`${path}/cashflow-summary-detailed/expenses`}>
                      <a
                        href="javascript:void(0)"
                        onClick={handleShowAddExpenseModal}
                        class=""
                      >
                        <span>
                          <FaPlus /> Add Expense
                        </span>
                      </a>
                    </Route>

                    <Route path={`${path}/cashflow-summary-detailed/products`}>
                      <a
                        href="javascript:void(0)"
                        onClick={handleShowAddProductModal}
                        class=""
                      >
                        <span>
                          <FaPlus /> Add Product
                        </span>
                      </a>

                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={() => {
                          setComponent("product-variants");
                          setMainId("all");
                        }}
                      >
                        <span>
                          <FaRankingStar /> All Product Variants
                        </span>
                      </a>
                    </Route>

                    <Route
                      path={`${path}/cashflow-summary-detailed/product-variants`}
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={() => setComponent("products")}
                        class=""
                      >
                        <span>
                          <FaAngleLeft /> Back to Products
                        </span>
                      </a>

                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={handleShowAddProductVariantModal}
                      >
                        <span>
                          <FaPlus /> Add Product Variant
                        </span>
                      </a>
                    </Route>

                    <Route path={`${path}/cashflow-summary-detailed/services`}>
                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={handleShowAddServiceModal}
                      >
                        <span>
                          <FaPlus /> Add Service
                        </span>
                      </a>

                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          setComponent("service-variants");
                          setMainId("all");
                        }}
                        class=""
                      >
                        <span>
                          <FaHandsHolding /> All Service Variants
                        </span>
                      </a>
                    </Route>

                    <Route
                      path={`${path}/cashflow-summary-detailed/service-variants`}
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={() => setComponent("services")}
                        class=""
                      >
                        <span>
                          <FaAngleLeft /> Back to Services
                        </span>
                      </a>

                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={handleShowAddServiceVariantModal}
                      >
                        <span>
                          <FaPlus /> Add Service Variant
                        </span>
                      </a>
                    </Route>

                    <Route
                      path={`${path}/cashflow-summary-detailed/investments`}
                    >
                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={handleShowAddInvestmentModal}
                      >
                        <span>
                          <FaPlus /> Add Investment
                        </span>
                      </a>
                    </Route>

                    <Route path={`${path}/cashflow-summary-detailed/inventory`}>
                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={handleShowAddInventoryModal}
                      >
                        <span>
                          <FaPlus /> Add Inventory
                        </span>
                      </a>
                    </Route>
                  </Switch>
                </div>
                <div className={styles.downLoadFilter}>
                  <div className={styles.downloadBtns}>
                    <DownloadCashflow
                      icon={
                        <FaRegCopy
                          color={
                            isHovered && hoveredDiv === 1
                              ? "#a9b1be"
                              : "#C4233C"
                          }
                        />
                      }
                      clicked={handleCopy}
                      format="Copy"
                      color={
                        isHovered && hoveredDiv === 1 ? "#a9b1be" : "#C4233C"
                      }
                      customKey={1}
                      setHoveredDiv={setHoveredDiv}
                      setIsHovered={setIsHovered}
                    />
                    <DownloadCashflow
                      icon={
                        <FaFileCsv
                          color={
                            isHovered && hoveredDiv === 2
                              ? "#a9b1be"
                              : "#FFC83B"
                          }
                        />
                      }
                      clicked={handleCSV}
                      format="CSV"
                      color={
                        isHovered && hoveredDiv === 2 ? "#a9b1be" : "#FFC83B"
                      }
                      customKey={2}
                      setHoveredDiv={setHoveredDiv}
                      setIsHovered={setIsHovered}
                    />
                    <DownloadCashflow
                      icon={
                        <FaFilePdf
                          color={
                            isHovered && hoveredDiv === 3
                              ? "#a9b1be"
                              : "#212153"
                          }
                        />
                      }
                      clicked={handlePDF}
                      format="PDF"
                      color={
                        isHovered && hoveredDiv === 3 ? "#a9b1be" : "#212153"
                      }
                      customKey={3}
                      setHoveredDiv={setHoveredDiv}
                      setIsHovered={setIsHovered}
                    />
                    <DownloadCashflow
                      icon={
                        <FaFileExcel
                          color={
                            isHovered && hoveredDiv === 4
                              ? "#a9b1be"
                              : "#00A5A2"
                          }
                        />
                      }
                      clicked={handleExcel}
                      format="Excel"
                      color={
                        isHovered && hoveredDiv === 4 ? "#a9b1be" : "#00A5A2"
                      }
                      customKey={4}
                      setHoveredDiv={setHoveredDiv}
                      setIsHovered={setIsHovered}
                    />
                    <DownloadCashflow
                      icon={
                        <FaPrint
                          color={
                            isHovered && hoveredDiv === 5
                              ? "#a9b1be"
                              : "#CC6328"
                          }
                        />
                      }
                      clicked={handlePrint}
                      format="Print"
                      color={
                        isHovered && hoveredDiv === 5 ? "#a9b1be" : "#CC6328"
                      }
                      customKey={5}
                      setHoveredDiv={setHoveredDiv}
                      setIsHovered={setIsHovered}
                    />
                  </div>
                  <div className={styles.filterContainer}>
                    <div>
                      <label for="filterInput">Filter:</label>
                    </div>
                    <div className={styles.filterCont}>
                      <input
                        onChange={(e) => {
                          setFilterWord(e.target.value.toLowerCase());
                        }}
                        value={filterWord}
                        type="text"
                        id="filterInput"
                        placeholder="Type here"
                        className={`${styles.formControl} ${styles.select} ${styles.filterSelect}`}
                      />
                      {filterWord === "" ? (
                        ""
                      ) : (
                        <FaXmark
                          className={styles.clearIcon}
                          color="#212153"
                          onClick={() => setFilterWord("")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Switch>
                <Route path={`${path}/cashflow-summary-detailed/sales`}>
                  <div className={styles.printHeader}>
                    <h1>Sales</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered" id="tableId">
                      <thead>
                        <tr>
                          <th>Sale ID / Edit</th>
                          <th>Sale Nature</th>
                          <th>Sale Variant</th>
                          <th>Quantity</th>
                          <th>Revenue</th>
                          <th>Date Sold</th>
                          <th>Type Of Sale</th>
                          <th>Client Phone</th>
                          <th>Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && !error && (
                          <tr>
                            <td colspan="9" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="9" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          !error &&
                          (productSalesData.length !== 0 ||
                            serviceSalesData.length !== 0 ? (
                            <>
                              {productSalesData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      setEditSaleId(item.id);
                                      setSaleEditNature("Product");
                                      setSaleEditProductVariant(
                                        item.productVariantId
                                      );
                                      setSaleEditQuantity(item.quantity);
                                      setSaleEditRevenue(item.revenue);
                                      const date = new Date(
                                        item.dateSold * 1000
                                      );
                                      const formattedDate = new Date(
                                        date.getTime() -
                                        date.getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .split("T")[0];
                                      setSaleEditDateSold(formattedDate);
                                      setSaleEditType(item.typeOfSale);
                                      setSaleEditClientPhone(item.clientPhone);
                                      handleShowEditSaleModal();
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare size="1rem" /> {item.id}
                                    </a>
                                  </td>
                                  <td>Product</td>
                                  <td>{item.productVariantName}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.revenue}</td>
                                  <td>
                                    {new Date(
                                      item.dateSold * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{item.typeOfSaleName}</td>
                                  <td>{item.clientPhone}</td>
                                  <td>
                                    {new Date(
                                      item.dateAdded * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}

                              {serviceSalesData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      setEditSaleId(item.id);
                                      setSaleEditNature("Service");
                                      setSaleEditServiceVariant(
                                        item.serviceVariantId
                                      );
                                      setSaleEditQuantity(item.quantity);
                                      setSaleEditRevenue(item.revenue);
                                      const date = new Date(
                                        item.dateSold * 1000
                                      );
                                      const formattedDate = new Date(
                                        date.getTime() -
                                        date.getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .split("T")[0];
                                      setSaleEditDateSold(formattedDate);
                                      setSaleEditType(item.typeOfSale);
                                      setSaleEditClientPhone(item.clientPhone);
                                      handleShowEditSaleModal();
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare size="1rem" /> {item.id}
                                    </a>
                                  </td>
                                  <td>Service</td>
                                  <td>{item.serviceVariantName}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.revenue}</td>
                                  <td>
                                    {new Date(
                                      item.dateSold * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{item.typeOfSaleName}</td>
                                  <td>{item.clientPhone}</td>
                                  <td>
                                    {new Date(
                                      item.dateAdded * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colspan="9" className={styles.noData}>
                                No data available in table
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>

                <Route path={`${path}/cashflow-summary-detailed/expenses`}>
                  <div className={styles.printHeader}>
                    <h1>Expenses</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered" id="tableId">
                      <thead>
                        <tr>
                          <th>Expense ID</th>
                          <th>Expense Nature</th>
                          <th>Name</th>
                          <th>Amount Spent</th>
                          <th>Transaction Date</th>
                          <th>Type Of Expense</th>
                          <th>Supplier Phone</th>
                          <th>Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          !error &&
                          (cogData.length !== 0 ||
                            capexData.length !== 0 ||
                            opexData.length !== 0 ? (
                            <>
                              {cogData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      setEditExpId(item.id);
                                      setEditExpNature("COG");
                                      setEditExpName(item.name);
                                      setEditExpAmountSpent(item.amountSpent);
                                      const date = new Date(
                                        item.transactionDate * 1000
                                      );
                                      const formattedDate = new Date(
                                        date.getTime() -
                                        date.getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .split("T")[0];
                                      setEditExpTransactionDate(formattedDate);
                                      setEditExpTypeOfExpense(
                                        item.typeOfExpense
                                      );
                                      setEditExpSupplierPhone(
                                        item.supplierPhone
                                      );
                                      setEditExpProductVariant(
                                        item.productVariantId
                                      );
                                      handleShowEditExpenseModal();
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare size="1rem" /> {item.id}
                                    </a>
                                  </td>
                                  <td>Cost of Goods</td>
                                  <td>{item.name}</td>
                                  <td>{item.amountSpent}</td>
                                  <td>
                                    {new Date(
                                      item.transactionDate * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{item.typeOfExpenseName}</td>
                                  <td>{item.supplierPhone}</td>
                                  <td>
                                    {new Date(
                                      item.dateAdded * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}

                              {capexData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      setEditExpId(item.id);
                                      setEditExpNature("CapEx");
                                      setEditExpName(item.name);
                                      setEditExpAmountSpent(item.amountSpent);
                                      const date = new Date(
                                        item.transactionDate * 1000
                                      );
                                      const formattedDate = new Date(
                                        date.getTime() -
                                        date.getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .split("T")[0];
                                      setEditExpTransactionDate(formattedDate);
                                      setEditExpTypeOfExpense(
                                        item.typeOfExpense
                                      );
                                      setEditExpSupplierPhone(
                                        item.supplierPhone
                                      );
                                      setEditExpProductVariant(
                                        item.productVariantId
                                      );
                                      setEditExpLifespan(item.lifespanInYears);
                                      handleShowEditExpenseModal();
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare /> {item.id}
                                    </a>
                                  </td>
                                  <td>Capital Expense</td>
                                  <td>{item.name}</td>
                                  <td>{item.amountSpent}</td>
                                  <td>
                                    {new Date(
                                      item.transactionDate * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{item.typeOfExpenseName}</td>
                                  <td>{item.supplierPhone}</td>
                                  <td>
                                    {new Date(
                                      item.dateAdded * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}

                              {opexData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      setEditExpId(item.id);
                                      setEditExpNature("OpEx");
                                      setEditExpName(item.name);
                                      setEditExpAmountSpent(item.amountSpent);
                                      const date = new Date(
                                        item.transactionDate * 1000
                                      );
                                      const formattedDate = new Date(
                                        date.getTime() -
                                        date.getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .split("T")[0];
                                      setEditExpTransactionDate(formattedDate);
                                      setEditExpTypeOfExpense(
                                        item.typeOfExpense
                                      );
                                      setEditExpSupplierPhone(
                                        item.supplierPhone
                                      );
                                      setEditExpProductVariant(
                                        item.productVariantId
                                      );
                                      handleShowEditExpenseModal();
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare /> {item.id}
                                    </a>
                                  </td>
                                  <td>Operating Expense</td>
                                  <td>{item.name}</td>
                                  <td>{item.amountSpent}</td>
                                  <td>
                                    {new Date(
                                      item.transactionDate * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{item.typeOfExpenseName}</td>
                                  <td>{item.supplierPhone}</td>
                                  <td>
                                    {new Date(
                                      item.dateAdded * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colspan="8" className={styles.noData}>
                                No data available in table
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>

                <Route path={`${path}/cashflow-summary-detailed/products`}>
                  <div className={styles.printHeader}>
                    <h1>Products</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered" id="tableId">
                      <thead>
                        <tr>
                          <th>View Variants</th>
                          <th>Name</th>
                          <th>Measurement Name</th>
                          <th>Category Name</th>
                          <th>Price</th>
                          <th>Somo Price</th>
                          <th>Status</th>
                          <th>Last Edited</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          !error &&
                          (productsData.length !== 0 ? (
                            <>
                              {productsData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      console.log(item.id);
                                      setMainId(item.id);
                                      setComponent("product-variants");
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaEye size="1rem" /> {item.id}
                                    </a>
                                  </td>
                                  <td
                                    onClick={() => {
                                      setEditProductId(item.id);
                                      setEditProductName(item.name);
                                      setEditProductCategoryId(item.categoryId);
                                      setEditProductMeasurementId(
                                        item.measurementId
                                      );
                                      setEditProductPrice(item.price);
                                      handleShowEditProductModal();
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare size="1rem" />{" "}
                                      {item.name}
                                    </a>
                                  </td>
                                  <td>{item.measurement}</td>
                                  <td>{item.categoryName}</td>
                                  <td>{item.price}</td>
                                  <td>{item.somoPrice}</td>
                                  <td>{item.status}</td>
                                  <td>
                                    {new Date(
                                      item.lastEdited * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colspan="8" className={styles.noData}>
                                No data available in table
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>

                <Route
                  path={`${path}/cashflow-summary-detailed/product-variants`}
                >
                  <div className={styles.printHeader}>
                    <h1>Product Variants</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered" id="tableId">
                      <thead>
                        <tr>
                          <th>Product Variant ID</th>
                          <th>Product Variant Name</th>
                          <th>Main Product Name</th>
                          <th>Unit Price</th>
                          <th>Initial Stock</th>
                          <th>Units Sold</th>
                          <th>Remaining Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingVariants && (
                          <tr>
                            <td colspan="7" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="7" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoadingVariants &&
                          !error &&
                          (allProductVariants.length !== 0 ? (
                            <>
                              {allProductVariants.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      setEditProductVariantId(item.id);
                                      setEditProductVariantName(item.name);
                                      setEditMainProductVariant(
                                        item.mainProductId
                                      );
                                      setEditProductVariantPrice(
                                        item.unitPrice
                                      );
                                      setEditProductVariantSQ(
                                        item.startingQuantity
                                      );
                                      handleShowEditProductVariantModal();
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare size="1rem" /> {item.id}
                                    </a>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.mainProduct}</td>
                                  <td>{item.unitPrice}</td>
                                  <td>{item.startingQuantity}</td>
                                  <td>{item.quantitySold}</td>
                                  <td>{item.endingQuantity}</td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colspan="7" className={styles.noData}>
                                No data available in table
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>

                <Route path={`${path}/cashflow-summary-detailed/services`}>
                  <div className={styles.printHeader}>
                    <h1>Services</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered" id="tableId">
                      <thead>
                        <tr>
                          <th>View Variants</th>
                          <th>Name</th>
                          <th>Measurement Name</th>
                          <th>Category Name</th>
                          <th>Price</th>
                          <th>Somo Price</th>
                          <th>Status</th>
                          <th>Last Edited</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          !error &&
                          (servicesData.length !== 0 ? (
                            servicesData.map((item, index) => (
                              <tr key={index}>
                                <td
                                  onClick={() => {
                                    setMainId(item.id);
                                    setComponent("service-variants");
                                  }}
                                >
                                  <a href="javascript:void(0)">
                                    <FaEye size="1rem" /> {item.id}
                                  </a>
                                </td>
                                <td
                                  onClick={() => {
                                    setEditServiceId(item.id);
                                    setEditServiceName(item.name);
                                    setEditSrcCategory(item.categoryId);
                                    setEditServiceMeasurement(
                                      item.measurementId
                                    );
                                    setEditServicePrice(item.price);
                                    handleShowEditServiceModal();
                                  }}
                                >
                                  <a href="javascript:void(0)">
                                    <FaRegPenToSquare size="1rem" /> {item.name}
                                  </a>
                                </td>
                                <td>{item.measurement}</td>
                                <td>{item.categoryName}</td>
                                <td>{item.price}</td>
                                <td>{item.somoPrice}</td>
                                <td>{item.status}</td>
                                <td>
                                  {new Date(
                                    item.lastEdited * 1000
                                  ).toLocaleDateString()}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="8" className={styles.noData}>
                                No data available in table
                              </td>{" "}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>

                <Route
                  path={`${path}/cashflow-summary-detailed/service-variants`}
                >
                  <div className={styles.printHeader}>
                    <h1>Service Variants</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered " id="tableId">
                      <thead>
                        <tr>
                          <th>Service Variant ID</th>
                          <th>Service Variant Name</th>
                          <th>Main Service Name</th>
                          <th>Unit Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingVariants && (
                          <tr>
                            <td colspan="4" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="4" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoadingVariants &&
                          !error &&
                          (allServiceVariants.length !== 0 ? (
                            allServiceVariants.map((item, index) => (
                              <tr key={index}>
                                <td
                                  onClick={() => {
                                    setEditServiceVariantId(item.id);
                                    setEditServiceVariantName(item.name);
                                    setEditMainServiceVariant(
                                      item.mainServiceId
                                    );
                                    setEditServiceVariantPrice(item.unitPrice);
                                    handleShowEditServiceVariantModal();
                                  }}
                                >
                                  <a href="javascript:void(0)">
                                    <FaRegPenToSquare size="1rem" /> {item.id}
                                  </a>
                                </td>
                                <td>{item.name}</td>
                                <td>{item.mainProduct}</td>
                                <td>{item.unitPrice}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="4" className={styles.noData}>
                                No data available in table
                              </td>{" "}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>

                <Route path={`${path}/cashflow-summary-detailed/investments`}>
                  <div className={styles.printHeader}>
                    <h1>Investments</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered" id="tableId">
                      <thead>
                        <tr>
                          <th>Investment ID</th>
                          <th>Investment Narration</th>
                          <th>Investment Amount</th>
                          <th>Form Of Investment</th>
                          <th>Investment Date</th>
                          <th>Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          !error &&
                          (investmentsData.length !== 0 ? (
                            <>
                              {investmentsData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      handleShowEditInvestmentModal();
                                      setEditingInvestmentId(item.id);
                                      setEditInvestmentType(
                                        item.typeOfInvestment
                                      );
                                      setEditNarration(
                                        item.investmentNarration
                                      );
                                      setEditInvestmentAmount(
                                        item.investmentAmount
                                      );
                                      setEditInvestmentDate(
                                        item.investmentDate
                                      );
                                      console.log(item.investmentDate);
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare size="1rem" /> {item.id}
                                    </a>
                                  </td>
                                  <td>{item.investmentNarration}</td>
                                  <td>{item.investmentAmount}</td>
                                  <td>{item.typeOfInvestmentName}</td>
                                  <td>{item.investmentDate}</td>
                                  <td>
                                    {new Date(
                                      item.dateAdded * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colspan="6" className={styles.noData}>
                                No data available in table
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>

                <Route path={`${path}/cashflow-summary-detailed/inventory`}>
                  <div className={styles.printHeader}>
                    <h1>Inventory</h1>
                  </div>
                  <section className={styles.tableBody}>
                    <table className="table-bordered" id="tableId">
                      <thead>
                        <tr>
                          <th>Inventory ID</th>
                          <th>Product Variant</th>
                          <th>Stocked</th>
                          <th>Unit Price</th>
                          <th>Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              <LoaderIntro color="#00A5A2" />
                            </td>
                          </tr>
                        )}
                        {error && (
                          <tr>
                            <td colspan="8" className={styles.noData}>
                              Error: {error}
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          !error &&
                          (inventoryData.length !== 0 ? (
                            <>
                              {inventoryData.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      handleShowEditInventoryModal();
                                      setEditInventoryId(item.id);
                                      setEditInventoryProductVId(
                                        item.productVariantId
                                      );
                                      setEditInventoryProductVName(
                                        item.productVariant
                                      );
                                      setEditInventoryStock(item.stocked);
                                      setEditInventoryPrice(item.unitPrice);
                                      const timestamp = item.dateStocked;
                                      const date = new Date(timestamp * 1000);
                                      const year = date.getFullYear();
                                      const month = String(
                                        date.getMonth() + 1
                                      ).padStart(2, "0");
                                      const day = String(
                                        date.getDate()
                                      ).padStart(2, "0");
                                      const formattedDate = `${year}-${month}-${day}`;
                                      setEditInventoryDate(formattedDate);
                                    }}
                                  >
                                    <a href="javascript:void(0)">
                                      <FaRegPenToSquare size="1rem" /> {item.id}
                                    </a>
                                  </td>
                                  <td>{item.productVariant}</td>
                                  <td>{item.stocked}</td>
                                  <td>{item.unitPrice}</td>
                                  <td>
                                    {new Date(
                                      item.dateStocked * 1000
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <tr>
                              <td colspan="5" className={styles.noData}>
                                No data available in table
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </section>
                </Route>
              </Switch>
            </div>
          </div>
        </Switch>

        {/* Adding a sale */}
        <Modal show={showAddSaleModal} onHide={handleCloseAddSaleModal}>
          <div className={`${styles.AddProductModal} ${styles.salesModal}`}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaCartPlus /> Add Sale
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="addsalenature">Sale Nature</label>
                  <select
                    name="addsalenature"
                    value={saleNature}
                    onChange={(e) => {
                      setSaleNature(e.target.value);
                      setFilteredProductsData([]);
                      setFilteredServicesData([]);
                      setSaleProductVariant("--Select--");
                      setSaleServiceVariant("--Select--");
                      if (e.target.value === "Product") {
                        setSaleService("--Select--");
                      } else if (e.target.value === "Service") {
                        setSaleProduct("--Select--");
                      }
                    }}
                    className="form-control"
                    id="addsalenature"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    <option value="Product">Product Sale</option>
                    <option value="Service">Service Sale</option>
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display:
                      saleNature === "Product" || saleNature === "--Select--"
                        ? "block"
                        : "none",
                  }}
                >
                  <label for="saleproduct">Main Product</label>
                  <select
                    disabled={saleNature === "Product" ? false : true}
                    name="saleproduct"
                    value={saleProduct}
                    onChange={(e) => {
                      setSaleProduct(e.target.value);
                      setFilterId(e.target.value);
                      setNature("product-variants");
                    }}
                    className="form-control"
                    id="saleproduct"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {productsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display:
                      saleNature === "Service" || saleNature === "--Select--"
                        ? "block"
                        : "none",
                  }}
                >
                  <label for="saleservice">Main Service</label>
                  <select
                    disabled={saleNature === "Service" ? false : true}
                    name="saleService"
                    value={saleService}
                    onChange={(e) => {
                      setSaleService(e.target.value);
                      setFilterId(e.target.value);
                      setNature("service-variants");
                    }}
                    className="form-control"
                    id="saleservice"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {servicesData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: saleNature === "Product" ? "block" : "none",
                  }}
                >
                  <label for="saleproductVariant">Product Variant</label>
                  <select
                    disabled={saleProduct !== "--Select--" ? false : true}
                    name="saleproductVariant"
                    value={saleProductVariant}
                    onChange={(e) => {
                      setSaleProductVariant(e.target.value);
                    }}
                    className="form-control"
                    id="saleproductVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoadingVariants && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}

                    {!isLoadingVariants &&
                      filteredProductsData.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: saleNature === "Service" ? "block" : "none",
                  }}
                >
                  <label for="serviceVariant">Service Variant</label>
                  <select
                    disabled={saleService !== "--Select--" ? false : true}
                    name="serviceVariant"
                    value={saleServiceVariant}
                    onChange={(e) => {
                      setSaleServiceVariant(e.target.value);
                    }}
                    className="form-control"
                    id="serviceVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoadingVariants && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {filteredServicesData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label for="quantity">Quantity</label>
                  <input
                    disabled={
                      saleProduct === "--Select--" &&
                        saleService === "--Select--"
                        ? true
                        : false
                    }
                    type="number"
                    value={saleQuantity}
                    onChange={(e) => {
                      setSaleQuantity(e.target.value);
                    }}
                    name="quantity"
                    className="form-control"
                    id="quantity"
                  />
                </div>

                <div className="form-group">
                  <label for="revenue">Revenue</label>
                  <input
                    disabled={
                      saleProduct === "--Select--" &&
                        saleService === "--Select--"
                        ? true
                        : false
                    }
                    type="number"
                    value={saleRevenue}
                    onChange={(e) => {
                      setSaleRevenue(e.target.value);
                    }}
                    name="revenue"
                    className="form-control"
                    id="revenue"
                  />
                </div>

                <div className="form-group">
                  <label for="dateSold">Date Sold</label>
                  <input
                    min={saleMin}
                    max={saleMax}
                    disabled={
                      saleProduct === "--Select--" &&
                        saleService === "--Select--"
                        ? true
                        : false
                    }
                    type="date"
                    value={saleDateSold}
                    onChange={(e) => {
                      setSaleDateSold(e.target.value);
                    }}
                    name="revenue"
                    className="form-control"
                    id="dateSold"
                  />
                </div>

                <div className="form-group">
                  <label for="typeOfSale">Type Of Sale</label>
                  <select
                    disabled={
                      saleProduct === "--Select--" &&
                        saleService === "--Select--"
                        ? true
                        : false
                    }
                    name="typeOfSale"
                    value={saleType}
                    onChange={(e) => {
                      setSaleType(e.target.value);
                    }}
                    className="form-control"
                    id="typeOfSale"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {saleTypeData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label for="clientPhone">Client Phone</label>
                  <input
                    disabled={
                      saleProduct === "--Select--" &&
                        saleService === "--Select--"
                        ? true
                        : false
                    }
                    type="text"
                    value={saleClientPhone}
                    onChange={(e) => {
                      setSaleClientPhone(e.target.value);
                    }}
                    name="clientPhone"
                    className="form-control"
                    id="clientPhone"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddSaleModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addSale}
              >
                {addSaleButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Updating a sale */}
        <Modal show={showEditSaleModal} onHide={handleCloseEditSaleModal}>
          <div className={`${styles.AddProductModal} ${styles.salesModal}`}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaCartPlus /> Edit Sale
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="salenature">Sale Nature</label>
                  <select
                    name="salenature"
                    disabled
                    value={saleEditNature}
                    onChange={(e) => {
                      setSaleEditNature(e.target.value);
                    }}
                    className="form-control"
                    id="salenature"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    <option value="Product">Product Sale</option>
                    <option value="Service">Service Sale</option>
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: saleEditNature === "Service" ? "none" : "",
                  }}
                >
                  <label for="productVariant">Product Variant</label>
                  <select
                    name="productVariant"
                    value={saleEditProductVariant}
                    onChange={(e) => {
                      setSaleEditProductVariant(e.target.value);
                    }}
                    className="form-control"
                    id="productVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {globalProductVariantsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display: saleEditNature === "Product" ? "none" : "",
                  }}
                >
                  <label for="serviceVariant">Service Variant</label>
                  <select
                    name="serviceVariant"
                    value={saleEditServiceVariant}
                    onChange={(e) => {
                      setSaleEditServiceVariant(e.target.value);
                    }}
                    className="form-control"
                    id="serviceVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {globalServiceVariantsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label for="quantity">Quantity</label>
                  <input
                    type="number"
                    value={saleEditQuantity}
                    onChange={(e) => {
                      setSaleEditQuantity(e.target.value);
                    }}
                    name="quantity"
                    className="form-control"
                    id="quantity"
                  />
                </div>

                <div className="form-group">
                  <label for="revenue">Revenue</label>
                  <input
                    type="number"
                    value={saleEditRevenue}
                    onChange={(e) => {
                      setSaleEditRevenue(e.target.value);
                    }}
                    name="revenue"
                    className="form-control"
                    id="revenue"
                  />
                </div>

                <div className="form-group">
                  <label for="dateSold">Date Sold</label>
                  <input
                    type="date"
                    value={saleEditDateSold}
                    onChange={(e) => {
                      setSaleEditDateSold(e.target.value);
                    }}
                    name="dateSold"
                    className="form-control"
                    id="dateSold"
                  />
                </div>

                <div className="form-group">
                  <label for="typeOfSale">Type Of Sale</label>
                  <select
                    name="typeOfSale"
                    value={saleEditType}
                    onChange={(e) => {
                      setSaleEditType(e.target.value);
                    }}
                    className="form-control"
                    id="typeOfSale"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {saleTypeData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label for="clientPhone">Client Phone</label>
                  <input
                    type="text"
                    value={saleEditClientPhone}
                    onChange={(e) => {
                      setSaleEditClientPhone(e.target.value);
                    }}
                    name="clientPhone"
                    className="form-control"
                    id="clientPhone"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditSaleModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editSale}
              >
                {editSaleButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Adding a expense */}
        <Modal show={showAddExpenseModal} onHide={handleCloseAddExpenseModal}>
          <div className={`${styles.AddProductModal} ${styles.salesModal}`}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaDiagramProject /> Add Expense
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="expenseNature">Nature of Expense</label>
                  <select
                    name="expenseNature"
                    value={addExpNature}
                    onChange={(e) => {
                      setAddExpNature(e.target.value);
                    }}
                    className="form-control"
                    id="expenseNature"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    <option value="COG">Cost of goods</option>
                    <option value="CapEx">Capital expense</option>
                    <option value="OpEx">Operating expense</option>
                  </select>
                </div>

                <div className="form-group">
                  <label for="typeOfSale">Type Of Expense</label>
                  <select
                    disabled={
                      addExpNature === "--Select--" || addExpNature === ""
                        ? true
                        : false
                    }
                    name="typeOfSale"
                    value={addExpTypeOfExpense}
                    onChange={(e) => {
                      setAddExpTypeOfExpense(e.target.value);
                    }}
                    className="form-control"
                    id="typeOfSale"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {saleTypeData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label for="supplierPhone">Supplier Phone</label>
                  <input
                    disabled={
                      addExpNature === "--Select--" || addExpNature === ""
                        ? true
                        : false
                    }
                    type="text"
                    value={addExpSupplierPhone}
                    onChange={(e) => {
                      setAddExpSupplierPhone(e.target.value);
                    }}
                    name="supplierPhone"
                    className="form-control"
                    id="supplierPhone"
                  />
                </div>

                <div className="form-group">
                  <label for="transactionDate">Transaction Date</label>
                  <input
                    disabled={
                      addExpNature === "--Select--" || addExpNature === ""
                        ? true
                        : false
                    }
                    min={saleMin}
                    max={saleMax}
                    type="date"
                    value={addExpTransactionDate}
                    onChange={(e) => {
                      setAddExpTransactionDate(e.target.value);
                    }}
                    name="transactionDate"
                    className="form-control"
                    id="transactionDate"
                  />
                </div>

                <div className="form-group">
                  <label for="expenseName">Name</label>
                  <input
                    disabled={
                      addExpNature === "--Select--" || addExpNature === ""
                        ? true
                        : false
                    }
                    type="text"
                    value={addExpName}
                    onChange={(e) => {
                      setAddExpName(e.target.value);
                    }}
                    name="expenseName"
                    className="form-control"
                    id="expenseName"
                  />
                </div>

                <div className="form-group">
                  <label for="amountSpent">Amount Spent</label>
                  <input
                    disabled={
                      addExpNature === "--Select--" || addExpNature === ""
                        ? true
                        : false
                    }
                    type="number"
                    value={addExpAmountSpent}
                    onChange={(e) => {
                      setAddExpAmountSpent(e.target.value);
                    }}
                    name="amountSpent"
                    className="form-control"
                    id="amountSpent"
                  />
                </div>

                <div
                  className="form-group"
                  style={{
                    display:
                      addExpNature === "COG" ||
                        addExpNature === "--Select--" ||
                        addExpNature === ""
                        ? "block"
                        : "none",
                  }}
                >
                  <label for="expProductVariant">Product Variant</label>
                  <select
                    disabled={addExpNature === "COG" ? false : true}
                    name="expProductVariant"
                    value={addExpProductVariant}
                    onChange={(e) => {
                      setAddExpProductVariant(e.target.value);
                    }}
                    className="form-control"
                    id="expProductVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {globalProductVariantsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display:
                      addExpNature === "CapEx" ||
                        addExpNature === "--Select--" ||
                        addExpNature === ""
                        ? "block"
                        : "none",
                  }}
                >
                  <label for="lifespan">Lifespan(years)</label>
                  <input
                    disabled={
                      addExpNature === "--Select--" || addExpNature === ""
                        ? true
                        : false
                    }
                    type="number"
                    value={addExpLifespan}
                    onChange={(e) => {
                      setAddExpLifespan(e.target.value);
                    }}
                    name="lifespan"
                    className="form-control"
                    id="lifespan"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddExpenseModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addExpense}
              >
                {addExpenseButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Editing expense */}
        <Modal show={showEditExpenseModal} onHide={handleCloseEditExpenseModal}>
          <div className={`${styles.AddProductModal} ${styles.salesModal}`}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaDiagramProject /> Edit Expense
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="expenseNature">Nature of Expense</label>
                  <select
                    disabled
                    name="expenseNature"
                    value={editExpNature}
                    onChange={(e) => {
                      setEditExpNature(e.target.value);
                    }}
                    className="form-control"
                    id="expenseNature"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    <option value="COG">Cost of goods</option>
                    <option value="CapEx">Capital expense</option>
                    <option value="OpEx">Operating expense</option>
                  </select>
                </div>

                <div className="form-group">
                  <label for="typeOfSale">Type Of Expense</label>
                  <select
                    disabled={
                      editExpNature === "--Select--" || editExpNature === ""
                        ? true
                        : false
                    }
                    name="typeOfSale"
                    value={editExpTypeOfExpense}
                    onChange={(e) => {
                      setEditExpTypeOfExpense(e.target.value);
                    }}
                    className="form-control"
                    id="typeOfSale"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {saleTypeData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label for="supplierPhone">Supplier Phone</label>
                  <input
                    disabled={
                      editExpNature === "--Select--" || editExpNature === ""
                        ? true
                        : false
                    }
                    type="text"
                    value={editExpSupplierPhone}
                    onChange={(e) => {
                      setEditExpSupplierPhone(e.target.value);
                    }}
                    name="supplierPhone"
                    className="form-control"
                    id="supplierPhone"
                  />
                </div>

                <div className="form-group">
                  <label for="transactionDate">Transaction Date</label>
                  <input
                    disabled={
                      editExpNature === "--Select--" || editExpNature === ""
                        ? true
                        : false
                    }
                    type="date"
                    value={editExpTransactionDate}
                    onChange={(e) => {
                      setEditExpTransactionDate(e.target.value);
                    }}
                    name="transactionDate"
                    className="form-control"
                    id="transactionDate"
                  />
                </div>

                <div className="form-group">
                  <label for="expenseName">Name</label>
                  <input
                    disabled={
                      editExpNature === "--Select--" || editExpNature === ""
                        ? true
                        : false
                    }
                    type="text"
                    value={editExpName}
                    onChange={(e) => {
                      setEditExpName(e.target.value);
                    }}
                    name="expenseName"
                    className="form-control"
                    id="expenseName"
                  />
                </div>

                <div className="form-group">
                  <label for="amountSpent">Amount Spent</label>
                  <input
                    disabled={
                      editExpNature === "--Select--" || editExpNature === ""
                        ? true
                        : false
                    }
                    type="number"
                    value={editExpAmountSpent}
                    onChange={(e) => {
                      setEditExpAmountSpent(e.target.value);
                    }}
                    name="amountSpent"
                    className="form-control"
                    id="amountSpent"
                  />
                </div>

                <div
                  className="form-group"
                  style={{
                    display:
                      editExpNature === "COG" ||
                        editExpNature === "--Select--" ||
                        editExpNature === ""
                        ? "block"
                        : "none",
                  }}
                >
                  <label for="expProductVariant">Product Variant</label>
                  <select
                    disabled={editExpNature === "COG" ? false : true}
                    name="expProductVariant"
                    value={editExpProductVariant}
                    onChange={(e) => {
                      setEditExpProductVariant(e.target.value);
                    }}
                    className="form-control"
                    id="expProductVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {globalProductVariantsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group"
                  style={{
                    display:
                      editExpNature === "CapEx" ||
                        editExpNature === "--Select--" ||
                        editExpNature === ""
                        ? "block"
                        : "none",
                  }}
                >
                  <label for="lifespan">Lifespan(years)</label>
                  <input
                    disabled={
                      editExpNature === "--Select--" || editExpNature === ""
                        ? true
                        : false
                    }
                    type="number"
                    value={editExpLifespan}
                    onChange={(e) => {
                      setEditExpLifespan(e.target.value);
                    }}
                    name="lifespan"
                    className="form-control"
                    id="lifespan"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditExpenseModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editExpense}
              >
                {editExpenseButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Adding a product */}
        <Modal show={showAddProductModal} onHide={handleCloseAddProductModal}>
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaBoxesStacked /> Add Product
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="name">Product Name</label>
                  <input
                    type="text"
                    value={productName}
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="categoryId">Category</label>
                  <select
                    name="categoryId"
                    value={prdCategory}
                    onChange={(e) => {
                      setPrdCategory(e.target.value);
                    }}
                    className="form-control"
                    id="categoryId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {productCategory.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="measurementId">Measurement</label>
                  <select
                    name="measurementId"
                    value={productMeasurement}
                    onChange={(e) => {
                      setProductMeasurement(e.target.value);
                    }}
                    className="form-control"
                    id="measurementId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>

                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {measurement.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Price</label>
                  <input
                    type="number"
                    value={productPrice}
                    onChange={(e) => {
                      setProductPrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddProductModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addProduct}
              >
                {addProductButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Editing a product */}
        <Modal show={showEditProductModal} onHide={handleCloseEditProductModal}>
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaBoxesStacked /> Edit Product
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="name">Product Name</label>
                  <input
                    type="text"
                    value={editProductName}
                    onChange={(e) => {
                      setEditProductName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="categoryId">Category</label>
                  <select
                    name="categoryId"
                    value={editProductCategoryId}
                    onChange={(e) => {
                      setEditProductCategoryId(e.target.value);
                    }}
                    className="form-control"
                    id="categoryId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {productCategory.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="measurementId">Measurement</label>
                  <select
                    name="measurementId"
                    value={editProductMeasurementId}
                    onChange={(e) => {
                      setEditProductMeasurementId(e.target.value);
                    }}
                    className="form-control"
                    id="measurementId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {measurement.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Price</label>
                  <input
                    type="number"
                    value={editProductPrice}
                    onChange={(e) => {
                      setEditProductPrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditProductModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editProduct}
              >
                {editProductButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Adding a product Variant*/}
        <Modal
          show={showAddProductVariantModal}
          onHide={handleCloseAddProductVariantModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaRankingStar /> Add Product Variant
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div>
                <div className="form-group">
                  <label for="name">Product Variant Name</label>
                  <input
                    type="text"
                    value={productVariantName}
                    onChange={(e) => {
                      setProductVariantName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="mainProduct">Main Product</label>
                  <select
                    name="mainProduct"
                    value={mainProduct}
                    onChange={(e) => {
                      setMainProduct(e.target.value);
                    }}
                    className="form-control"
                    id="mainProduct"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {globalProductsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Unit Price</label>
                  <input
                    type="number"
                    value={productVariantPrice}
                    onChange={(e) => {
                      setProductVariantPrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
                <div className="form-group">
                  <label for="price">Starting Quantity</label>
                  <input
                    type="number"
                    value={productVariantSQ}
                    onChange={(e) => {
                      setProductVariantSQ(e.target.value);
                    }}
                    name="startingQ"
                    className="form-control"
                    id="startingQ"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddProductVariantModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addProductVariant}
              >
                {addProductVariantButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Editing a product Variant*/}
        <Modal
          show={showEditProductVariantModal}
          onHide={handleCloseEditProductVariantModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaRankingStar /> Edit Product Variant
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div>
                <div className="form-group">
                  <label for="name">Product Variant Name</label>
                  <input
                    type="text"
                    value={editProductVariantName}
                    onChange={(e) => {
                      setEditProductVariantName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="mainProduct">Main Product</label>
                  <select
                    name="mainProduct"
                    value={editMainProductVariant}
                    onChange={(e) => {
                      setEditMainProductVariant(e.target.value);
                    }}
                    className="form-control"
                    id="mainProduct"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {globalProductsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Unit Price</label>
                  <input
                    type="number"
                    value={editProductVariantPrice}
                    onChange={(e) => {
                      setEditProductVariantPrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
                <div className="form-group">
                  <label for="price">Starting Quantity</label>
                  <input
                    type="number"
                    value={editProductVariantSQ}
                    onChange={(e) => {
                      setEditProductVariantSQ(e.target.value);
                    }}
                    name="startingQ"
                    className="form-control"
                    id="startingQ"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditProductVariantModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editProductVariant}
              >
                {editProductVariantButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Adding a service */}
        <Modal show={showAddServiceModal} onHide={handleCloseAddServiceModal}>
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaPeopleCarryBox /> Add Service
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="name">Service Name</label>
                  <input
                    type="text"
                    value={serviceName}
                    onChange={(e) => {
                      setServiceName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="categoryId">Category</label>
                  <select
                    name="categoryId"
                    value={srcCategory}
                    onChange={(e) => {
                      setSrcCategory(e.target.value);
                    }}
                    className="form-control"
                    id="categoryId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {productCategory.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="measurementId">Measurement</label>
                  <select
                    name="measurementId"
                    value={serviceMeasurement}
                    onChange={(e) => {
                      setServiceMeasurement(e.target.value);
                    }}
                    className="form-control"
                    id="measurementId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>

                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}

                    {measurement.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Price</label>
                  <input
                    type="number"
                    value={servicePrice}
                    onChange={(e) => {
                      setServicePrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddServiceModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addService}
              >
                {addServiceButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Editing a service */}
        <Modal show={showEditServiceModal} onHide={handleCloseEditServiceModal}>
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaPeopleCarryBox /> Edit Service
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="name">Service Name</label>
                  <input
                    type="text"
                    value={editServiceName}
                    onChange={(e) => {
                      setEditServiceName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="categoryId">Category</label>
                  <select
                    name="categoryId"
                    value={editSrcCategory}
                    onChange={(e) => {
                      setEditSrcCategory(e.target.value);
                    }}
                    className="form-control"
                    id="categoryId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {productCategory.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="measurementId">Measurement</label>
                  <select
                    name="measurementId"
                    value={editServiceMeasurement}
                    onChange={(e) => {
                      setEditServiceMeasurement(e.target.value);
                    }}
                    className="form-control"
                    id="measurementId"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {measurement.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Price</label>
                  <input
                    type="number"
                    value={editServicePrice}
                    onChange={(e) => {
                      setEditServicePrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditServiceModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editService}
              >
                {editServiceButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Adding a service Variant*/}
        <Modal
          show={showAddServiceVariantModal}
          onHide={handleCloseAddServiceVariantModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaHandsHolding /> Add Service Variant
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div>
                <div className="form-group">
                  <label for="name">Service Variant Name</label>
                  <input
                    type="text"
                    value={serviceVariantName}
                    onChange={(e) => {
                      setServiceVariantName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="mainService">Main Service</label>
                  <select
                    name="mainService"
                    value={mainService}
                    onChange={(e) => {
                      setMainService(e.target.value);
                    }}
                    className="form-control"
                    id="mainService"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {globalServiceData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Unit Price</label>
                  <input
                    type="number"
                    value={serviceVariantPrice}
                    onChange={(e) => {
                      setServiceVariantPrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddServiceVariantModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addServiceVariant}
              >
                {addServiceVariantButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Editing a service Variant*/}
        <Modal
          show={showEditServiceVariantModal}
          onHide={handleCloseEditServiceVariantModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaRankingStar /> Edit Service Variant
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div>
                <div className="form-group">
                  <label for="name">Service Variant Name</label>
                  <input
                    type="text"
                    value={editServiceVariantName}
                    onChange={(e) => {
                      setEditServiceVariantName(e.target.value);
                    }}
                    name="name"
                    className="form-control"
                    id="name"
                  />
                </div>

                <div className="form-group">
                  <label for="mainProduct">Main Service</label>
                  <select
                    name="mainProduct"
                    value={editMainServiceVariant}
                    onChange={(e) => {
                      setEditMainServiceVariant(e.target.value);
                    }}
                    className="form-control"
                    id="mainProduct"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {globalServiceData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="price">Unit Price</label>
                  <input
                    type="number"
                    value={editServiceVariantPrice}
                    onChange={(e) => {
                      setEditServiceVariantPrice(e.target.value);
                    }}
                    name="price"
                    className="form-control"
                    id="price"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditServiceVariantModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editServiceVariant}
              >
                {editServiceVariantButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Adding investment */}
        <Modal
          show={showAddInvestmentModal}
          onHide={handleCloseAddInvestmentModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaTruckRampBox /> Add Investment
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="investmentType">Investment Type</label>
                  <select
                    name="investmentType"
                    value={investmentType}
                    onChange={(e) => {
                      setInvestmentType(e.target.value);
                    }}
                    className="form-control"
                    id="investmentType"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {investmentTypeData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="narration">Investment Narration</label>
                  <input
                    type="text"
                    value={narration}
                    onChange={(e) => {
                      setNarration(e.target.value);
                    }}
                    name="narration"
                    className="form-control"
                    id="narration"
                  />
                </div>
                <div className="form-group">
                  <label for="investmentAmount">Investment Amount</label>
                  <input
                    type="number"
                    value={investmentAmount}
                    onChange={(e) => {
                      setInvestmentAmount(e.target.value);
                    }}
                    name="investmentAmount"
                    className="form-control"
                    id="investmentAmount"
                  />
                </div>
                <div className="form-group">
                  <label for="dateInput">Investment Date</label>
                  <input
                    type="date"
                    value={investmentDate}
                    min={minDate}
                    max={maxDate}
                    onChange={(e) => {
                      setInvestmentDate(e.target.value);
                    }}
                    name="investmentDate"
                    className="form-control"
                    id="dateInput"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddInvestmentModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addInvestment}
              >
                {addInvestmentButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* editing investment */}
        <Modal
          show={showEditInvestmentModal}
          onHide={handleCloseEditInvestmentModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaTruckRampBox /> Edit Investment
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.addProductForm}>
                <div className="form-group">
                  <label for="investmentType">Investment Type</label>
                  <select
                    name="investmentType"
                    value={editInvestmentType}
                    onChange={(e) => {
                      setEditInvestmentType(e.target.value);
                    }}
                    className="form-control"
                    id="investmentType"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {investmentTypeData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="narration">Investment Narration</label>
                  <input
                    type="text"
                    value={editNarration}
                    onChange={(e) => {
                      setEditNarration(e.target.value);
                    }}
                    name="narration"
                    className="form-control"
                    id="narration"
                  />
                </div>
                <div className="form-group">
                  <label for="investmentAmount">Investment Amount</label>
                  <input
                    type="number"
                    value={editInvestmentAmount}
                    onChange={(e) => {
                      setEditInvestmentAmount(e.target.value);
                    }}
                    name="investmentAmount"
                    className="form-control"
                    id="investmentAmount"
                  />
                </div>
                <div className="form-group">
                  <label for="dateInput">Investment Date</label>
                  <input
                    type="date"
                    value={editInvestmentDate}
                    onChange={(e) => {
                      setEditInvestmentDate(e.target.value);
                    }}
                    name="investmentDate"
                    className="form-control"
                    id="dateInput"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditInvestmentModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editInvestment}
              >
                {editInvestmentButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Adding inventory */}
        <Modal
          show={showAddInventoryModal}
          onHide={handleCloseAddInventoryModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaTruckRampBox /> Add Inventory
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div>
                <div className="form-group">
                  <label for="inventoryVariant">Product Variant</label>
                  <select
                    name="inventoryVariant"
                    value={inventoryProductVId}
                    onChange={(e) => {
                      setInventoryProductVId(e.target.value);
                    }}
                    className="form-control"
                    id="inventoryVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {isLoading && (
                      <option disabled selected value="--Select--">
                        Loading...
                      </option>
                    )}
                    {globalProductVariantsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="stock">No of Items</label>
                  <input
                    type="number"
                    value={inventoryStock}
                    onChange={(e) => {
                      setInventoryStock(e.target.value);
                    }}
                    name="inventoryStock"
                    className="form-control"
                    id="stock"
                  />
                </div>
                <div className="form-group">
                  <label for="inventoryDate">Date Stocked</label>
                  <input
                    type="date"
                    value={inventoryDate}
                    max={maxDate}
                    onChange={(e) => {
                      setInventoryDate(e.target.value);
                    }}
                    name="inventoryDate"
                    className="form-control"
                    id="dateInput"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseAddInventoryModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={addInventory}
              >
                {addInventoryButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>

        {/* Editing inventory */}
        <Modal
          show={showEditInventoryModal}
          onHide={handleCloseEditInventoryModal}
        >
          <div className={styles.AddProductModal}>
            <ToastContainer position="top-center" />
            <div className={styles.modalHeader}>
              <h4>
                <FaTruckRampBox /> Edit Inventory
              </h4>
            </div>
            <div className={styles.modalBody}>
              <div>
                <div className="form-group">
                  <label for="inventoryVariant">Product Variant</label>
                  <select
                    name="inventoryVariant"
                    value={editInventoryProductVId}
                    onChange={(e) => {
                      setEditInventoryProductVId(e.target.value);
                    }}
                    className="form-control"
                    id="inventoryVariant"
                    required=""
                  >
                    <option disabled selected value="--Select--">
                      --Select--
                    </option>
                    {globalProductVariantsData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label for="stock">No of Items</label>
                  <input
                    type="number"
                    value={editInventoryStock}
                    onChange={(e) => {
                      setEditInventoryStock(e.target.value);
                    }}
                    name="inventoryStock"
                    className="form-control"
                    id="stock"
                  />
                </div>
                <div className="form-group">
                  <label for="inventoryDate">Date Stocked</label>
                  <input
                    type="date"
                    value={editInventoryDate}
                    max={maxDate}
                    onChange={(e) => {
                      setEditInventoryDate(e.target.value);
                    }}
                    name="inventoryDate"
                    className="form-control"
                    id="dateInput"
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#C4233C"
                color="#fff"
                clicked={handleCloseEditInventoryModal}
              >
                Cancel
              </DigikuaButton>
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#00A5A2"
                color="#fff"
                clicked={editInventory}
              >
                {editInventoryButton}
              </DigikuaButton>
            </div>
          </div>
        </Modal>
      </Dashboard>
    </>
  );
};

export default Digikua;
