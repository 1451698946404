import React, { useState } from 'react';
import styles from "./MilestoneBudget.module.scss";
import { clientRoutes } from "../../routes/sidebarRoutes";
import {
    Route,
    useRouteMatch,
    Switch,
    useLocation,
    useHistory,
} from "react-router-dom";

import Dashboard from '../../components/Dashboard/Dashboard';
import { FaCircleChevronDown, FaCircleChevronUp, FaMoneyBills, FaChevronDown, FaRegPenToSquare, FaPlus, FaCalendarPlus, FaMoneyBill1Wave } from "react-icons/fa6";
import { FcMoneyTransfer } from "react-icons/fc";

import Button from "../../components/Button/Button"



const MilestoneBudget = () => {
    const location = useLocation();

    const [selectedButton, setSelectedButton] = useState(1)
    const [hiddenTable, setHiddenTable] = useState()


    return (
        <>
            <Dashboard sidebarRoutes={clientRoutes} location={location}>
                <div>
                    <div className={styles.overview}>
                        <div className={styles.header}>
                            <div className={styles.icon}>
                                <FaMoneyBills size="1.2rem" color='#ffffff' />
                            </div>
                            <h3>Milestone Budget</h3>
                        </div>
                        <div className={styles.buttonsContainer}>
                            <h5>Overview</h5>
                            <div className={styles.filterButtons}>
                                <button
                                    style={{
                                        backgroundColor: selectedButton == 1 ? '#ffffff' : '#eeeeee',
                                        boxShadow: selectedButton == 1 && '8px 15px 30px #0000001a',
                                        fontWeight: selectedButton == 1 && 'bold'
                                    }} onClick={() => setSelectedButton(1)}>Budget
                                    <div className={styles.iconCircle}
                                        style={{
                                            backgroundColor: selectedButton == 1 ? '#C4233C' : '#ffffff',
                                        }}
                                    >
                                        {selectedButton == 1 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                                    </div></button>
                                <button
                                    style={{
                                        backgroundColor: selectedButton == 2 ? '#ffffff' : '#eeeeee',
                                        boxShadow: selectedButton == 2 && '8px 15px 30px #0000001a',
                                        fontWeight: selectedButton == 2 && 'bold'
                                    }}
                                    onClick={() => setSelectedButton(2)}>Actual
                                    <div className={styles.iconCircle}
                                        style={{
                                            backgroundColor: selectedButton == 2 ? '#C4233C' : '#ffffff',
                                        }}
                                    >
                                        {selectedButton == 2 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className={styles.totalsContainer}>
                            <div className={styles.total}>
                                <h5><span>Ksh</span> 200000</h5>
                                <p>Total Amount</p>
                            </div>
                            <div className={styles.total}>
                                <h5><span>Ksh</span> 120000</h5>
                                <p>Capital Expense</p>
                            </div>
                            <div className={styles.total}>
                                <h5><span>Ksh</span> 80000</h5>
                                <p>Operating Expense</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.budgetContent}>
                        <div className={styles.addButtons}>
                            <div className={styles.button}>
                                <button><FaMoneyBill1Wave color='#979797' style={{ marginRight: '5px' }} /> Add Expense</button>
                            </div>
                            <div className={styles.button}>
                                <button><FaCalendarPlus color='#979797' style={{ marginRight: '5px' }} /> Add Period</button>
                            </div>
                        </div>
                        <div className={styles.tables}>
                            <div className={styles.tableTitle} onClick={() => hiddenTable == 1 ? setHiddenTable() : setHiddenTable(1)}>
                                <FaChevronDown />
                                <h5>Operating Expense</h5>
                            </div>

                            <div className={styles.table} style={{ display: hiddenTable == 1 && 'none' }}>
                                <div className={styles.tableBody}>
                                    <table className={`${styles.theTable}, table-bordered`} id="tableId">
                                        <thead>
                                            <tr>
                                                <th colspan="1"></th>
                                                <th colspan="3">Period 1 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Period 2 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Period 3 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Period 4 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className={styles.secondHeader}>
                                                <td>Expense</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                            </tr>
                                            <tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr>
                                            <tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={styles.tableTitle} onClick={() => hiddenTable == 2 ? setHiddenTable() : setHiddenTable(2)}>
                                <FaChevronDown />
                                <h5>Capital Expense</h5>
                            </div>
                            <div className={styles.table} style={{ display: hiddenTable == 1 && 'none' }}>
                                <div className={styles.tableBody}>
                                    <table className={`${styles.theTable}, table-bordered`} id="tableId">
                                        <thead>
                                            <tr>
                                                <th colspan="1"></th>
                                                <th colspan="3">Period 1 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Period 2 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Period 3 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Period 4 <br /> <span>01/01/2025 - 31/01/2025</span></th>
                                                <th colspan="3">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className={styles.secondHeader}>
                                                <td>Expense</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                                <td>Budget</td>
                                                <td>Actual</td>
                                                <td>Variance</td>
                                            </tr>
                                            <tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr>
                                            <tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr><tr>
                                                <td><a href="javascript:void(0)"><FaRegPenToSquare size="1rem" /></a> Rent</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                                <td>9000</td>
                                                <td>11000</td>
                                                <td>-22%</td>
                                                <td>5000</td>
                                                <td>7000</td>
                                                <td>-40%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dashboard >
        </>
    )
}

export default MilestoneBudget