import React, { useContext, useEffect, useState } from "react";
import styles from "./BusinessDocuments.module.scss";
import Dashboard from "../../components/Dashboard/Dashboard";
import Button from "../../components/Button/Button";
import { Row, Col, Modal } from "react-bootstrap";
import { FaArrowRight, FaFileInvoice, FaHome } from "react-icons/fa";
import { Context as AuthContext } from "../../context/AuthContext";
import LoaderIntro from "react-spinners/SyncLoader";
import { ThreeDots } from "react-loader-spinner";
import { ReactComponent as Icon1 } from "../../assets/icons/doc1.svg";
import { ReactComponent as Icon2 } from "../../assets/icons/doc2.svg";

import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { clientRoutes } from "../../routes/sidebarRoutes";
import { FaFileInvoiceDollar, FaCircleChevronDown, FaCircleChevronUp, FaPaperPlane, FaRegPenToSquare, FaCircleExclamation, FaPlus, FaBoxesStacked, FaBoxArchive, FaClockRotateLeft, FaDiagramNext, FaListCheck, FaMobileScreen, FaMobileScreenButton, FaReceipt, FaScreenpal, FaTachographDigital, FaTemperatureQuarter, FaTicket, FaTicketSimple, FaTimeline, FaUpload } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";

import DigikuaButton from "../../components/DigikuaButton/DigikuaButton"

const Invoices = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const [baseUrl, setBaseUrl] = useState("https://space.somoafrica.org")
  // const [baseUrl, setBaseUrl] = useState("http://space.somoafrica-org")

  const [phone, setPhone] = useState("")
  const [businessId, setBusinessId] = useState("")
  const [email, setEmail] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [clientName, setClientName] = useState("")
  const [items, setItems] = useState([{ variantId: '', quantity: '', totalAmount: '' }]);

  const [amountPaid, setAmountPaid] = useState()
  const [datePaid, setDatePaid] = useState("")
  const [invoiceId, setInvoiceId] = useState("")


  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [sendInvoiceError, setSendingInvoiceError] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [sendingId, setSendingId] = useState()


  const [invoiceData, setInvoiceData] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const [quotations, setQuotations] = useState([]);


  const [variants, setVariants] = useState([]);

  const [successCreateInvoice, setSuccessCreateInvoice] = useState(false);
  const [successCreateQuotation, setSuccessCreateQuotation] = useState(false);
  const [successCreateReceipt, setSuccessCreateReceipt] = useState(false);

  const [createInvoiceButton, setCreateInvoiceButton] = useState("Send")
  const [receiptButton, setReceiptButton] = useState("Add & Share")

  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [showCreateQuotationModal, setShowCreateQuotationModal] = useState(false);
  const [showCreateReceiptModal, setShowCreateReceiptModal] = useState(false);

  const [activeModal, setActiveModal] = useState('');
  const [activeMenu, setActiveMenu] = useState('');
  const [selectedButton, setSelectedButton] = useState(1)


  const handleShowCreateInvoiceModal = () => setShowCreateInvoiceModal(true);
  const handleCloseCreateInvoiceModal = () => {
    setShowCreateInvoiceModal(false)
    setActiveModal('')
  };

  const handleShowCreateQuotationModal = () => setShowCreateQuotationModal(true);
  const handleCloseCreateQuotationModal = () => {
    setShowCreateQuotationModal(false)
    setActiveModal('')
  };

  const handleShowCreateReceiptModal = () => setShowCreateReceiptModal(true);
  const handleCloseCreateReceiptModal = () => setShowCreateReceiptModal(false)



  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (!user) return;

    setPhone(user.phoneNumber);
    setEmail(user.phoneNumber);
  }, [user]);

  useEffect(() => {
    if (email) {
      const formData = new FormData();

      formData.append("readByEmailOrId", email);

      const requestOptions = {
        method: "POST",
        headers: {},
        body: formData,
      };

      fetch(
        `${baseUrl}/api.php?resource=business&action=readByEmailOrId`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setBusinessId(data.business[0].id);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("I am not boarding");
    }
  }, [email]);

  useEffect(() => {
    if (!businessId) return;
    const controller = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);
      setError("");

      try {
        const endpoints = [
          `${baseUrl}/api.php?resource=entrepreneurProductVariant&action=read&businessId=${businessId}`,
          `${baseUrl}/api.php?resource=entrepreneurServiceVariant&action=read&businessId=${businessId}`,
        ];

        const fetchPromises = endpoints.map((url) =>
          fetch(url, { signal: controller.signal }).then((response) => {
            if (!response.ok) {
              throw new Error(`Something went wrong`);
            }
            return response.json();
          })
        );

        const responses = await Promise.all(fetchPromises);

        const allVariants = responses.flatMap(data => data.data);

        setVariants(allVariants);

      } catch (error) {
        if (error.name !== "AbortError") {
          setError(error.message);
          console.log("Error: " + error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [businessId]);

  const getInvoiceData = async (phone) => {
    try {
      setInvoiceData([]);
      setInvoices([]);
      setIsLoading(true);
      setError("");

      const dt = {
        phone: phone
      };

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(dt)
      }

      const url = `${baseUrl}/api.php?resource=documents&action=invoices/list`;
      const res = await fetch(url, requestOptions);

      if (!res.ok) {
        throw new Error("Something went wrong with fetching invoices");
      }

      const data = await res.json();
      if (data.Response === "False") {
        throw new Error("Invoices not found");
      }
      if (data.data) {
        setInvoiceData(data.data);
        setInvoices(data.data);
        setError("");
        setIsLoading(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getQuotationData = async (phone) => {
    try {
      setQuotationData([]);
      setQuotations([]);
      setIsLoading(true);
      setError("");

      const dt = {
        phone: phone
      };

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(dt)
      }

      const url = `${baseUrl}/api.php?resource=documents/quotations&action=list`;
      const res = await fetch(url, requestOptions);

      if (!res.ok) {
        throw new Error("Something went wrong with fetching quotations");
      }

      const data = await res.json();
      if (data.Response === "False") {
        throw new Error("Quotations not found");
      }
      if (data.data) {
        setQuotationData(data.data);
        setQuotations(data.data)
        setError("");
        setIsLoading(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!phone) return;

    getInvoiceData(phone);
    getQuotationData(phone);
  }, [phone]);


  const incrementItems = () => {
    setItems([...items, { variantId: '', quantity: '', totalAmount: '' }]);
  };

  const removeItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const updateItem = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
  };

  const generateInvoice = () => {
    if (!phone) {
      toast.error("Missing important information");
    } else if (!items) {
      toast.error("Add an item");
    } else if (!clientEmail) {
      toast.error("Client email cannot be blank");
    } else if (!clientPhone) {
      toast.error("Client phone cannot be blank");
    } else if (!clientName) {
      toast.error("Client name cannot be blank");
    } else {
      setCreateInvoiceButton(<LoaderIntro color="#fff" />);

      const data = {
        phone: phone,
        items: items,
        clientName: clientName,
        clientPhone: clientPhone,
        clientEmail: clientEmail
      }

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        `${baseUrl}/api.php?resource=documents&action=invoices/items/add-multiple`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setSuccessCreateInvoice(true);
            handleCloseCreateInvoiceModal();
            setClientEmail("");
            setClientPhone("");
            setClientName("");
            setItems([{ variantId: '', quantity: '', totalAmount: '' }])
            setCreateInvoiceButton("Send");
          } else {
            toast.error(data.message);
            setSuccessCreateInvoice(false);
            setCreateInvoiceButton("Send");
          }
        })
        .catch((error) => {
          toast.error(error);
          setSuccessCreateInvoice(false);
          setCreateInvoiceButton("Send");
        });
    }
  };

  const generateQuotation = () => {
    if (!phone) {
      toast.error("Missing important information");
    } else if (!items) {
      toast.error("Add an item");
    } else if (!clientEmail) {
      toast.error("Client email cannot be blank");
    } else if (!clientPhone) {
      toast.error("Client phone cannot be blank");
    } else if (!clientName) {
      toast.error("Client name cannot be blank");
    } else {
      setCreateInvoiceButton(<LoaderIntro color="#fff" />);

      const data = {
        phone: phone,
        items: items,
        clientName: clientName,
        clientPhone: clientPhone,
        clientEmail: clientEmail
      }

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        `${baseUrl}/api.php?resource=documents/quotations&action=items/add-multiple`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setSuccessCreateQuotation(true);
            handleCloseCreateInvoiceModal();
            setClientEmail("");
            setClientPhone("");
            setClientName("");
            setItems([{ variantId: '', quantity: '', totalAmount: '' }])
            setCreateInvoiceButton("Send");
          } else {
            toast.error(data.message);
            setSuccessCreateQuotation(false);
            setCreateInvoiceButton("Send");
          }
        })
        .catch((error) => {
          toast.error(error);
          setSuccessCreateQuotation(false);
          setCreateInvoiceButton("Send");
        });
    }
  };


  const generateReceipt = () => {
    if (!phone || !invoiceId) {
      toast.error("Missing important information");
    } else if (!datePaid) {
      toast.error("Date paid cannot be blank");
    } else if (!amountPaid) {
      toast.error("Amount paid cannot be blank");
    } else {
      setReceiptButton(<LoaderIntro color="#fff" />);

      const data = {
        phone: phone,
        invoiceId: invoiceId,
        amountPaid: amountPaid,
        datePaid: datePaid,
      }

      const requestOptions = {
        method: "POST",
        headers: {},
        body: JSON.stringify(data),
      };

      fetch(
        `${baseUrl}/api.php?resource=documents&action=invoices/payments/add`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            fetch(
              `${baseUrl}//api.php?resource=documents&action=invoices/receipts/print`,
              requestOptions
            ).then((res) => res.json())
              .then((dt) => {
                if (dt.error === false) {
                  setSuccessCreateReceipt(true);
                  setReceiptButton("Add & Share");
                  handleCloseCreateReceiptModal();
                  setAmountPaid("");
                  setDatePaid("");
                  setInvoiceId("");
                } else {
                  toast.error(dt.message);
                  setSuccessCreateReceipt(false);
                  setReceiptButton("Add & Share");
                }
              })
          } else {
            toast.error(data.message);
            setSuccessCreateReceipt(false);
            setReceiptButton("Add & Share");
          }
        })
        .catch((error) => {
          toast.error(error);
          setSuccessCreateReceipt(false);
          setReceiptButton("Add & Share");
        });
    }
  };

  useEffect(() => {
    if (successCreateInvoice === true) {
      toast.success("Invoice generated successfully");
      setSuccessCreateInvoice(false);
    } else if (successCreateQuotation) {
      toast.success("Quotation created successfully");
      setSuccessCreateQuotation(false);
    } else if (successCreateReceipt) {
      toast.success("Receipt created and sent successfully");
      setSuccessCreateReceipt(false);
    }
  }, [successCreateInvoice, successCreateQuotation, successCreateReceipt])

  const shareDocument = async (phone, id, type) => {
    try {
      setIsSending(true);
      setSendingInvoiceError("");

      const dt = { phone, id };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dt),
      };

      let url = '';
      if (type === 'invoice') {
        url = `${baseUrl}/api.php?resource=documents&action=invoices/print`;
      } else if (type === 'quotation') {
        url = `${baseUrl}/api.php?resource=documents/quotations&action=print`;
      }

      const res = await fetch(url, requestOptions).catch(() => {
        throw new Error("Network error: Please check your internet connection.");
      });

      if (!res.ok) {
        throw new Error(`Something went wrong with sending the ${type}`);
      }

      const data = await res.json();

      if (data.Response === "False") {
        throw new Error(`${type.charAt(0).toUpperCase() + type.slice(1)} not sent`);
      }

      toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} sent successfully`);
    } catch (error) {
      toast.error(error.message);
      setSendingInvoiceError(error.message);
    } finally {
      setIsSending(false);
      setSendingId(null);
    }
  };

  useEffect(() => {
    if (activeMenu == 'quotation' && selectedButton === 1) {
      setQuotationData(quotations);
    } else if (activeMenu == 'quotation' && selectedButton === 2) {
      setQuotationData(quotations.filter(quotation => quotation.isGenerated));
    } else if (activeMenu == 'quotation' && selectedButton === 3) {
      setQuotationData(quotations.filter(quotation => !quotation.isGenerated));
    } else if (activeMenu == 'invoice' && selectedButton === 1) {
      setInvoiceData(invoices);
    } else if (activeMenu == 'invoice' && selectedButton === 2) {
      setInvoiceData(invoices.filter(invoice => invoice.isGenerated));
    } else if (activeMenu == 'invoice' && selectedButton === 3) {
      setInvoiceData(invoices.filter(invoice => !invoice.isGenerated));
    } else if (activeMenu == 'receipts' && selectedButton === 1) {
      setInvoiceData(invoices);
    } else if (activeMenu == 'receipts' && selectedButton === 2) {
      setInvoiceData(invoices);
    } else if (activeMenu == 'receipts' && selectedButton === 3) {
      setInvoiceData(invoices);
    }
  }, [selectedButton, activeMenu])


  return (
    <>
      <Dashboard sidebarRoutes={clientRoutes} location={location}>
        <ToastContainer position="top-center" />
        <div className={styles.heading}>
          <nav>
            <Button bgColor={window.location.href.split("/").pop() == 'home' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => {
              setSelectedButton(1)
              setActiveMenu('home')
              history.push("/dashboard/business-documents/home")
            }}>
              <FaHome /> <span className={styles.btnTxt}>Home</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'quotation' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => {
              setSelectedButton(1)
              setActiveMenu('quotation')
              history.push("/dashboard/business-documents/quotation")
            }}>
              <FaFileInvoiceDollar /> <span className={styles.btnTxt}>Quotations</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'invoice' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => {
              setSelectedButton(1)
              setActiveMenu('invoice')
              history.push("/dashboard/business-documents/invoice")
            }}>
              <FaFileInvoice /> <span className={styles.btnTxt}>Invoices</span>
            </Button>
            <Button bgColor={window.location.href.split("/").pop() == 'receipts' ? "#00a5a2" : "#cc6328"} size="sm" color="#fff" className="mt-4" clicked={() => {
              setSelectedButton(1)
              setActiveMenu('receipts')
              history.push("/dashboard/business-documents/receipts")
            }}>
              <FaReceipt /> <span className={styles.btnTxt}>Receipts</span>
            </Button>
          </nav>
        </div>

        <div className={styles.tableContainer}>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div className={styles.operationFunctions}>
                <Switch>
                  <Route path={`${path}/home`}>
                    <div className={styles.welcomeAcc}>
                      <Icon1
                        className={styles.mainIcon}
                      />
                      <div className={styles.text}>
                        <h1>Quotations, invoices, and receipts <br></br> <span>all in one place.</span></h1>
                        <Button
                          bgColor="#cc6328"
                          size="sm"
                          color="#fff"
                          className={`mt-4 ${styles.btn}`}
                          clicked={() => {
                            setActiveMenu('quotation')
                            setSelectedButton(1)
                            history.push("/dashboard/business-documents/quotation")
                          }
                          }
                        >
                          Quotations <FaArrowRight />
                        </Button><br />
                      </div>
                    </div>
                  </Route>

                  <Route path={`${path}/quotation`}>
                    <div className={styles.headerContent}>
                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={() => {
                          setActiveModal('quotation')
                          handleShowCreateQuotationModal()
                        }}
                      >
                        <span>
                          <FaPlus /> Create Quotation
                        </span>
                      </a>
                      <div className={styles.filterButtons}>
                        <button
                          style={{
                            backgroundColor: selectedButton == 1 ? '#ffffff' : '#eeeeee',
                            boxShadow: selectedButton == 1 && '8px 15px 30px #0000001a',
                            fontWeight: selectedButton == 1 && 'bold'
                          }} onClick={() => setSelectedButton(1)}>All
                          <div className={styles.iconCircle}
                            style={{
                              backgroundColor: selectedButton == 1 ? '#C4233C' : '#ffffff',
                            }}
                          >
                            {selectedButton == 1 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                          </div></button>
                        <button
                          style={{
                            backgroundColor: selectedButton == 2 ? '#ffffff' : '#eeeeee',
                            boxShadow: selectedButton == 2 && '8px 15px 30px #0000001a',
                            fontWeight: selectedButton == 2 && 'bold'
                          }}
                          onClick={() => setSelectedButton(2)}>Sent
                          <div className={styles.iconCircle}
                            style={{
                              backgroundColor: selectedButton == 2 ? '#C4233C' : '#ffffff',
                            }}
                          >
                            {selectedButton == 2 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                          </div>
                        </button>
                        <button
                          style={{
                            backgroundColor: selectedButton == 3 ? '#ffffff' : '#eeeeee',
                            boxShadow: selectedButton == 3 && '8px 15px 30px #0000001a',
                            fontWeight: selectedButton == 3 && 'bold'
                          }} onClick={() => setSelectedButton(3)}>Not Sent
                          <div className={styles.iconCircle}
                            style={{
                              backgroundColor: selectedButton == 3 ? '#C4233C' : '#ffffff',
                            }}
                          >
                            {selectedButton == 3 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                          </div></button>
                      </div>
                    </div>
                  </Route>
                  <Route path={`${path}/invoice`}>
                    <div className={styles.headerContent}>
                      <a
                        href="javascript:void(0)"
                        class=""
                        onClick={() => {
                          setActiveModal('invoice')
                          handleShowCreateInvoiceModal()
                        }}
                      >
                        <span>
                          <FaPlus /> Create Invoice
                        </span>
                      </a>
                      <div className={styles.filterButtons}>
                        <button
                          style={{
                            backgroundColor: selectedButton == 1 ? '#ffffff' : '#eeeeee',
                            boxShadow: selectedButton == 1 && '8px 15px 30px #0000001a',
                            fontWeight: selectedButton == 1 && 'bold'
                          }} onClick={() => setSelectedButton(1)}>All
                          <div className={styles.iconCircle}
                            style={{
                              backgroundColor: selectedButton == 1 ? '#C4233C' : '#ffffff',
                            }}
                          >
                            {selectedButton == 1 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                          </div></button>
                        <button
                          style={{
                            backgroundColor: selectedButton == 2 ? '#ffffff' : '#eeeeee',
                            boxShadow: selectedButton == 2 && '8px 15px 30px #0000001a',
                            fontWeight: selectedButton == 2 && 'bold'
                          }}
                          onClick={() => setSelectedButton(2)}>Sent
                          <div className={styles.iconCircle}
                            style={{
                              backgroundColor: selectedButton == 2 ? '#C4233C' : '#ffffff',
                            }}
                          >
                            {selectedButton == 2 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                          </div>
                        </button>
                        <button
                          style={{
                            backgroundColor: selectedButton == 3 ? '#ffffff' : '#eeeeee',
                            boxShadow: selectedButton == 3 && '8px 15px 30px #0000001a',
                            fontWeight: selectedButton == 3 && 'bold'
                          }} onClick={() => setSelectedButton(3)}>Not Sent
                          <div className={styles.iconCircle}
                            style={{
                              backgroundColor: selectedButton == 3 ? '#C4233C' : '#ffffff',
                            }}
                          >
                            {selectedButton == 3 ? <FaCircleChevronUp color='#ffffff' size='.8rem' /> : <FaCircleChevronDown color='#C4233C' size='.8rem' />}
                          </div></button>
                      </div>
                    </div>
                  </Route>
                  <Route path={`${path}/receipts`}>
                    <a
                      href="javascript:void(0)"
                      style={{ cursor: 'auto' }}
                    >
                      <span>
                        <FaReceipt /> Receipts
                      </span>
                    </a>
                  </Route>
                </Switch>
              </div>
            </div>
            <Switch>
              <Route path={`${path}/quotation`}>
                <section className={styles.tableBody}>
                  <table className="table-bordered" id="tableId">
                    <thead>
                      <tr>
                        <th>Send</th>
                        <th>Client Name</th>
                        <th>Client Email</th>
                        <th>Client Phone</th>
                        <th>Date Created</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <tr>
                          <td colspan="6" className={styles.noData}>
                            <LoaderIntro color="#00A5A2" />
                          </td>
                        </tr>
                      )}
                      {error && !isLoading && (
                        <tr>
                          <td colspan="6" className={styles.noData}>
                            <FaCircleExclamation color='#C4233C' size='1rem' /> {error}
                          </td>
                        </tr>
                      )}
                      {!isLoading &&
                        !error &&
                        (quotationData.length !== 0 ? (
                          <>
                            {quotationData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <a title='Send' href="javascript:void(0)">
                                    {isSending && sendingId === item.id ?
                                      <ThreeDots
                                        visible={true}
                                        height="25"
                                        width="25"
                                        color="#00A5A2"
                                        radius="5"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                      />
                                      : <FaPaperPlane size="1rem" onClick={() => {
                                        setSendingId(item.id)
                                        shareDocument(item.sessionPhone, item.id, 'quotation')
                                      }} />}
                                  </a>
                                </td>
                                <td>{item.quoteTo.name}</td>
                                <td>{item.quoteTo.email}</td>
                                <td>{item.quoteTo.phone}</td>
                                <td>
                                  {new Date(
                                    item.createdAt * 1000
                                  ).toLocaleDateString()}
                                </td>
                                <td><div className={styles.isGenerated} style={{ backgroundColor: item.isGenerated ? '#00A5A23a' : '#C4233C3a' }}>{item.isGenerated ? <p>Sent</p> : <p>Not Sent</p>}</div></td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colspan="6" className={styles.noData}>
                              No data available in table
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </section>
              </Route>
              <Route path={`${path}/invoice`}>
                <section className={styles.tableBody}>
                  <table className="table-bordered" id="tableId">
                    <thead>
                      <tr>
                        <th>Send</th>
                        <th>Client Name</th>
                        <th>Client Email</th>
                        <th>Client Phone</th>
                        <th>Date Created</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <tr>
                          <td colspan="6" className={styles.noData}>
                            <LoaderIntro color="#00A5A2" />
                          </td>
                        </tr>
                      )}
                      {error && !isLoading && (
                        <tr>
                          <td colspan="6" className={styles.noData}>
                            <FaCircleExclamation color='#C4233C' size='1rem' /> {error}
                          </td>
                        </tr>
                      )}
                      {!isLoading &&
                        !error &&
                        (invoiceData.length !== 0 ? (
                          <>
                            {invoiceData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <a title='Send' href="javascript:void(0)">
                                    {isSending && sendingId === item.id ?
                                      <ThreeDots
                                        visible={true}
                                        height="25"
                                        width="25"
                                        color="#00A5A2"
                                        radius="5"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                      />
                                      : <FaPaperPlane size="1rem" onClick={() => {
                                        setSendingId(item.id)
                                        shareDocument(item.sessionPhone, item.id, 'invoice')
                                      }} />}
                                  </a>
                                </td>
                                <td>{item.billTo.name}</td>
                                <td>{item.billTo.email}</td>
                                <td>{item.billTo.phone}</td>
                                <td>
                                  {new Date(
                                    item.createdAt * 1000
                                  ).toLocaleDateString()}
                                </td>
                                <td><div className={styles.isGenerated} style={{ backgroundColor: item.isGenerated ? '#00A5A23a' : '#C4233C3a' }}>{item.isGenerated ? <p>Sent</p> : <p>Not Sent</p>}</div></td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colspan="6" className={styles.noData}>
                              No data available in table
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </section>
              </Route>
              <Route path={`${path}/receipts`}>
                <section className={styles.tableBody}>
                  <table className="table-bordered" id="tableId">
                    <thead>
                      <tr>
                        <th>Add Payment & Share</th>
                        <th>Client Name</th>
                        <th>Client Email</th>
                        <th>Client Phone</th>
                        <th>Date Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <tr>
                          <td colspan="6" className={styles.noData}>
                            <LoaderIntro color="#00A5A2" />
                          </td>
                        </tr>
                      )}
                      {error && !isLoading && (
                        <tr>
                          <td colspan="6" className={styles.noData}>
                            <FaCircleExclamation color='#C4233C' size='1rem' /> {error}
                          </td>
                        </tr>
                      )}
                      {!isLoading &&
                        !error &&
                        (invoiceData.length !== 0 ? (
                          <>
                            {invoiceData.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <a href="javascript:void(0)" onClick={() => {
                                    setInvoiceId(item.id)
                                    handleShowCreateReceiptModal()
                                  }}>
                                    <FaRegPenToSquare size="1rem" /> {item.id}
                                  </a>
                                </td>
                                <td>{item.billTo.name}</td>
                                <td>{item.billTo.email}</td>
                                <td>{item.billTo.phone}</td>
                                <td>
                                  {new Date(
                                    item.createdAt * 1000
                                  ).toLocaleDateString()}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colspan="6" className={styles.noData}>
                              No data available in table
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </section>
              </Route>
            </Switch>
            <Icon2
              className={styles.icon}
              style={{
                width: "50px",
                height: "50px",
                bottom: "1px",
                right: "5px",
                transform: "rotate(-20deg)"
              }}
            />
          </div>
        </div>
      </Dashboard >

      {/* Create Invoice */}
      <Modal
        show={activeModal === 'invoice' ? showCreateInvoiceModal :
          activeModal === 'quotation' ? showCreateQuotationModal : ''
        } onHide={activeModal === 'invoice' ? handleCloseCreateInvoiceModal :
          activeModal == 'quotation' ? handleCloseCreateQuotationModal : ''
        }>
        <div className={styles.AddProductModal}>
          <ToastContainer position="top-center" />
          <div className={styles.modalHeader}>
            <h4>
              {activeModal === 'invoice' && <><FaFileInvoice /> Create Invoice</>}
              {activeModal === 'quotation' && <><FaFileInvoiceDollar /> Create Quotation</>}
            </h4>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.addProductForm}>
              <div className="form-group">
                <label for="name">Client Name</label>
                <input
                  type="text"
                  value={clientName}
                  onChange={(e) => {
                    setClientName(e.target.value);
                  }}
                  name="name"
                  className="form-control"
                  id="name"
                />
              </div>

              <div className="form-group">
                <label for="name">Client Phone</label>
                <input
                  type="text"
                  value={clientPhone}
                  onChange={(e) => {
                    setClientPhone(e.target.value);
                  }}
                  name="name"
                  className="form-control"
                  id="name"
                />
              </div>
            </div>
            <div className="form-group">
              <label for="name">Client Email</label>
              <input
                type="email"
                value={clientEmail}
                onChange={(e) => {
                  setClientEmail(e.target.value);
                }}
                name="name"
                className="form-control"
                id="name"
              />
            </div>
            <div className={styles.items}>
              {items.map((item, index) => (
                <div key={index} className={styles.item}>
                  <div className={styles.header}>
                    <h4>Item {index + 1}</h4>
                    {items.length > 1 && (
                      <FaTrashAlt
                        color="#C4233C"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeItem(index)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor={`variantId-${index}`}>Product/Service Variant</label>
                    <select
                      disabled={variants !== "--Select--" ? false : true}
                      name={`variantId-${index}`}
                      value={item.variantId || "--Select--"}
                      onChange={(e) => updateItem(index, 'variantId', e.target.value)}
                      className="form-control"
                      required=""
                    >
                      <option disabled selected value="--Select--">
                        --Select--
                      </option>

                      {isLoading && (
                        <option disabled value="--Select--">
                          Loading...
                        </option>
                      )}

                      {!isLoading && error && (
                        <option disabled value="--Select--">
                          Error
                        </option>
                      )}

                      {!isLoading && !error &&
                        variants.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`quantity-${index}`}>Quantity</label>
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => updateItem(index, 'quantity', e.target.value)}
                      name={`quantity-${index}`}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`totalAmount-${index}`}>Total Amount</label>
                    <input
                      type="number"
                      value={item.totalAmount}
                      onChange={(e) => updateItem(index, 'totalAmount', e.target.value)}
                      name={`totalAmount-${index}`}
                      className="form-control"
                    />
                  </div>
                </div>
              ))}
              <DigikuaButton
                fontSize="1rem"
                size="lg"
                margin=".3rem"
                bgColor="#ABB0B8"
                color="#ffffff"
                clicked={incrementItems}
              >
                Add Item
              </DigikuaButton>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#C4233C"
              color="#fff"
              clicked={activeModal === 'invoice' ? handleCloseCreateInvoiceModal :
                activeModal == 'quotation' ? handleCloseCreateQuotationModal : handleCloseCreateReceiptModal
              }
            >
              Cancel
            </DigikuaButton>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#00A5A2"
              color="#fff"
              clicked={activeModal === 'invoice' ? generateInvoice :
                activeModal == 'quotation' ? generateQuotation : generateReceipt
              }
            >
              {createInvoiceButton}
            </DigikuaButton>
          </div>
        </div>
      </Modal>

      {/* Create Receipt */}
      <Modal
        show={showCreateReceiptModal} onHide={handleCloseCreateReceiptModal}>
        <div className={styles.AddProductModal}>
          <ToastContainer position="top-center" />
          <div className={styles.modalHeader}>
            <h4><FaFileInvoice /> Add Payment</h4>
          </div>
          <div className={styles.modalBody}>
            <div className="form-group">
              <label for="name">Amount Paid</label>
              <input
                type="number"
                value={amountPaid}
                onChange={(e) => {
                  setAmountPaid(e.target.value);
                }}
                name="amountPaid"
                className="form-control"
                id="amountPaid"
              />
            </div><div className="form-group">
              <label for="name">Date Paid</label>
              <input
                type="date"
                value={datePaid}
                onChange={(e) => {
                  setDatePaid(e.target.value);
                }}
                name="datePaid"
                className="form-control"
                id="datePaid"
              />
            </div>
          </div>
          <div className={styles.modalFooter}>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#C4233C"
              color="#fff"
              clicked={() => {
                setInvoiceId("")
                handleCloseCreateReceiptModal()
              }}
            >
              Cancel
            </DigikuaButton>
            <DigikuaButton
              fontSize="1rem"
              size="lg"
              margin=".3rem"
              bgColor="#00A5A2"
              color="#fff"
              clicked={generateReceipt}
            >
              {receiptButton}
            </DigikuaButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Invoices;

