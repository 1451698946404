import React, { useState, useEffect } from "react";
import styles from "./LMSSurvey.module.scss";
import Button from "../Button/Button";
import Survey from "../Survey/Survey";
import {
  Route,
  useRouteMatch,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FaCheckCircle } from "react-icons/fa";

const LMSSurvey = ({
  traineeId,
  cohortId,
  baseUrl,
  name,
  doneSurvey,
  setDoneSurvey,
  doneSurveyData,
  checkDoneSurvey,
}) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [survey, setSurvey] = useState("");
  const [surveyTitle, setSurveyTitle] = useState("");
  // const [entryORexit, setEntryORexit] = useState("entry");
  const [round, setRound] = useState(1);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <div className={styles.creditTable}>
          <div className={styles.welcomeAcc}>
            <h2>Welcome to Somo Learning Management System</h2>
            <hr />
            <p>
              Hi {name ? name : "there"}, <br />
              We're excited to have you with us!
              <br />
              Please take a moment to complete the survey.
              <br />
              Once you finish, you'll gain access to the learning materials.
              <br />
              <br />
              <strong>
                <i>Available Surveys*:</i>
              </strong>
              <br />
            </p>
            {doneSurveyData &&
              Object.entries(doneSurveyData).map(([key, survey]) => (
                <div key={key} className={styles.surveyList}>
                  <div>
                    <h3 className={styles.surveyTitle}>
                      {key === "training" && "Training Impact Report"}
                      {key === "confidence" && "Confidence Survey"}
                      {key === "computerLiteracy" && "Computer Literacy"}
                      {key === "businessAcumen" && "Entry Questions"}
                      {key === "baseline" && "Baseline Survey"}
                    </h3>
                    {survey === 0 && (
                      <button
                        className={`${styles.button}`}
                        onClick={() => {
                          const link =
                            key === "confidence"
                              ? "confidence-level"
                              : key === "training"
                                ? "training-impact-report"
                                : key === "computerLiteracy"
                                  ? "computer-literacy"
                                  : key === "businessAcumen"
                                    ? `business-acumen/${round === 1 ? "entry" : "exit"
                                    }`
                                    : key === "baseline"
                                      ? "baseline"
                                      : "";
                          const title =
                            key === "training"
                              ? "Training Impact Report"
                              : key === "confidence"
                                ? "Confidence Survey"
                                : key === "computerLiteracy"
                                  ? "Computer Literacy"
                                  : key === "businessAcumen" && round === 1
                                    ? "Entry Questions"
                                    : key === "businessAcumen" && round === 2
                                      ? "Exit Questions"
                                      : key === "baseline"
                                        ? "Baseline Survey"
                                        : "Survey";
                          history.push(`${path}/survey/${link}`);
                          setSurvey(link);
                          setSurveyTitle(title);
                        }}
                      >
                        Take Survey
                      </button>
                    )}
                  </div>
                  {survey === 0 && (
                    <FadeLoader size="4em" className={styles.icon} />
                  )}
                  {survey > 0 && (
                    <FaCheckCircle
                      size="4em"
                      className={styles.icon}
                      color="#00a5a2"
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </Route>
      <Route path={`${path}/survey/:survey`}>
        <Survey
          traineeId={traineeId}
          cohortId={cohortId}
          baseUrl={baseUrl}
          survey={survey}
          surveyTitle={surveyTitle}
          setSurvey={setSurvey}
          setSurveyTitle={setSurveyTitle}
          round={round}
        />
      </Route>
    </Switch>
  );
};

export default LMSSurvey;
