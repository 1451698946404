import React, { useState, useEffect } from "react";
import styles from "./LMSMessages.module.scss";
import { ReactComponent as Icon } from "../../assets/icons/messages.svg";
import { ReactComponent as Man } from "../../assets/icons/man.svg";
import { ReactComponent as Woman } from "../../assets/icons/woman.svg";
import { FaSearch, FaPaperPlane, FaChevronRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

const LMSMessages = ({
  baseUrl,
  cohortId,
  traineeId,
  mentorId,
  mentorName,
  closePanel,
  setClosePanel,
  screenWidth,
}) => {
  const [messages, setMessages] = useState([]);
  const [messagesError, setMessagesError] = useState("");
  const [isLoadingmessages, setIsLoadingMessages] = useState(false);

  const [newMessage, setNewMessage] = useState("");
  const [gradient, setGradient] = useState(1);

  const [submittingMessage, setSubmittingMessage] = useState();

  const fetchMessages = async () => {
    setIsLoadingMessages(true);
    setMessagesError("");

    try {
      const url = `${baseUrl}messages?cohort_id=${cohortId}&mentor_id=${mentorId}&trainee_id=${traineeId}`;
      const res = await fetch(url, { method: "GET" });

      if (!res.ok) {
        throw new Error(`Error ${res.status}: ${res.statusText}`);
      }

      const data = await res.json();

      if (!data || data.Response === "False") {
        throw new Error("Messages not found");
      }

      setMessages(Array.isArray(data.data) ? data.data : []);
    } catch (error) {
      setMessagesError(error.message || "An unexpected error occurred");
    } finally {
      setIsLoadingMessages(false);
    }
  };


  useEffect(() => {
    fetchMessages();
  }, []);

  const trimText = (text) => {
    const len = text.length;
    const newText = len > 20 ? `${text.slice(0, 20)}...` : text;
    return newText;
  };

  const getOccurrence = (closePanel, screenWidth) => {
    if (closePanel && screenWidth < 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth < 1200) {
      return ["0%", "none"];
    } else if (closePanel && screenWidth > 1200) {
      return ["100%", ""];
    } else if (!closePanel && screenWidth > 1200) {
      return ["70%", ""];
    }
  };

  const getBackgroundColor = (gradient, type) => {
    if (gradient === 1 && type === "mentor") {
      return "rgba(196, 35, 60, 0.1)";
    } else if (gradient === 1 && type === "trainee") {
      return "rgba(0, 165, 162, 0.1)";
    } else if (gradient === 2 && type === "mentor") {
      return "rgba(255, 200, 59, 0.1)";
    } else if (gradient === 2 && type === "trainee") {
      return "rgba(196, 35, 60, 0.1)";
    } else if (gradient === 3 && type === "mentor") {
      return "rgba(204, 99, 40, 0.1)";
    } else if (gradient === 3 && type === "trainee") {
      return "rgba(255, 200, 59, 0.1)";
    }
  };

  const submitMessage = () => {
    setSubmittingMessage(true);
    if (!cohortId && !traineeId) {
      toast.error("Missing important information");
    } else if (!newMessage || newMessage === "") {
      toast.error("Enter message");
    } else {
      const data = {
        cohortId: cohortId,
        traineeId: traineeId,
        mentorId: mentorId,
        sender: "trainee",
        content: {
          kind: "text",
          text: newMessage,
        },
        /* status options -> {pending | sending | read | scheduled | failed | queued | rejected | undeliverable | sent | delivered | cancelled} */
        status: null,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      fetch(`${baseUrl}messages`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            console.log("Successful");
            fetchMessages();
            setNewMessage("");
            setSubmittingMessage(false);
          } else {
            toast.error(data.message);
            setSubmittingMessage(false);
          }
        })
        .catch((error) => {
          toast.error("An error occurred. Please try again.");
          setSubmittingMessage(false);
        });
    }
  };

  const getTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toISOString().slice(0, 16).replace("T", " ");
    return formattedDate;
  };

  const handleGradientClick = (gradientValue) => {
    setGradient(gradientValue);
    localStorage.setItem("selectedGradient", gradientValue);
  };

  useEffect(() => {
    const savedGradient = localStorage.getItem("selectedGradient");
    if (savedGradient) {
      setGradient(parseInt(savedGradient, 10));
    }
  }, []);

  return (
    <div
      className={styles.messageCont}
      style={{
        width: getOccurrence(closePanel, screenWidth)[0],
        display: getOccurrence(closePanel, screenWidth)[1],
      }}
    >
      <div className={styles.topBar}>
        <div className={styles.header}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {closePanel ? (
              <div
                className={styles.openMenu}
                onClick={() => setClosePanel(false)}
              >
                <FaChevronRight size="1rem" color="#cc6328" />
              </div>
            ) : (
              ""
            )}
          </div>
          <Icon
            className={styles.icon}
            style={{ marginLeft: closePanel ? "" : "20px" }}
          />
          <h2>Messages</h2>
        </div>
      </div>
      <div className={styles.contentBar}>
        <div className={styles.selectedMessage}>
          <div className={styles.messageHeader}>
            <div className={styles.selectedUser}>
              <Man className={styles.user} />
              <h5>{mentorName}</h5>
            </div>
            <div className={styles.gradientCont}>
              <div
                onClick={() => handleGradientClick(1)}
                style={{
                  border: gradient === 1 ? "1px solid #00a5a2" : "none",
                }}
                className={`${styles.gradient} ${styles.gradient1}`}
              ></div>
              <div
                onClick={() => handleGradientClick(2)}
                style={{
                  border: gradient === 2 ? "1px solid #00a5a2" : "none",
                }}
                className={`${styles.gradient} ${styles.gradient2}`}
              ></div>
              <div
                onClick={() => handleGradientClick(3)}
                style={{
                  border: gradient === 3 ? "1px solid #00a5a2" : "none",
                }}
                className={`${styles.gradient} ${styles.gradient3}`}
              ></div>
            </div>
          </div>
          <div className={styles.textBody}>
            {messages.length > 0 &&
              messages.map((chat) => (
                <div
                  className={styles.chatBox}
                  style={{
                    alignItems:
                      chat.sender === "mentor" ? "flex-start" : "flex-end",
                  }}
                >
                  <div
                    className={`${styles.chat} ${chat.type === "mentor"
                        ? styles.chatLeft
                        : styles.chatRight
                      }`}
                    style={{
                      backgroundColor: getBackgroundColor(
                        gradient,
                        chat.sender
                      ),
                    }}
                  >
                    {chat.content !== null && chat.content.kind === "text" && (
                      <p>{chat.content.text}</p>
                    )}
                    <div className={styles.timestamp}>
                      {chat.content !== null && (
                        <p>{getTime(chat.createdAt)}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}

            {submittingMessage && (
              <div
                className={styles.chatBox}
                style={{
                  alignItems: "flex-end",
                }}
              >
                <div className={`${styles.chat} ${styles.chatRight}`}>
                  <p>Sending...</p>
                </div>
              </div>
            )}

            {!isLoadingmessages && !messagesError && messages.length < 1 && (
              <p></p>
            )}
          </div>

          <div className={styles.newText}>
            <input
              type="text"
              placeholder="Type something..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && newMessage !== "") {
                  submitMessage();
                }
              }}
            />
            <FaPaperPlane
              color="#878787"
              className={styles.paperPlane}
              size="1.2rem"
              style={{ cursor: newMessage === "" ? "not-allowed" : "pointer" }}
              onClick={() => newMessage !== "" && submitMessage()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LMSMessages;
